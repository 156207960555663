import React, { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash, FaFacebookSquare, FaGithub, FaGooglePlusG } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { githubLogin, googleLogin, loginUser } from '../../features/auth/authSlice';
import FormInput from '../../Shared/Components/FormInput'
import signupimg from "../../assets/images/signup.png"
import { useNavigate } from 'react-router-dom';
import { isShowAuthModal, toggleAuthModal } from '../../features/globalState/globalStateSlice';
import { useLocation, Navigate } from 'react-router-dom';

export default function Signin({ setShowPage }) {
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [error, setError] = useState("");
    let [passType, setPassType] = useState(true);

    const { email: logedInUser, isLoading,  isError, error:authError } = useSelector(state => state.authDetail);
    const navigate = useNavigate();
    const location = useLocation();

    const { from } = location.state || { from: { pathname: '/' } };

    const showModal = useSelector(isShowAuthModal);
    const dispatch = useDispatch();
  
  
    const handleToggle = () => {
      dispatch(toggleAuthModal(!showModal));
    }

    
    useEffect(() => {
        if (logedInUser) {
            // setShowPage("");
            <Navigate to={from} replace />;
            handleToggle()
        }
    }, [logedInUser])


    const handleSignin = (e) => {
        e.preventDefault();
        setError("");
        if (!email || !password) {
            setError("Please, fill all the input field to sign in")
            return
        }
        dispatch(loginUser({ email, password }))
    }

    const handleGoogleLogin = () => {
        dispatch(googleLogin())
    }

    const handleGithubLogin = () => {
        dispatch(githubLogin())
    }

    return (
        <div className='max-w-6xl w-full mx-auto flex justify-center items-center z-50 px-4 absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2 '>
            <div className='bg-white w-full grid grid-cols-1 md:grid-cols-2 rounded-xl overflow-hidden z-50'>
                <div className='hidden col-span-1 md:flex items-center p-4'>
                    <img src={signupimg} />
                </div>
                <div className='col-span-1 p-4'>
                    <div className='hidden md:block text-right'>
                        <p className='text-xl'>Don't have an Account? <span className='text-primary cursor-pointer' onClick={() => setShowPage("signup")}>Sign up</span></p>
                    </div>
                    <div className='py-12 px-4 md:px-8 w-full md:w-5/6 '>
                        <h2 className='text-2xl font-bold pb-4'>Sign in to Ongshak</h2>
                        <div className='flex justify-between space-x-6 '>
                            <div className='bg-primary w-full flex items-center space-x-4 p-4 rounded-xl cursor-pointer' onClick={handleGoogleLogin}>
                                <FaGooglePlusG className='w-8 h-8 fill-white' />
                                <p className='w-full text-center text-white font-bold'>Sign up with Google</p>
                            </div>
                            <div className='bg-gray-200 flex items-center justify-center p-4 rounded-xl cursor-pointer' onClick={handleGithubLogin}>
                                <FaGithub className='w-8 h-8 fill-gray-500' />
                            </div>
                        </div>
                        <div className='relative text-center py-8'>
                            <p className='absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline bg-white z-10 px-4'>Or</p>
                            <div className='absolute top-1/2 left-0 w-full border -z-2'>

                            </div>
                        </div>

                        <form onSubmit={handleSignin}>
                            <FormInput type="email" htmlFor="fEmail" label="Email Address" handleChange={setEmail} />
                            <div className='flex flex-col w-full relative'>
                                <label className='font-bold pb-2' htmlFor="fPass">Password</label>
                                <input onChange={(e) => setPassword(e.target.value)} className='border border-gray-300 outline-none p-2 rounded-lg w-full' type={passType ? "password" : "text"} id="fPass" />
                                {
                                    !passType ? <FaEye className='absolute bottom-[13px] right-[10px] cursor-pointer' onClick={() => setPassType(!passType)} /> : <FaEyeSlash className='absolute bottom-[13px] right-[10px] cursor-pointer' onClick={() => setPassType(!passType)} />
                                }
                            </div>
                            <div className='mt-4'>
                                <input type="submit" className='bg-[#E85BA7] text-white font-bold px-4 py-2 rounded-lg' value={isLoading ? "Processing" : "Sign in"} />
                            </div>
                            {
                                isError && <p className="text-red-500 pt-2">{authError.split("Firebase:")[1]}</p>
                            }
                        </form>
                        {
                            error ? <p className='text-red-500 pt-4'>{error}</p> : null
                        }
                        <div className='md:hidden text-center pt-6'>
                            <p className='text-xl'>Already a member?<span className='text-primary cursor-pointer' onClick={() => setShowPage("signup")}>Sign in</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
