import SingleAddAssessment from './SingleAddAssessment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useFirestore } from '../../../../hooks/useFirestore';
import { toast } from 'react-toastify';
import Toast from '../../../../Shared/UI/Toast';

export default function AddJobAssessment({assessments, setAssessments}) {
    let [type, setType] = useState("");
    let [assessment, setAssessment] = useState({
        question: '',
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        questionType: "",
        correctAnswerIndex: null
    })

    const handleAdd = () => {
        setAssessments([...assessments, assessment] )
        setAssessment({
            question: '',
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            questionType: "",
            correctAnswerIndex: null
        })
        setType("");
    }


    return (
        <div className='w-full pb-8 '>
            <div className="max-w-screen-md mx-auto">
                <h2 className='py-8 text-3xl font-bold'>Add Assessment - {assessments?.length}</h2>
                <div className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md'>


                    <SingleAddAssessment type={type} setType={setType} assessment={assessment} setAssessment={setAssessment} />

                    <div>
                        <button className='btn bg-admin_primary text-white px-4 py-2 rounded-md' onClick={handleAdd}>Add Assessment</button>
                    </div>


                    {/* {
            assessmentNumber?.map(number => (
              <SingleAssessment number={number} setAssessments ={setAssessments} assessments ={assessments} selectAssessment={selectAssessment} />
            ))
            } */}



                </div>
                <Toast />
            </div>
        </div>
    )
}
