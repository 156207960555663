import React from 'react'
import { FaTimes } from 'react-icons/fa'

export default function CreatorSkill() {
    return (
        <div className='w-full bg-secondary rounded-md p-6'>
            <div className='flex justify-between items-center pb-4'>
                <h2 className='text-xl font-bold'>Your skills set</h2>
                <button>Edit</button>
            </div>
            <div className='flex gap-4 flex-wrap'>
                <div className='bg-white rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                    <p className='text-primary'>Javascript</p>
                    <FaTimes className='fill-primary' />
                </div>
                <div className='bg-white rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                    <p className='text-primary'>Javascript</p>
                    <FaTimes className='fill-primary' />
                </div>
                <div className='bg-white rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                    <p className='text-primary'>Javascript</p>
                    <FaTimes className='fill-primary' />
                </div>
                <div className='bg-white rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                    <p className='text-primary'>Javascript</p>
                    <FaTimes className='fill-primary' />
                </div>
            </div>
            <div className='py-6'>
                <h2 className='text-xl font-bold pb-4'>Add new skill</h2>
                <div className='flex items-end space-x-6 w-full'>
                    <input type="text" placeholder='Technology name' className='px-4 py-2 outline-none w-full rounded-md' />
                    <div className='w-1/3'>
                    <button className='bg-primary px-4 py-2 text-white rounded-md' >Add</button>
                    </div>
                </div>
            </div>
            <div className='text-right'>
                <button className='bg-primary text-white px-4 py-2 rounded-md'>Update</button>
            </div>
        </div>
    )
}
