import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    showBar: true,
    page: 1
}

const creatorsNavSlice = createSlice({
    name: "creatorsNavSlice",
    initialState,
    reducers: {
        toggleBar: (state, action) => {
            state.showBar = action.payload;
        },

        pageChange: (state, action) => {
            state.page = action.payload;
        }
    }
})

export const isShowBar = (state) => state.creatorNav.showBar;
export const pageNumber = (state) => state.creatorNav.page;

export default creatorsNavSlice.reducer;
export const { toggleBar, pageChange } = creatorsNavSlice.actions;

