import React, { useEffect } from 'react'
import signupimg from "../assets/images/contact.png"
import { FaFacebookSquare, FaLinkedin, FaYoutube } from "react-icons/fa";
import { useState } from 'react';
import { useFirestore } from '../hooks/useFirestore';
import { toast } from 'react-toastify';
import Toast from './UI/Toast';

export default function ContactForm() {
    let initialState = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: ""
    };
    const [contactDetails, setContactDetails] = useState({...initialState});

    const { addDocument, response, isLoading } = useFirestore("contact_details")

    useEffect(() => {
        if (response.success) {
            toast.success("Contact details submited.");
            setContactDetails({...initialState})
        } else if(response.error) {
            toast.error("Could not submit contact details. please try again")
        }
    }, [response])

    const handleSubmit = e => {
        e.preventDefault();
        if(isLoading) {
            return
        }

        if (!contactDetails?.firstName || !contactDetails.lastName || !contactDetails.email || !contactDetails.message || !contactDetails.phoneNumber) {
            toast.error("Please fill all the required field.")
            return
        }
        addDocument(contactDetails);
    }
    return (
        <div className='max-w-5xl w-full  mx-auto flex justify-center items-center z-20 px-4 absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2'>
            <div className='bg-white w-full grid grid-cols-1 md:grid-cols-2  place-items-center rounded-xl overflow-hidden'>
                <div className='hidden col-span-1 md:flex flex-col justify-center items-center p-16'>
                    <img src={signupimg} className="h-auto w-full" />
                    <div className='flex space-x-4'>
                        <a href="https://www.facebook.com/ongshak/" target='_blank'>
                            <div className='bg-gray-200 flex items-center justify-center p-2 rounded-xl'>
                                <FaFacebookSquare className='w-8 h-8 fill-gray-500' />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/ongshak/mycompany/" target='_blank'>
                            <div className='bg-gray-200 flex items-center justify-center p-2 rounded-xl'>
                                <FaLinkedin className='w-8 h-8 fill-gray-500' />
                            </div>
                        </a>
                        <a href="https://www.youtube.com/channel/UCORwe1FE3dPpRHQ73n6Qtcw" target='_blank'>
                            <div className='bg-gray-200 flex items-center justify-center p-2 rounded-xl'>
                                <FaYoutube className='w-8 h-8 fill-gray-500' />
                            </div>
                        </a>
                    </div>
                </div>
                <div className='col-span-1 p-4'>
                    <div className='py-12 px-8 w-full'>
                        <h2 className='text-2xl font-bold'>Contact Us</h2>
                        <p className=' pb-4 text-gray-700'>24/7 We will answer your questions and problems</p>

                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col md:flex-row space-between md:space-x-6'>
                                <div className='flex flex-col w-full'>
                                    <label className='font-bold pb-2' htmlFor='fName'>First Name</label>
                                    <input className='border border-gray-300 outline-none p-2 rounded-lg w-full' type="text" id='fName' value={contactDetails?.firstName} onChange={e => setContactDetails({ ...contactDetails, firstName: e.target.value })} />
                                </div>
                                <div className='flex flex-col w-full'>
                                    <label className='font-bold pb-2' htmlFor='lName'>Last Name</label>
                                    <input className='border border-gray-300 outline-none p-2 rounded-lg w-full' type="text" id='lName' value={contactDetails?.lastName} onChange={e => setContactDetails({ ...contactDetails, lastName: e.target.value })} />
                                </div>
                            </div>

                            <div className='flex flex-col md:flex-row md:space-x-6'>
                                <div className='flex flex-col w-full'>
                                    <label className='font-bold pb-2' htmlFor='fEmail'>Email</label>
                                    <input className='border border-gray-300 outline-none p-2 rounded-lg w-full' type="email" id='fEmail' value={contactDetails?.email} onChange={e => setContactDetails({ ...contactDetails, email: e.target.value })} />
                                </div>
                                <div className='flex flex-col w-full'>
                                    <label className='font-bold pb-2' htmlFor='fPhone'>Phone</label>
                                    <input className='border border-gray-300 outline-none p-2 rounded-lg w-full' type="phone" id='fPhone' value={contactDetails?.phoneNumber} onChange={e => setContactDetails({ ...contactDetails, phoneNumber: e.target.value })} />
                                </div>
                            </div>

                            <div className='flex flex-col'>
                                <label className='font-bold pb-2' htmlFor='fMessage'>Your Message</label>
                                <textarea className='border border-gray-300 outline-none p-2 rounded-lg w-full h-[120px] resize-none' id='fMessage' value={contactDetails?.message} onChange={e => setContactDetails({ ...contactDetails, message: e.target.value })} />
                            </div>


                            <input type="submit" className='bg-primary text-white font-bold px-4 py-2 my-6 rounded-lg' value="Send Message" />
                        </form>
                    </div>
                    <Toast />
                </div>
            </div>
        </div>
    )
}
