import React from 'react'
import { FaClipboardList, FaMicrochip, FaShoppingBag } from 'react-icons/fa'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import { useCollection } from '../../hooks/useCollection'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from '../../hooks/useFirestore'
import { toast } from 'react-toastify'
import Toast from '../../Shared/UI/Toast'
import { isShowAuthModal, toggleAuthModal } from '../../features/globalState/globalStateSlice'

export default function JobDetails() {
    const { document } = useCollection("Jobs");
    const { document: allCreatorDetails } = useCollection("creator_details");
    const { email } = useSelector(state => state.authDetail);

    const creator = allCreatorDetails.filter(creator => creator?.basicInfo?.email === email);

    const { id } = useParams();
    let [job, setJob] = useState({});
    let [showAssessment, setShowAssessment] = useState(false);

    const { updateDocument, response, isLoading } = useFirestore("Jobs");


    // Assessment functionality Start
    const [answers, setAnswers] = useState(Array(10).fill(null));
    const { document: creatorDetails } = useCollection("creator_details");

    const navigate = useNavigate("");

    // handle answer selection
    function handleAnswerSelection(questionIndex, answerIndex) {
        console.log("questionIndex, answerIndex", questionIndex, answerIndex)
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = answerIndex;
        setAnswers(updatedAnswers);
        localStorage.setItem('answers', JSON.stringify(updatedAnswers)); // store the updated answers in local storage
    }

    // retrieve the answers from local storage if they exist
    useEffect(() => {
        const storedAnswers = JSON.parse(localStorage.getItem('answers'));
        if (storedAnswers) {
            setAnswers(storedAnswers);
        }
    }, []);

    // Assessment functionality End


    useEffect(() => {
        let filteredJob = document?.filter(individualJob => individualJob?.id === id)[0];
        setJob(filteredJob);
    }, [document, id])

    const showModal = useSelector(isShowAuthModal);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(toggleAuthModal(!showModal));
    }

    const handleJObApply = () => {
        if (!email) {
            handleToggle()
            return
        }
        setShowAssessment(true);
    }

    const handleALredyApplied = () => {
        toast.error("we are analyzing your application. please stay tuned.")
    }

    useEffect(() => {
        if (response.success) {
            toast.success("Successfully applied.");
            localStorage.removeItem("answers");
            setShowAssessment(false);
        }
    }, [response])

    const isAlreadyApplied = job?.candidates?.some(indJob => indJob?.creator?.basicInfo?.email === email);

    const handleSubmit = e => {
        console.log(!creator[0]?.roleDefined === "admin" && (!creator[0]?.role === "Intermediate" || !creator[0]?.role === "Advanced"))
        if (!creator[0]?.basicInfo?.resume) {
            toast.error("Before apply into our job please upload your resume from your dashboard");
            return
        } else if (creator[0]?.roleDefined === "creator" || creator[0]?.role === "Beginner") {
            toast.error("You should be Intermediate/Advanced creator.");
            return
        }
        const updatedObj = {
            ...job,
            candidates: [
                ...job?.candidates,
                {
                    creator: creator[0],
                    answers
                }
            ]
        }

        updateDocument(job?.id, updatedObj)
    }




    // Saved Job
    const [savedJobs, setSavedJobs] = useState([]);

    // code to load saved jobs from local storage on component mount
    useEffect(() => {
        const savedJobsJson = localStorage.getItem("savedJobs");
        const savedJobsArr = JSON.parse(savedJobsJson) || [];
        setSavedJobs(savedJobsArr);
    }, []);

    // code to save new job to local storage and add to the saved jobs list
    const handleSaveJob = (newJob) => {

        let isJobExist = savedJobs.find(job => job.id === newJob.id);
        if (isJobExist) {
            const newSavedJobs = savedJobs.filter((job, i) => job?.id !== newJob.id);
            setSavedJobs(newSavedJobs);
            localStorage.setItem("savedJobs", JSON.stringify(newSavedJobs));
        } else {
            const newSavedJobs = [...savedJobs, newJob];
            setSavedJobs(newSavedJobs);
            localStorage.setItem("savedJobs", JSON.stringify(newSavedJobs));
        }
    };

    const isJobSaved = savedJobs?.filter(job => job?.id === id);

    const { pathname } = useLocation();
    let dynamicPath = `/dashboard/jobs/${id}`

    return (
        <div>
            {
                pathname !== dynamicPath ? <Navbar /> : null
            }

            <div className='max-w-screen-xl mx-auto min-h-[50vh]  flex flex-col md:flex-row gap-4 items-start p-4'>
                <div className='w-full'>
                    <div className='py-6'>
                        <h2 className='text-2xl'>{job?.title}</h2>
                        <p className='pb-4'>Ongshak .  Remote</p>
                        <div className='flex gap-4 items-center'>
                            <FaShoppingBag />
                            <p>{job?.jobType}</p>
                        </div>
                        {/* <div className='flex gap-4 items-center'>
                            <FaClipboardList />
                            <p>50 - 200 employees</p>
                        </div> */}
                        <div className='flex gap-4 items-center'>
                            <FaMicrochip />
                            <p>Skills: {job?.categories?.slice(0, 2).map(category => category + ", ")} {job?.categories?.length - 2}+more</p>
                        </div>
                        <div className="flex gap-4 my-8">
                            {
                                isAlreadyApplied ? <button className='bg-blue-500 hover:bg-primary px-4 py-2 text-white rounded-full  font-bold' onClick={handleALredyApplied}>Already Applied</button> : <button className='bg-blue-500 hover:bg-primary px-4 py-2 text-white rounded-full  font-bold' onClick={handleJObApply}>Apply</button>
                            }

                            {
                                isJobSaved?.length ? <button className='border border-primary px-4 py-2 rounded-full hover:bg-secondary font-bold' onClick={() => handleSaveJob(job)}>Saved</button> : <button className='border border-primary px-4 py-2 rounded-full hover:bg-secondary font-bold' onClick={() => handleSaveJob(job)}>Save</button>
                            }

                        </div>

                        {/* Details */}
                        {
                            !showAssessment ? <div > 
                                <h2 className='text-2xl font-bold'>About the job</h2>
                                <div style={{ lineHeight: "1.6" }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: job?.jobDetail }}
                                    />
                                </div>

                                <div className='pt-6'>
                                    <h2 className='text-gray-500 font-bold'>Job Position: {job?.title}</h2>
                                    <p ><span className='text-gray-500 font-bold'>Job Level:</span> {job?.level}</p>
                                    <p ><span className='text-gray-500 font-bold'>Salary:</span> {job?.salary} BDT</p>
                                    <p ><span className='text-gray-500 font-bold'>Job Location:</span> Hybrid (Remote)</p>
                                </div>
                            </div> : <div className='w-full'>
                                {/* Job Assessment */}
                                {
                                    job?.assessments?.map((assessment, questinoIndex) => (
                                        <div className='py-2'>
                                            <h1 className='text-xl pb-4'>{assessment?.question}</h1>
                                            {
                                                assessment?.questionType === "option" ? <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                                    {console.log("answers[questinoIndex] == 3", answers[questinoIndex] == 3)}
                                                    <div className={` p-4 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 0 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 0)} >
                                                        {assessment?.option1}
                                                    </div>
                                                    <div className={` p-4 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 1 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 1)} >
                                                        {assessment?.option2}
                                                    </div>
                                                    <div className={` p-4 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 2 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 2)} >
                                                        {assessment?.option3}
                                                    </div>
                                                    <div className={` p-4 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 3 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 3)} >
                                                        {assessment?.option4}
                                                    </div>
                                                </div> : <textarea value={answers[questinoIndex]} className='w-full h-[160px] resize-none focus:outline-none p-2 border border-primary rounded-md' onChange={(e) => handleAnswerSelection(questinoIndex, e.target.value)} />
                                            }
                                        </div>
                                    ))
                                }
                                <div className='text-left'>
                                    <button className='bg-primary text-white px-4 py-2 rounded-md' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <Toast />
            </div>
            <Footer />
        </div>
    )
}
