import React, { useState } from 'react'
import { FaPen, FaTrashAlt } from 'react-icons/fa'
import { useCollection } from '../../../../hooks/useCollection';

export default function AdminAllPrograms() {
  let [showEditForm, setShowEditForm] = useState(false);

  const { document: allPrograms } = useCollection("all-programs");
  return (
    <div className='w-full pb-8'>
      <div className="max-w-screen-xl mx-auto py-6">

        <div className='flex justify-between items-center'>
          <h2 className='py-8 text-3xl font-bold'>All Programs</h2>
          <div class="relative">
            <select
              class="border-[#1DD1A1] w-[200px] appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
            >
              <option value="">New to Old</option>
              <option value="">Old to New</option>
            </select>
            <span
              class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
            >
            </span>
          </div>
        </div>
        {/* All Program */}
        <div className='rounded-md bg-[#E7FFF9] overflow-hidden'>

          <table className="w-full table-auto rounded-md ">
            <thead className='bg-[#1DD1A1] text-white '>
              <tr className="text-dark font-bold text-center">
                <th
                  className="w-4/6 min-w-[160px] border-l border-transparent py-4 px-2 text-lg font-semibold lg:py-7 "
                >
                  Program Name
                </th>
                <th
                  className="w-1/6 min-w-[160px] py-4 px-2 text-lg font-semibold lg:py-7"
                >
                  Enrolled Student
                </th>
                <th
                  className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-2 text-lg font-semibold lg:py-7"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                allPrograms?.map(program => (
                  <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                    <td
                      className="py-2 px-2  text-base"
                    >
                      <p className='py-4'>{program?.title}</p>
                    </td>
                    <td
                      className="py-2 px-2  text-base"
                    >
                      {program?.enrolledStudent?.length ? program?.enrolledStudent?.length : 0}
                    </td>

                    <td
                      className="y-2 px-2  text-base"
                    >
                      <div className='flex space-x-4 justify-center'>
                        <FaPen onClick={() => setShowEditForm(true)} />
                        <FaTrashAlt />
                      </div>
                    </td>

                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>

        {/* Edit Program */}
        {
          showEditForm ? <form className='w-full bg-[#E7FFF9] p-10 mt-8 rounded-md drop-shadow-md'>
            <div className='flex flex-col'>
              <label className='pb-4'>Course Title</label>
              <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' />
            </div>
            <div className='flex flex-col'>
              <label className='pb-4'>Course Details</label>
              <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
            </div>
            <div className='flex flex-col'>
              <label className='pb-4'>Course Prerequisite</label>
              <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
            </div>
            <div className='flex flex-col'>
              <label className='pb-4'>Course Outcomes</label>
              <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
            </div>
            <div className='flex flex-col pb-4'>
              <label className='pb-4'>Difficulty Level</label>
              <div class="relative">
                <select
                  class="border-[#1DD1A1] w-full appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                >
                  <option value="">Option</option>
                  <option value="">Option</option>
                  <option value="">Option</option>
                </select>
                <span
                  class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                >
                </span>
              </div>
            </div>

            <input type="submit" value="Add Course" className='bg-[#1DD1A1] p-4 text-white rounded-md' />


          </form> : null
        }

      </div>
    </div>
  )
}
