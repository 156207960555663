import React from 'react'
import { FaAngleRight } from "react-icons/fa"
import { Link } from 'react-router-dom'
import { Button } from '../../../Shared/UI/Button';

export default function Blog({ blogObject }) {
    let blogParams = blogObject?.title?.split(" ").join("-");
    return (
        <div className='bg-white drop-shadow-xl rounded-lg overflow-hidden'>
            <img src={blogObject?.blogImage} alt={blogObject?.title} className="w-full" />
            <div className='p-6'>
                <h2 className='text-xl font-bold pb-4'>{blogObject?.title}</h2>
                <Link to={`/blogs/${blogParams}`}>
                {/* <button className='text-xs flex items-center bg-primary text-white px-4 py-2 rounded-md'>Read more <span><FaAngleRight /></span></button> */}
                <Button>Read more</Button>
                </Link>
            </div>
        </div>
    )
}
