import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, updateProfile } from "firebase/auth"
import { auth, db, timeStamp } from "../../firebase.config"
import { addDoc, collection } from "firebase/firestore"

const initialState = {
    error: null,
    isLoading: false,
    success: false
}

export const createDocument = createAsyncThunk("fireStore/addDocument", async ({ doc, collectionName }) => {
    const ref = collection(db, collectionName);
    const createdAt = timeStamp.fromDate(new Date());
    const addingDocument = await addDoc(ref, { ...doc, createdAt });
    return addingDocument
})

const addDocumentSlice = createSlice({
    name: "fireStore",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createDocument.pending, (state, action) => {
                state.error = null;
                state.isLoading = true;
                state.success = false
            })
            .addCase(createDocument.fulfilled, (state, action) => {
                state.error = null;
                state.isLoading = false;
                state.success = true
            })
            .addCase(createDocument.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
                state.success = true;
            })

    }
})

export const addDocumentData = (state) => state.addDocument;

export default addDocumentSlice.reducer;
export const { } = addDocumentSlice.actions;

