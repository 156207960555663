import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAddJob from './Components/AdminAddJob'

export default function AdminAddJobs() {
    return (
        <div className='flex bg-white'>
            <SuperAdminSidebar />
            <AdminAddJob />
        </div>
    )
}
