import React, { useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp, FaCheck, FaCheckCircle, FaLock, FaPlay, FaTasks } from 'react-icons/fa'
import CoursetutorialPart from './CoursetutorialPart';
import { toast } from 'react-toastify';
import Toast from "../../../Shared/UI/Toast"

export default function CourseLessonPart({ program, currentTutorial, setCurrentTutorial, setShowAssessment }) {
    let [showLesson, setShowLesson] = useState(false);

    const handleCurrentTutorial = (tutorial, id) => {
        if (tutorial?.watch) {
            setCurrentTutorial({ ...tutorial, id });
            setShowAssessment(false)
        } else {
            toast.error("You should complete sequence wise.")
        }
    }

    useEffect(() => {
        if (!currentTutorial?.title && program?.studentEmail) {
            setCurrentTutorial({ ...program?.curriculum[0], id: 0 });
            setShowLesson(true);
        }
    }, [program])

    return (
        <div>
            <div className='flex items-center gap-4 justify-between'>
                <p className='text-primary font-bold'>{program?.title}</p>
                {
                    showLesson ? <FaAngleUp onClick={() => setShowLesson(!showLesson)} className='fill-primary cursor-pointer w-4 h-4' /> : <FaAngleDown onClick={() => setShowLesson(!showLesson)} className='fill-primary cursor-pointer w-4 h-4' />
                }
            </div>
            {
                showLesson && <div className='py-4 max-h-[500px] overflow-y-scroll  scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-blue-300 course'>
                    {
                        program?.curriculum?.map((tutorial, id) => (
                            <>
                                {/* <CoursetutorialPart tutorial={tutorial} /> */}
                                <>
                                    {
                                        currentTutorial?.title === tutorial?.title ? <div div className='flex items-start gap-4 bg-secondary rounded-md p-2 cursor-pointer' >
                                            <FaPlay className='w-6 h-6 basis-12 fill-primary' />
                                            <p className=' w-full'>{tutorial?.title}</p>
                                        </div> : <div className='flex items-start gap-4 p-2 cursor-pointer' onClick={() => handleCurrentTutorial(tutorial, id)} >
                                            {
                                                tutorial?.watch ? <>
                                                    <FaCheckCircle className='w-6 h-6 basis-12 fill-primary' />
                                                    <p className=' w-full'>{tutorial?.title}</p>
                                                </> : <>
                                                    <FaLock className='w-6 h-6 basis-12 fill-primary' />
                                                    <p className=' w-full'>{tutorial?.title}</p>
                                                </>
                                            }

                                        </div>
                                    }


                                </>
                            </>
                        ))
                    }
                    
                </div>
            }
            <Toast />
        </div >
    )
}
