import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    showAuthModal: false,
    showContactModal: false,
    showAuthPage: "signup"
}

const globalStateSlice = createSlice({
    name: "globalSlice",
    initialState,
    reducers: {
        toggleAuthModal: (state, action) => {
            state.showAuthModal = action.payload;
        },

        toggleContactModal: (state, action) => {
            state.showContactModal = action.payload;
        },

        changeAuthPage: (state, action) => {
            state.showAuthPage = action.payload;
        }
    }
})

export const isShowAuthModal = (state) => state.globalState.showAuthModal;
export const isShowContactModal = (state) => state.globalState.showContactModal;
export const authPage = (state) => state.globalState.showAuthPage;

export default globalStateSlice.reducer;
export const { toggleAuthModal, toggleContactModal, changeAuthPage } = globalStateSlice.actions;

