import React, { useState } from 'react'
import { FaPen, FaTrashAlt, FaExternalLinkAlt, FaEye } from 'react-icons/fa'
import creatorImg from "../../../../assets/images/rafi.jpg"
import Modal from '../../../../Shared/Modal';
import { useCollection } from '../../../../hooks/useCollection'
import ShowCreator from './ShowCreator';
import EditCreator from './EditCreator';

export default function AdminAllCreator() {
    const [showEditForm, setShowEditForm] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [creatorDetail, setCreatorDetail] = useState({});
    const [creatorEditDetail, setCreatorEditDetail] = useState({});

    const { document: allCreators } = useCollection("creator_details")

    return (
        <div className='w-full pb-8'>
            <div className="max-w-screen-xl mx-auto py-16">

                {
                    showCard && creatorDetail ? <Modal setShowModal={setShowCard}>
                        <ShowCreator creatorDetail={creatorDetail} />
                    </Modal>
                        : null
                }

                {/* All Program */}

                <div className='flex justify-between items-center'>
                    <h2 className='py-8 text-3xl font-bold'>All Creators</h2>
                    <div class="relative">
                        <select
                            class="border-[#1DD1A1] w-[200px] appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                        >
                            <option value="">New to Old</option>
                            <option value="">Old to New</option>
                        </select>
                        <span
                            class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                        >
                        </span>
                    </div>
                </div>

                <div className='rounded-md bg-[#E7FFF9] overflow-hidden'>

                    <table className="w-full table-auto rounded-md ">
                        <thead className='bg-[#1DD1A1] text-white '>
                            <tr className="text-center text-dark font-bold">
                                <th
                                    className="w-3/12 min-w-[160px] border-l border-transparent py-4 px-2 text-lg font-semibold lg:py-7"
                                >
                                    Creator Name
                                </th>
                                <th
                                    className="w-3/12 min-w-[160px] py-4 px-2 text-lg font-semibold lg:py-7 "
                                >
                                    Email
                                </th>
                                <th
                                    className="w-2/12 min-w-[160px] border-r border-transparent py-4 px-2 text-lg font-semibold lg:py-7 "
                                >
                                    Phone
                                </th>
                                <th
                                    className="w-1/12 min-w-[160px] border-r border-transparent py-4 px-2 text-lg font-semibold lg:py-7"
                                >
                                    Details
                                </th>
                                <th
                                    className="w-1/12 min-w-[160px] border-r border-transparent py-4 px-2 text-lg font-semibold lg:py-7"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allCreators?.map(creator => (
                                    <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center gap-2 justify-center'>

                                                <p className={`py-4 `}>{creator?.basicInfo?.firstName} {creator?.basicInfo?.lastName}</p>
                                                {
                                                    creator?.roleDefined === "admin" ? <div className='w-4 h-4 bg-admin_primary rounded-full'></div> : null
                                                }
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <p>{creator?.basicInfo?.email}</p>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <p>{creator?.basicInfo?.phoneNumber}</p>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaEye onClick={() => {
                                                    setCreatorDetail(creator);
                                                    setCreatorEditDetail({});
                                                    setShowCard(true);
                                                    setShowEditForm(false)
                                                    window.scrollTo(0, 0);
                                                }}
                                                />
                                            </div>
                                        </td>

                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaPen onClick={() => {
                                                    setCreatorEditDetail(creator)
                                                    setCreatorDetail({})
                                                    setShowEditForm(true)
                                                    setShowCard(false)
                                                }} />
                                                <FaTrashAlt />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }



                        </tbody>
                    </table>
                </div>

                {
                    showEditForm && creatorEditDetail ? <EditCreator creatorEditDetail={creatorEditDetail} /> : null
                }
            </div>
        </div>
    )
}
