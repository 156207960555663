import React from 'react'
import heroImg from "../../../assets/images/hero_img.png"
import html from "../../../assets/images/html.png"
import css from "../../../assets/images/css.png"
import typescript from "../../../assets/images/typescript.png"
import javascript from "../../../assets/images/javascript.png"
import node from "../../../assets/images/node.png"
import react from "../../../assets/images/react.png"
import { FaAngleRight } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import { isShowAuthModal, toggleAuthModal } from '../../../features/globalState/globalStateSlice'
import { useNavigate } from 'react-router-dom'

export default function Hero({ setShowModal }) {
    const dispatch = useDispatch();
    const showModal = useSelector(isShowAuthModal);

    const {email} = useSelector(state => state.authDetail);
    const navigate = useNavigate("")

    const handleToggle = () => {
        if(email) {
            navigate("/all-programs");
            return
        }
        dispatch(toggleAuthModal(!showModal));
    }
    return (
        <div className='max-w-screen-xl mx-auto'>
            <div className='flex flex-col md:flex-row items-center justify-between py-8 md:py-0 p-4 '>
                <div>
                    <h1 className='text-center md:text-left text-3xl md:text-5xl font-bold pb-8'>Modern Skill Based <br /><span className='text-primary'>Learning Approach.</span></h1>
                    <p className='text-center md:text-left text-gray-500 pb-8 text-md md:text-lg'>Access to FREE learning resources & experience the modern <br />skill-based working environment</p>
                    <div className='flex space-x-4'>
                        <div className='bg-[#1DD1A1] md:font-bold text-white py-4 rounded-lg px-2 md:px-8 flex items-center space-x-2 cursor-pointer' onClick={handleToggle}>
                            <button className='text-md md:text-xl' >Explore Programs </button>
                            <FaAngleRight className='mt-1' />
                        </div>
                        <div className='bg-secondary flex flex-col justify-center items-center text-center py-2 px-6 text-sm rounded-lg'>
                            <span className='font-bold text-xl'>4+</span>
                            <p className='text-base'>Years</p>
                        </div>
                        <div className='bg-secondary flex flex-col justify-center items-center text-center py-2 px-6 text-sm rounded-lg'>
                            <span className='font-bold text-xl'>20+</span>
                            <p className='text-base'>Projects</p>
                        </div>
                    </div>

                    <div className='py-8 flex items-center justify-center md:justify-start space-x-4'>
                        <img src={html} alt="html" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        <img src={css} alt="css" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        <img src={javascript} alt="javascript" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        <img src={typescript} alt="typescript" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        <img src={react} alt="react" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        <img src={node} alt="node" className='md:w-12 w-8 h-8 md:h-12 object-contain' />
                        {/* <img src={css} alt="css" />
                        <img src={typescript} alt="typescript" />
                        <img src={javascript} alt="javascript" />
                        <img src={node} alt="node" />
                        <img src={react} alt="react" /> */}
                    </div>
                </div>
                <div>
                    <img src={heroImg} alt="Hero image" />
                </div>
            </div>
        </div>
    )
}
