import React, { useState } from 'react'
import { FaTrashAlt, FaEye } from 'react-icons/fa'
import { useCollection } from '../../../../hooks/useCollection'
import Modal from '../../../../Shared/Modal';
import AppliedJob from './AppliedJob';

export default function AdminAllJob() {
    const [showAppliedCreator, setShowAppliedCreator] = useState(false);
    const [individualJob, setIndividualJob] = useState({});
    const { document: allJobs } = useCollection("Jobs");
    return (
        <div className='w-full pb-8'>
            <div className="max-w-screen-xl mx-auto py-6">

                {
                    showAppliedCreator && individualJob ? <Modal setShowModal={setShowAppliedCreator}>
                        <AppliedJob jobApplied={individualJob} />
                    </Modal>
                        : null
                }
                <div className='flex justify-between items-center'>
                    <h2 className='py-8 text-3xl font-bold'>All Jobs</h2>
                    <div class="relative">
                        <select
                            class="border-[#1DD1A1] w-[200px] appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                        >
                            <option value="">New to Old</option>
                            <option value="">Old to New</option>
                        </select>
                        <span
                            class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                        >
                        </span>
                    </div>
                </div>

                {/* All Program */}
                <div className='rounded-md bg-[#E7FFF9] overflow-hidden'>

                    <table className="w-full table-auto rounded-md ">
                        <thead className='bg-[#1DD1A1] text-white '>
                            <tr className="text-center text-dark font-bold">
                                <th
                                    className="w-6/12 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Position
                                </th>
                                <th
                                    className="w-3/12 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Applied
                                </th>
                                <th
                                    className="w-2/12 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Applied Creator
                                </th>
                                <th
                                    className="w-1/12 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allJobs?.map(job => (
                                    <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <p className='py-4'>{job.title}</p>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            {job?.candidates?.length ? job?.candidates?.length : 0} person
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaEye className='text-center' onClick={() => {
                                                    setShowAppliedCreator(true);
                                                    setIndividualJob(job)}} />
                                            </div>
                                        </td>

                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaTrashAlt />
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
