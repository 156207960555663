import React, { useState } from 'react'
import ShowCreator from '../../Creators/Components/ShowCreator';

export default function AppliedJob({ jobApplied }) {
    const [showCreator, setShowCreator] = useState(false);
    console.log("jobApplied", jobApplied)
    return (
        <>
            <div className='max-w-5xl w-full min-h-min mx-auto flex justify-center items-center z-20 absolute top-[100px] left-1/2  -translate-x-1/2 -translate-y-0'>

                <div className='relative bg-[#E7FFF9] p-16 drop-shadow-md rounded-md  min-w-[900px]'>
                    <div className='flex justify-between items-start'>
                    <h2 className='text-xl pb-8'><span className='font-bold'>Job Title:</span> {jobApplied?.title}</h2>
                    {
                        showCreator && <button className='bg-admin_primary px-4 py-2 rounded-md text-white'  onClick={() => setShowCreator(false)}>Cancel Profile</button>
                    }
                    </div>
                    <div>

                        {
                           jobApplied?.candidates?.length ? jobApplied?.candidates?.map(candidate => (
                                <div className='bg-white p-4 flex justify-between items-center'>
                                    <h2>{candidate?.creator?.basicInfo?.firstName}</h2>
                                    <button className='bg-admin_primary px-4 py-2 rounded-md text-white' onClick={() => setShowCreator(true)}>View Profile</button>

                                    {
                                        showCreator && <ShowCreator creatorDetail={candidate?.creator} />
                                    }
                                </div>
                            )) : <div>No cadidate applied yet.</div>
                        }
                    </div>


                </div>
            </div>
        </>
    )
}
