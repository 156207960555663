import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAllCreator from './Components/AdminAllCreator'

export default function AdminAllCreators() {
  return (
    <div className='flex bg-white'>
      <SuperAdminSidebar />
      <AdminAllCreator />
    </div>
  )
}
