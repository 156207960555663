import React from 'react'

export default function Modal({ children, setShowModal }) {
    return (
        <>
            <div
                className="fixed w-full max-h-screen inset-0 z-20 bg-black/50 cursor-pointer"
                onClick={() => setShowModal(false)}
            ></div>
            {children}
        </>
        // <>
        //     <div className="fixed w-full h-full inset-0 z-10 bg-black/50 cursor-pointer" onClick={() => setShowModal(false)}>
        //     </div>
        //     <div className='w-full'>

        //         {children}
        //     </div>
        // </>
    )
}
