import React, { useEffect, useState } from 'react'
import CourseLesson from './CourseLesson'
import CourseTutorial from './CourseTutorial'

export default function CourseDetail({ program }) {
  let [currentTutorial, setCurrentTutorial] = useState({});
  let [showAssessment, setShowAssessment] = useState(false);
  return (
    <div className='max-w-screen-xl mx-auto min-h-[50vh] flex flex-col md:flex-row items-start gap-4 p-4'>
      <CourseTutorial program={program} currentTutorial={currentTutorial} setCurrentTutorial={setCurrentTutorial} showAssessment={showAssessment} setShowAssessment={setShowAssessment} />
      <CourseLesson program={program} currentTutorial={currentTutorial} setCurrentTutorial={setCurrentTutorial}setShowAssessment={setShowAssessment} />
    </div>
  )
}
