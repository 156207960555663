import React from 'react'
import approach1 from "../../../assets/images/approach1.png"
import approach2 from "../../../assets/images/approach2.png"
import approach3 from "../../../assets/images/approach3.png"
import number1 from "../../../assets/images/1.png"
import number2 from "../../../assets/images/2.png"
import number3 from "../../../assets/images/3.png"

export default function Approach() {
    return (
        <div className='bg-secondary py-[120px]'>
            <div className='max-w-screen-xl mx-auto '>
                <div className='text-center pb-12 px-6'>
                    <h2 className='text-2xl md:text-4xl font-bold pb-6'>Our Approach to Innovation</h2>
                    <p className='text-gray-700 text-md md:text-lg'>Hands-on, skill-based learning is at the heart of everything you do at Ongshak</p>
                </div>

                <div className='flex flex-col space-y-8 md:space-y-0 md:space-x-8 md:flex-row m-4'>
                    <div className='bg-white rounded-xl p-8 relative shadow-large'>
                        <img src={number1} alt="number one" className='absolute top-8 right-12'  />
                        <div className='w-16 h-16  bg-secondary rounded-full p-[5px]'>
                            <img src={approach1} alt="approach" className=' p-2 object-contain'/>
                        </div>
                        <div className='pt-6'>
                            <h2 className='font-bold text-xl'>20+</h2>
                            <h2 className='font-bold text-xl'>Programs to Choose from</h2>
                            <p className='pt-6'>Explore our diverse programs tailored for your learning goals and embark on a journey of professional growth</p>
                        </div>
                    </div>
                    <div className='bg-white rounded-xl p-8 relative shadow-large'>
                        <img src={number2} alt="number two" className='absolute top-8 right-12'  />
                        <div className='w-16 h-16  bg-secondary rounded-full p-[5px]'>
                            <img src={approach2} alt="approach" className=' p-2 object-contain'/>
                        </div>
                        <div className='pt-6'>
                            <h2 className='font-bold text-xl'>Research</h2>
                            <h2 className='font-bold text-xl'>and Innovation</h2>
                            <p className='pt-6'>Unleash your potential with our cutting-edge research and innovation initiatives and exciting job offerings</p>
                        </div>
                    </div>
                    <div className='bg-white rounded-xl p-8 relative shadow-large'> 
                        <img src={number3} alt="number three" className='absolute top-8 right-12'  />
                        <div className='w-16 h-16  bg-secondary rounded-full p-[5px]'>
                            <img src={approach3} alt="approach" className=' p-2 object-contain'/>
                        </div>
                        <div className='pt-6'>
                            <h2 className='font-bold text-xl'>Certification</h2>
                            <h2 className='font-bold text-xl'>and Earning Support</h2>
                            <p className='pt-6'>Boost your earning potential with our comprehensive certification programs and dedicated support</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
