import React, { useState } from 'react'
import Toast from '../../../../Shared/UI/Toast';
import RichTextEditor from './RichTextEditor';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { projectStorage } from '../../../../firebase.config';
import { useFirestore } from '../../../../hooks/useFirestore';
import { useEffect } from 'react';

export default function AdminAddBlog() {
    const initialBlog = {
        title: "",
        blogerName: "",
        blogImage: "",
        blogDetails: "",
        comments: []
    }
    const [blog, setBlog] = useState({...initialBlog});
    let [blogContent, setBlogContent] = useState("");
    let [fileProgress, setFileProgress] = useState("");

    const {addDocument, response, isLoading} = useFirestore("blogs");

    useEffect(() => {
        if (response?.success) {
            toast.success("Blog added Successfully.")
        }
        if (response?.error) {
            toast.success("Could not add Blog")
        }

    }, [response])

    const imageTypes = ['image/png', 'image/jpeg', "image/jpg"];

    const handlImage = (e) => {
        let selected = e.target.files[0];

        if (selected && !imageTypes.includes(selected.type)) {
            // setDetails({ ...details, image: "" })
            toast.error('Please select an image file (png , jpg Or JPeg) for program picture');
            return
        }
        const storageRef = ref(projectStorage, `profile/${selected.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selected);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFileProgress(progress);
            },
            (error) => {
                toast.error(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setBlog({ ...blog, blogImage: downloadURL });
                });
            }
        );
    }

    const handleSubmit = e => {
        e.preventDefault();
        addDocument({
            ...blog,
            blogDetails: blogContent
        });
        setBlog({...initialBlog});
        setBlogContent("");
    }
    return (
        <div className='w-full pb-8'>
            <div className="max-w-screen-md mx-auto">
                <h2 className='py-8 text-3xl font-bold'>Add Blog</h2>
                <form className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md' onSubmit={handleSubmit}>
                    <div className='w-3/4 min-h-[160px] h-auto mx-auto bg-white flex justify-center items-center font-bold text-2xl '>
                        {
                            blog?.image ? <img src={blog?.image} alt={blog?.title} /> : <h2>Preview Image {fileProgress}</h2>
                        }
                    </div>

                    <div className='flex flex-col'>
                        <label className='pb-4'>Blog Image</label>
                        <input
                            type="file"
                            class="border-[#1DD1A1] text-black placeholder-[#1DD1A1] focus:border-[#1DD1A1] active:border-[#1DD1A1] file:border-[#1DD1A1] file:text-[#1DD1A1]  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#1DD1A1] file:text-white file:py-3 file:px-5 disabled:cursor-default "
                            onChange={handlImage}
                        />
                    </div>

                    <div className='flex flex-col'>
                        <label className='pb-4'>Blog Title</label>
                        <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' value={blog?.title} onChange={e => setBlog({...blog, title: e.target.value})} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Bloger Name</label>
                        <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' value={blog?.blogerName} onChange={e => setBlog({...blog, blogerName: e.target.value})} />
                    </div>


                    <RichTextEditor

                        setContent={setBlogContent}
                        placeholder="Write your blog details"

                    />
                    <Toast />

                    <input type="submit" value="Add Course" className='bg-[#1DD1A1] p-4 mt-4 text-white rounded-md' />
                </form>
            </div>
        </div>
    )
}
