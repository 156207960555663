import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAllPrograms from './Components/AdminAllPrograms'

export default function ProgramPanel() {
  return (
    <div className='flex bg-white'>
        <SuperAdminSidebar />
        <AdminAllPrograms />
    </div>
  )
}
