import React from 'react'

export default function CreatorsInfoInput({ placeholder, type, value, onChange }) {
    return (
        <div className='w-full mt-4'>
            <label className=''>{placeholder}</label>
            <input type={type} value={value} onChange={onChange} className='w-full border-b-2 border-black p-2 outline-none' />
        </div>
    )
}
