import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home/Home";
import ProgramDetails from "./pages/ProgramDetails/ProgramDetails";
import AllPrograms from "./pages/AllPrograms/AllPrograms";
import Blogs from "./pages/Blogs/Blogs";
import BlogRead from "./pages/BlogRead/BlogRead";
import ProgramPanel from "./pages/SuperAdmin/ProgramPanel/ProgramPanel";
import AdminAddPrograms from "./pages/SuperAdmin/ProgramPanel/AdminAddPrograms";
import AdminAllBlogs from "./pages/SuperAdmin/AdminBlogs/AdminAllBlogs";
import AdminAddBlogs from "./pages/SuperAdmin/AdminBlogs/AdminAddBlogs";
import AdminAllCreators from "./pages/SuperAdmin/Creators/AdminAllCreators";
import AdminallJobs from "./pages/SuperAdmin/AdminJobs/AdminallJobs";
import AdminAddJobs from "./pages/SuperAdmin/AdminJobs/AdminAddJobs";
import CreatorsInfo from "./pages/CreatorsInfo/CreatorsInfo";
import Dashboard from "./pages/Dashboard/Dashboard";
import BasicInfo from "./pages/Profile/BasicInfo/BasicInfo";
import Jobs from "./pages/Jobs/Jobs";
import JobDetails from "./pages/Jobs/JobDetails";
import CourseDetails from "./pages/Dashboard/CourseDetails";
import Experiences from "./pages/Profile/Experiences/Experiences";
import Skills from "./pages/Profile/Skills/Skills";
import AllCourses from "./pages/CreatorsInfo/AllCourses/AllEnrolledCourses";
// import Alljob from "";
import { useAuthContext } from "./hooks/useAuthContext";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { setUser, toggleUser } from "./features/auth/authSlice";
// import AllJobs from "";
import { isShowAuthModal, isShowContactModal, toggleAuthModal, toggleContactModal } from "./features/globalState/globalStateSlice";
import Modal from "./Shared/Modal";
import Form from "./Shared/Form";
import Signin from "./pages/Signin/Signin";
import { useState } from "react";
import ContactForm from "./Shared/ContactForm";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import AdminAddAssessments from "./pages/SuperAdmin/AdminAssessment/AdminAddAssessments";
import Assessment from "./pages/Assessment/Assessment";
import { useCollection } from "./hooks/useCollection";
import Level from "./pages/Level/Level";
import Loader from "./Shared/UI/Loader";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminProtectedRoute from "./ProtectedRoute/AdminProtectedRoute";
import AllSuperAdmin from "./pages/SuperAdmin/AllSuperAdmin/AllSuperAdmin";
import AddSuperAdmin from "./pages/SuperAdmin/AllSuperAdmin/AddSuperAdmin";
import ScrollToTop from "./Shared/utils/ScrollToTop";
import AllJobDashboardDetails from "./pages/CreatorsInfo/Alljob/AllJobDashboardDetails";
import EnrolledCourses from "./pages/CreatorsInfo/AllCourses/Components/EnrolledCourses";
import AllEnrolledCourses from "./pages/CreatorsInfo/AllCourses/AllEnrolledCourses";
import AllDashbaordCourses from "./pages/CreatorsInfo/AllCourses/AllDashbaordCourses";

const Alljob = lazy(() => import('./pages/CreatorsInfo/Alljob/Alljob'));
const AllJobs = lazy(() => import('./pages/AllJobs/AllJobs'));

function App() {
  let [showPage, setShowPage] = useState("signup");

  const showModal = useSelector(isShowAuthModal);
  const showContactModal = useSelector(isShowContactModal);
  const { email, isLoading } = useSelector(state => state.authDetail);
  // console.log("home isLoading", isLoading)

  const dispatch = useDispatch();

  const { document } = useCollection('creator_admin');

  useEffect(() => {
    if (document.length !== 0) localStorage.setItem('creators_admin', JSON.stringify(document));
  }, [document]);

  const handleToggle = () => {
    dispatch(toggleAuthModal(!showModal));
  }

  const handleContactToggle = () => {
    dispatch(toggleContactModal(!showContactModal));
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setUser({ email: user?.email, displayName: user?.displayName }))
      } else {
        dispatch(toggleUser())
      }
    })
  }, [email]);

  return (
    <>
      <div >
        <ScrollToTop />
        {
          (showModal && showPage === "signup") && <Modal setShowModal={handleToggle}  >
            <Form setShowPage={setShowPage} />
          </Modal>

        }
        {
          (showModal && showPage === "signin") && <Modal setShowModal={handleToggle} >
            <Signin setShowPage={setShowPage} />
          </Modal>
        }

        {
          showContactModal && <Modal setShowModal={handleContactToggle}>
            <ContactForm />
          </Modal>
        }

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/all-programs" element={<AllPrograms />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:blogTitle" element={<BlogRead />} />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/all-jobs" element={
            <Suspense fallback={<Loader />}>
              <AllJobs />
            </Suspense>
          } />
          <Route path="/all-jobs/:id" element={<JobDetails />} />

          {
            !isLoading && <>
              <Route path="/creators-info" element={<ProtectedRoute><CreatorsInfo /></ProtectedRoute>} />
              {/* Creators dashboard */}
              <Route path="/dashboard/all-courses" element={<ProtectedRoute><AllDashbaordCourses /></ProtectedRoute>} />
              <Route path="/dashboard/enrolled-courses" element={<ProtectedRoute><AllEnrolledCourses /></ProtectedRoute>} />
              <Route path="/dashboard/courses/:id" element={<ProtectedRoute><CourseDetails /></ProtectedRoute>} />
              <Route path="/all-programs/programDetails/:id" element={<ProtectedRoute><ProgramDetails /></ProtectedRoute>} />
              <Route path="/level" element={<ProtectedRoute><Level /></ProtectedRoute>} />
              <Route path="/level" element={<ProtectedRoute><Level /></ProtectedRoute>} />
              <Route path="/dashboard/jobs/:id" element={<AllJobDashboardDetails />} />
              <Route path="/dashboard/jobs" element={<ProtectedRoute><Suspense fallback={<Loader />}>
                <Alljob />
              </Suspense>
              </ProtectedRoute>
              } />
            </>
          }

          {/* Creators Profile edit page */}
          {/* Depricated page start */}
          <Route path="/dashboard/profile" element={<BasicInfo />} />
          <Route path="/dashboard/profile/experience" element={<Experiences />} />
          <Route path="/dashboard/profile/skills" element={<Skills />} />
          {/* Depricated page end */}




          {/* Admin  dashboard */}
          <Route path="/admin-login" element={<AdminLogin />} />

          <Route path="/admin/all-programs" element={<AdminProtectedRoute><ProgramPanel /></AdminProtectedRoute>} />
          <Route path="/admin/add-program" element={<AdminProtectedRoute><AdminAddPrograms /></AdminProtectedRoute>} />

          <Route path="/admin/all-blogs" element={<AdminProtectedRoute><AdminAllBlogs /></AdminProtectedRoute>} />
          <Route path="/admin/add-blogs" element={<AdminProtectedRoute><AdminAddBlogs /></AdminProtectedRoute>} />

          <Route path="/admin/all-creators" element={<AdminProtectedRoute><AdminAllCreators /></AdminProtectedRoute>} />

          <Route path="/admin/all-jobs" element={<AdminProtectedRoute><AdminallJobs /></AdminProtectedRoute>} />
          <Route path="/admin/add-job" element={<AdminProtectedRoute><AdminAddJobs /></AdminProtectedRoute>} />

          <Route path="/admin/all-jobs" element={<AdminProtectedRoute><AdminallJobs /></AdminProtectedRoute>} />
          <Route path="/admin/add-assessment" element={<AdminProtectedRoute><AdminAddAssessments /></AdminProtectedRoute>} />

          <Route path="/admin/all-admin" element={<AdminProtectedRoute><AllSuperAdmin /></AdminProtectedRoute>} />
          <Route path="/admin/add-admin" element={<AdminProtectedRoute><AddSuperAdmin /></AdminProtectedRoute>} />
        </Routes>
      </div >
    </>
  );
}

export default App;
