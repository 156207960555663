import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GithubAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, updateProfile } from "firebase/auth"
import { auth, db, timeStamp } from "../../firebase.config"
import { addDoc, collection } from "firebase/firestore"

const initialState = {
    email: "",
    password: "",
    displayName: "",
    error: "",
    isError: false,
    isLoading: true
}


export const createUser = createAsyncThunk("auth/createUser", async ({ email, password, displayName, level }) => {
    await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(auth.currentUser, { displayName });
    const [firstName, lastName] = displayName.split(" ");
    const ref = collection(db, "creator_details");
    const createdAt = timeStamp.fromDate(new Date());
    const addingDocument = await addDoc(ref, {
        basicInfo: {
            firstName,
            lastName,
            email,
            image: "",
            phoneNumber: "",
            dateOfBirth: "",
            gender: "",
            institute: "",
            resume: ""
        },
        skills: [],
        experiences: [],
        additionalInfo: {
            github: "",
            linkedin: "",
            portfolio: "",
            others: "",
        },
        assessments: [],
        role: level,
        roleDefined: "creator"
    });
    return auth.currentUser;
})

export const loginUser = createAsyncThunk("auth/loginUser", async ({ email, password }) => {
    await signInWithEmailAndPassword(auth, email, password);
    return auth.currentUser;
})

export const googleLogin = createAsyncThunk("auth/googleLogin", async () => {
    const googleProvider = new GoogleAuthProvider();

    await signInWithPopup(auth, googleProvider);
    return auth.currentUser;
})

export const githubLogin = createAsyncThunk("auth/githubLogin", async () => {
    const githubProvider = new GithubAuthProvider();

    await signInWithPopup(auth, githubProvider);
    return auth.currentUser;
})

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.email = "";
            state.displayName = "";
        },
        setUser: (state, { payload }) => {
            state.email = payload.email;
            state.displayName = payload.displayName;
            state.isLoading = false;
        },
        toggleUser: (state) => {
            state.isLoading = false;
        }
       
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state, action) => {
                state.isLoading = true;
                state.isError = false;
                state.error = "";
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.error = "";
                state.email = action.payload.email;
                state.displayName = action.payload.displayName;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message;
                state.email = "";
                state.displayName = "";
            })
            // Login Case
            .addCase(loginUser.pending, (state, action) => {
                state.isLoading = true;
                state.isError = false;
                state.error = "";
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.error = "";
                state.email = action.payload.email;
                state.displayName = action.payload.displayName;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message;
                state.email = "";
                state.displayName = "";
            })
            // Google Login
            .addCase(googleLogin.pending, (state, action) => {
                state.isLoading = true;
                state.isError = false;
                state.error = "";
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.error = "";
                state.email = action.payload.email;
                state.displayName = action.payload.displayName;
            })
            .addCase(googleLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message;
                state.email = "";
                state.displayName = "";
            })
            // Github Login
            .addCase(githubLogin.pending, (state, action) => {
                state.isLoading = true;
                state.isError = false;
                state.error = "";
            })
            .addCase(githubLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.error = "";
                state.email = action.payload.email;
                state.displayName = action.payload.displayName;
            })
            .addCase(githubLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message;
                state.email = "";
                state.displayName = "";
            })
    }
})

export default authSlice.reducer;
export const { setUser, logout, toggleUser } = authSlice.actions;

