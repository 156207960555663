import React from 'react'
import { useSelector } from 'react-redux';
import { isShowBar } from '../../../features/CreatorsNav/CreatorsNavSlice';
import CreatorsInfoSidebar from '../Components/CreatorsInfoSidebar';
import EnrolledCourses from './Components/EnrolledCourses';
import { useCollection } from '../../../hooks/useCollection';
import AllPrograms from '../../AllPrograms/AllPrograms';
import CreatorsDashboardLayout from '../../../Shared/Layout/CreatorsDashboardLayout';

export default function AllEnrolledCourses() {
    const { email } = useSelector(state => state.authDetail);

    const { document: allCreatorDetails } = useCollection("creator_details");
    const creator = allCreatorDetails.filter(creator => creator?.basicInfo?.email === email);

    return (
        <CreatorsDashboardLayout creatorDetails={creator[0]}>
            <EnrolledCourses />
        </CreatorsDashboardLayout>
    )
}
