import React, { useEffect, useState } from 'react'
import { FaPen, FaTrashAlt, FaExternalLinkAlt } from 'react-icons/fa'
import { useCollection } from '../../../../hooks/useCollection';

export default function AdminAllBlog() {
    let [showEditForm, setShowEditForm] = useState(false);
    let [sortingBy, setSortingBy] = useState("");
    let [blogs, setBlogs] = useState([]);
    // const { document: blogs } = useCollection("blogs",  undefined, sortingBy && ["createdAt", sortingBy]);
    const { document: allBlogs } = useCollection("blogs");

    useEffect(() => {
        let filteredBySortAsc = allBlogs?.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.createdAt?.toDate()) - new Date(b.createdAt?.toDate());
        });
        let filteredBySortDesc = allBlogs?.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
        });
        if (sortingBy === "asc") {
            setBlogs(filteredBySortAsc);
        } else {
            setBlogs(filteredBySortDesc);
        }
        console.log("ssss", filteredBySortAsc, filteredBySortDesc);
    }, [sortingBy, allBlogs]);

    return (
        <div className='w-full pb-8'>
            <div className="max-w-screen-xl mx-auto py-6">
                <div className='flex justify-between items-center'>
                    <h2 className='py-8 text-3xl font-bold'>All Blogs</h2>
                    <div class="relative">
                        <select
                            class="border-[#1DD1A1] w-[200px] appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                            onChange={(e) => setSortingBy(e.target.value)}
                        >
                            <option value="">Sorting By</option>
                            <option value="asc">New to Old</option>
                            <option value="desc">Old to New</option>
                        </select>
                        <span
                            class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                        >
                        </span>
                    </div>
                </div>
                {/* All Program */}
                <div className='rounded-md bg-[#E7FFF9] overflow-hidden'>

                    <table className="w-full table-auto rounded-md ">
                        <thead className='bg-[#1DD1A1] text-white '>
                            <tr className="text-center text-dark font-bold">
                                <th
                                    className="w-4/6 min-w-[160px] border-l border-transparent py-4 px-2 text-lg font-semibold lg:py-7"
                                >
                                    Program Name
                                </th>
                                <th
                                    className="w-1/6 min-w-[160px] py-4 px-2 text-lg font-semibold lg:py-7"
                                >
                                    Blog Image
                                </th>
                                <th
                                    className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-2 text-lg font-semibold lg:py-7 "
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                blogs?.map(blog => (
                                    <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <p className='py-4'>{blog?.title}</p>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <a href={blog?.blogImage} target='_blank'>
                                                    <FaExternalLinkAlt />
                                                </a>
                                            </div>
                                        </td>

                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaPen onClick={() => setShowEditForm(true)} />
                                                <FaTrashAlt />
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            }


                        </tbody>
                    </table>
                </div>

                {/* Edit Program */}
                {
                    showEditForm ? <form className='w-full bg-[#E7FFF9] mt-6 p-10 rounded-md drop-shadow-md'>
                        <div className='w-3/4 h-[160px] mx-auto bg-white flex justify-center items-center font-bold text-2xl '>
                            <h2>Preview Image</h2>
                        </div>

                        <div className='flex flex-col'>
                            <label className='pb-4'>Blog Image</label>
                            <input
                                type="file"
                                class="border-[#1DD1A1] text-black placeholder-[#1DD1A1] focus:border-[#1DD1A1] active:border-[#1DD1A1] file:border-[#1DD1A1] file:text-[#1DD1A1]  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#1DD1A1] file:text-white file:py-3 file:px-5 disabled:cursor-default "
                            />
                        </div>

                        <div className='flex flex-col'>
                            <label className='pb-4'>Blog Title</label>
                            <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' />
                        </div>
                        <div className='flex flex-col pb-4'>
                            <label className='pb-4'>Blog Details</label>
                            <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
                        </div>
                        <input type="submit" value="Add Course" className='bg-[#1DD1A1] p-4 text-white rounded-md' />


                    </form> : null
                }

            </div>
        </div>
    )
}
