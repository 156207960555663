import React from 'react'
import signupimg from "../assets/images/signup.png"
import { FaGooglePlusG, FaFacebookSquare, FaEye, FaEyeSlash, FaGithub } from "react-icons/fa";
import { useEffect } from 'react';
import { useSignup } from '../hooks/useSignup';
import { useState } from 'react';
import FormInput from './Components/FormInput';
import { createUser, githubLogin, googleLogin } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isShowAuthModal, toggleAuthModal } from '../features/globalState/globalStateSlice';

export default function Form({ setShowPage }) {
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [level, setLevel] = useState("");
    let [isChecked, setIsChecked] = useState(false);
    let [error, setError] = useState("");
    let [passType, setPassType] = useState(true);

    const { email: logedInUser, isLoading, isError, error: authError } = useSelector(state => state.authDetail);

    const navigate = useNavigate();

    const showModal = useSelector(isShowAuthModal);

    const handleToggle = () => {
        dispatch(toggleAuthModal(!showModal));
    }

    useEffect(() => {
        if (logedInUser) {
            // setShowPage("");
            // navigate("/all-programs");
            handleToggle()
        }
    }, [logedInUser])

    let authenticateError = null;

    if (authError !== "Firebase: Error (auth/popup-closed-by-user).") {
        authenticateError = authError.split("Firebase:")[1];
    }

    const dispatch = useDispatch();

    const handleSignup = (e) => {
        e.preventDefault();
        setError("");
        if (!firstName || !lastName || !email || !password || !level || !isChecked) {
            setError("Please, fill all the input field to sign up")
            return
        }
        dispatch(createUser({ email, password, displayName: `${firstName} ${lastName}`, level }))

    }

    const handleGoogleLogin = () => {
        dispatch(googleLogin())
    }

    const handleGithubLogin = () => {
        dispatch(githubLogin())
    }
    return (
        <div className='max-w-6xl w-full mx-auto flex justify-center items-center  px-4 absolute top-[130px] left-1/2 z-20 -translate-x-1/2 ' id="#form">
            <div className='bg-white w-full grid grid-cols-1 md:grid-cols-2 rounded-xl overflow-hidden z-50'>
                <div className='hidden col-span-1 md:flex items-center p-4'>
                    <img src={signupimg} />
                </div>
                <div className='col-span-1 p-4 '>
                    <div className='hidden md:block text-right'>
                        <p className='text-xl'>Already a member? <span className='text-primary cursor-pointer' onClick={() => setShowPage("signin")}>Sign in</span></p>
                    </div>
                    <div className='py-12 px-4 md:px-8 w-full md:w-5/6 '>
                        <h2 className='text-2xl font-bold pb-4'>Sign up to Ongshak</h2>
                        <div className='flex justify-between space-x-6 '>
                            <div className='bg-primary w-full flex items-center space-x-4 p-4 rounded-xl cursor-pointer' onClick={handleGoogleLogin}>
                                <FaGooglePlusG className='w-8 h-8 fill-white' />
                                <p className='text-white text-center w-full font-bold'>Sign up with Google</p>
                            </div>
                            <div className='bg-gray-200 flex items-center justify-center p-4 rounded-xl cursor-pointer' onClick={handleGithubLogin}>
                                <FaGithub className='w-8 h-8 fill-gray-500' />
                            </div>
                        </div>
                        <div className='relative text-center py-8'>
                            <p className='absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline bg-white z-10 px-4'>Or</p>
                            <div className='absolute top-1/2 left-0 w-full border -z-2'>

                            </div>
                        </div>

                        <form onSubmit={handleSignup}>
                            <div className='flex flex-col md:flex-row md:space-x-6'>
                                <div className='flex flex-col w-full py-2'>
                                    <label className='font-bold pb-2' htmlFor="fLevel">Select your level</label>
                                    <select id="fLevel" className='w-full border border-gray-300 outline-none p-2 rounded-lg' onChange={e => setLevel(e.target.value)}>
                                        <option value="">Please Select your level</option>
                                        <option value="Beginner">Beginner</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Advanced">Advanced</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row md:space-x-6'>
                                <FormInput type="text" htmlFor="fName" label="First Name" handleChange={setFirstName} />
                                <FormInput type="text" htmlFor="lName" label="Last Name" handleChange={setLastName} />
                            </div>
                            {/* <div className='flex flex-col pt-2 w-full'>
                                <label className='font-bold pb-2' htmlFor='email'>Email Address</label>
                                <input className='w-full border border-gray-300 outline-none p-2 rounded-lg' type="email" id='email' />
                            </div> */}
                            <FormInput type="email" htmlFor="fEmail" label="Email Address" handleChange={setEmail} />
                            <div className='flex flex-col w-full relative'>
                                <label className='font-bold pb-2' htmlFor="fPass">Password</label>
                                <input onChange={(e) => setPassword(e.target.value)} className='border border-gray-300 outline-none p-2 rounded-lg w-full' type={passType ? "password" : "text"} id="fPass" />
                                {
                                    !passType ? <FaEye className='absolute bottom-[13px] right-[10px] cursor-pointer' onClick={() => setPassType(!passType)} /> : <FaEyeSlash className='absolute bottom-[13px] right-[10px] cursor-pointer' onClick={() => setPassType(!passType)} />
                                }
                            </div>
                            <div className='flex space-x-2 pb-2'>
                                <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                                <p className='text-gray-700 pt-4'>Creating account means you are okay with our <Link to="/terms-condition"><span className='text-primary' onClick={() => handleToggle()}>Terms and Condition</span></Link></p>
                            </div>
                            <input type="submit" disabled={isLoading} className='bg-[#E85BA7] text-white font-bold px-4 py-2 rounded-lg' value={isLoading ? "Processing" : "Create Account"} />

                            {
                                isError && <p className="text-red-500 pt-2">{authenticateError}</p>
                            }
                        </form>
                        {
                            error ? <p className='text-red-500 pt-4'>{error}</p> : null
                        }
                        <div className='md:hidden text-center pt-6'>
                            <p className='text-xl'>Already a member?<span className='text-primary cursor-pointer' onClick={() => setShowPage("signin")}>Sign in</span></p>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
