import React from 'react'
import achieve1 from "../../../assets/images/achieve1.png"
import achieve2 from "../../../assets/images/achieve2.png"

export default function Achieve() {
    return (
        <div className='max-w-screen-xl mx-auto '>
            <div className='bg-secondary rounded-lg p-8 mx-4'>
                <p className='text-gray-500 mb-4'>Achieve More</p>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 '>
                    <div className='pr-6 '>
                        <h2 className='font-bold text-xl md:text-[32px] pb-4 md:pb-0 leading-8'>Creating success with our developers network</h2>
                    </div>
                    <div className='flex space-x-2 '>
                        <div>
                            <img src={achieve1} alt="achieve1" className='w-12 h-6' />
                        </div>
                        <div className='pr-4 md:pr-16'>
                            <h3 className='text-lg font-bold text-gray-700 pb-4'>Anywhere anytime access</h3>
                            <p>With Ongshak, learners can access resources anytime, anywhere, on their mobility tools</p>
                        </div>
                    </div>
                    <div className='flex space-x-2 '>
                        <div>
                            <img src={achieve2} alt="achieve2" className='w-12 h-6' />
                        </div>
                        <div className='pr-4 md:pr-16'>
                            <h3 className='text-lg font-bold text-gray-700 pb-4'>Secured earning opportunity</h3>
                            <p>We provide FREE learning resources with skill based learning method to make you capable of earning</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
