import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { pageChange, pageNumber } from '../../../features/CreatorsNav/CreatorsNavSlice';
import CreatorsInfoInput from '../../../Shared/Components/CreatorsInfoInput'
import CreatorsInfoFooter from '../../../Shared/CreatorsInfoFooter';
import { useFirestore } from '../../../hooks/useFirestore';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function CreatorsInfoPageFour({ showBar, setCreatorDetails, creatorDetails, creator }) {
    let width = 75;

    const page = useSelector(pageNumber);
    const { displayName } = useSelector(state => state.authDetail);
    const [firstName, lastName] = displayName.split(" ");
    const dispatch = useDispatch();

    const { addDocument, updateDocument, response } = useFirestore("creator_details");

    useEffect(() => {
        if (response?.success) {
            toast.success("Your additional info submited.");
        }
    }, [response?.success])


    const handlePagePrev = () => {
        dispatch(pageChange(page - 1))
    }

    const handleSubmit = () => {
        if (creatorDetails?.additionalInfo?.github && creatorDetails?.additionalInfo?.linkedin && creatorDetails?.additionalInfo?.portfolio) {
            updateDocument(creator?.id, creatorDetails);
        }
    }

    // const handlePageNext = () => {

    //     if (creatorDetails?.additionalInfo?.github && creatorDetails?.additionalInfo?.linkedin && creatorDetails?.additionalInfo?.portfolio) {
    //         updateDocument(creator?.id, creatorDetails);
    //     } else {
    //         toast.error("Additional Information is required.")
    //     }
    //     dispatch(pageChange(page + 1))
    // }

    return (
        <div className={`w-full bg-white flex flex-col justify-between ${showBar ? "hidden md:flex" : ""}`}>
            <div className='w-full md:p-8'>
                <div className='max-w-6xl mx-auto p-8 '>
                    <h2 className='text-2xl md:text-4xl font-bold mb-4'>Hi, {lastName}</h2>
                    <h2 className='text-2xl md:text-4xl font-bold w-full'>Additional info</h2>
                    <div className='grid gap-x-12 grid-cols-1 md:grid-cols-2'>
                        <CreatorsInfoInput type="text" placeholder="Github Link" value={creatorDetails?.additionalInfo?.github} onChange={(e) => setCreatorDetails({ ...creatorDetails, additionalInfo: { ...creatorDetails?.additionalInfo, github: e.target.value } })} />

                        <CreatorsInfoInput type="text" placeholder="Linkedin Link" value={creatorDetails?.additionalInfo?.linkedin} onChange={(e) => setCreatorDetails({ ...creatorDetails, additionalInfo: { ...creatorDetails?.additionalInfo, linkedin: e.target.value } })} />

                        <CreatorsInfoInput type="text" placeholder="Portfolio Link" value={creatorDetails?.additionalInfo?.portfolio} onChange={(e) => setCreatorDetails({ ...creatorDetails, additionalInfo: { ...creatorDetails?.additionalInfo, portfolio: e.target.value } })} />

                        <CreatorsInfoInput type="text" placeholder="Others" value={creatorDetails?.additionalInfo?.others} onChange={(e) => setCreatorDetails({ ...creatorDetails, additionalInfo: { ...creatorDetails?.additionalInfo, others: e.target.value } })} />
                    </div>

                    <div className='flex space-x-4 my-6'>
                        <input type="checkbox" />
                        <p>I agree with all <a>Terms & Conditions</a></p>
                    </div>

                </div>
            </div>

            <div className='w-full'>
                <div className='max-w-6xl mx-auto p-8 flex items-center justify-between'>
                    <FaArrowLeft className='w-4 h-4 md:w-6 md:h-6 cursor-pointer' onClick={handlePagePrev} />

                    <div className='w-1/2 bg-blue-200 rounded-full'>
                        <div className={`bg-blue-500 w-[75%] rounded-full`}>
                            <p className='text-xs font-bold text-white text-right pr-4'>75%</p>
                        </div>
                    </div>

                    <button className='bg-primary px-4 py-2 text-white rounded-md' onClick={handleSubmit} >Submit</button>
                </div>
            </div>
            {/* <CreatorsInfoFooter /> */}
        </div>
    )
}
