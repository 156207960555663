import React from 'react'
import CreatorsInfoSidebar from '../../pages/CreatorsInfo/Components/CreatorsInfoSidebar'

export default function CreatorsDashboardLayout({children, creatorDetails}) {
    return (
        <div className='min-h-screen flex'>
            <CreatorsInfoSidebar creatorDetails={creatorDetails} />
            {children}
        </div>
    )
}
