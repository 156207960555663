import React, { useEffect, useState } from 'react'
import { ProgramsBenefit, programsData } from '../Data/Data'
import Slider from 'infinite-react-carousel';
import Program from '../../../Shared/Components/Program';
import { useCollection } from '../../../hooks/useCollection';

export default function Programs() {
    const calculateSlidesToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 992) {
            return 3;
        } else if (screenWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };

    const slidesToShow = calculateSlidesToShow();

    const { document, isLoading } = useCollection("all-programs");
    let [data, setData] = useState([]);
    useEffect(() => {
        setData(document)
    }, [document]);

    let allPrograms = document.length ? document?.map(program => (<Program program={program} key={program?.id} />)) : <p>loading</p>;

    return (
        <div className='max-w-screen-xl mx-auto py-[120px]'>
            <div className='text-center px-4'>
                <h2 className='text-2xl md:text-4xl font-bold pb-6'>Transform Your Future: <br /> Discover Our Diverse Programs</h2>
                <p className='text-gray-700 text-md md:text-lg'>With a diverse range of programs, find everything you need to find your goals & transform your future</p>
            </div>

            <div className='py-8  program_card '>
                <Slider
                    slidesToShow={slidesToShow}
                    adaptiveHeight={true}
                >
                    {
                        allPrograms
                    }
                    {/* {
                        document?.map(doc => <h2>{doc}</h2>)
                    } */}
                </Slider>
            </div>
            <div className='grid grid-cols-2 gap-4 md:grid-cols-5 p-4 pt-8'>
                {
                    ProgramsBenefit?.map(pb => <div className={`flex items-center space-x-4 ${pb?.title === "Community Support" ? "hidden md:flex" : ""}`} >
                        <div className='bg-secondary p-4 rounded-full flex items-center justify-center'>
                            <img src={pb?.image} alt={pb?.title} className='w-8 h-8 object-contain' />
                        </div>
                        <p className='md:font-bold'>{pb?.title}</p>
                    </div>)
                }
            </div>
        </div>
    )
}
