import React from 'react'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import ExplorePrograms from './Components/ExplorePrograms'
import { useLocation } from 'react-router-dom'

export default function AllPrograms() {
  const { pathname } = useLocation();
  return (
    <div>
      {
        pathname !== "/dashboard/all-courses" ? <Navbar /> : null
      }

      <ExplorePrograms />
      {
        pathname !== "/dashboard/all-courses" ? <Footer /> : null
      }

    </div>
  )
}
