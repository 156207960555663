import React, { useEffect, useState } from 'react'
import { FaAngleLeft, FaUserCircle, FaCode, FaShoppingBag, FaInfoCircle, FaBorderAll, FaSignOutAlt, FaAngleRight, FaAngleDown, FaHome, FaTasks } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isShowBar, pageChange, pageNumber, toggleBar } from '../../../features/CreatorsNav/CreatorsNavSlice';
import { ToastContainer, toast } from 'react-toastify';
import profile from "../../../assets/images/profile.png"
import { logout } from '../../../features/auth/authSlice';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase.config';
import { useCollection } from '../../../hooks/useCollection';


export default function CreatorsInfoSidebar({ creatorDetails }) {
    let [dashboardDropdown, setDashboardDropdown] = useState(false)

    // ClassName
    const activeClass1 = "flex items-center justify-between space-x-4 p-4  text-black cursor-pointer duration-200  border-l-4 border-primary text-primary font-bold";
    const nonActiveClass1 = "flex items-center justify-between space-x-4 p-4  text-black cursor-pointer duration-200  hover:border-l-4 hover:border-primary hover:text-primary";
    let submenuClass = 'flex items-center space-x-4 p-4  text-black cursor-pointer pl-8 duration-200 hover:text-[#1DD1A1] hover:border-l-4 hover:border-primary hover:text-primary'
    let ActiveSubmenuClass = 'flex items-center space-x-4 p-4 cursor-pointer pl-8 duration-200 hover:border-l-4 hover:border-primary hover:text-primary text-primary font-bold '

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(toggleBar(!showBar));
    }

    const page = useSelector(pageNumber);
    const showBar = useSelector(isShowBar);
    const { email, displayName } = useSelector(state => state.authDetail);

    const { document: enrolledPrograms } = useCollection("enrolled_course");


    const studentenrolledPrograms = enrolledPrograms?.filter((program) => program?.studentEmail === email);

    const handlePage = (number) => {

        if ((number === 2 || number === 3) && (!creatorDetails.basicInfo.firstName || !creatorDetails.basicInfo.lastName || !creatorDetails.basicInfo.email || !creatorDetails.basicInfo.phoneNumber || !creatorDetails.basicInfo.dateOfBirth || !creatorDetails.basicInfo.gender || !creatorDetails.basicInfo.institute)) {
            toast.error("Please Fill up your basic information first");
            return
        }
        // } else if (number === 3 && (creatorDetails?.skills?.length < 3)) {
        //     toast.error("Please Fill up your basic information and skills page");
        //     return
        // }
        // Page 5 functionality should be writted
        dispatch(pageChange(number));
        navigate("/creators-info");
    }


    const handleLogout = () => {
        signOut(auth)
            .then((res) => {
                logout();
                window.location.reload();
            })
    }

    const { pathname } = useLocation();

    useEffect(() => {
        if(pathname.includes("/dashboard")) {
            setDashboardDropdown(true)
        }
    }, [pathname])

    return (
        <div className={`sticky top-0 left-0 ${showBar ? "w-full md:min-w-[270px] md:max-w-[270px] p-4" : ""} bg-[#F5F6FA] h-screen `}>
            <div className='absolute right-[20px] top-1/2 md:-right-[12px] border-2 border-[#F5F6FA] p-2 bg-[#F5F6FA] rounded-full'>
                {
                    showBar ? <FaAngleLeft className='w-6 h-6 cursor-pointer fill-black' onClick={handleToggle} /> : <FaAngleRight className='w-6 h-6 cursor-pointer fill-black' onClick={handleToggle} />
                }

            </div>
            {
                showBar ? <div className='flex items-center space-x-4 py-4'>
                    <img src={creatorDetails?.basicInfo?.image ? creatorDetails?.basicInfo?.image : profile} alt={displayName} className='w-16 h-16 rounded-full drop-shadow-2xl' />
                    <div>
                        <p className='font-bold'>{displayName}</p>
                    </div>
                </div> : null
            }


            <div className='flex flex-col justify-between py-2 min-h-[calc(100vh_-_8rem)]'>
                {/* Side Navigation */}
                <div>

                    <div className={nonActiveClass1} onClick={() => navigate("/")}>
                        <div className="flex space-x-4 items-center" >
                            <FaHome />
                            {
                                showBar ? <p>Home</p> : null
                            }
                        </div>
                    </div>

                    <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer duration-200  hover:border-l-4 hover:border-primary hover:text-primary' onClick={() => setDashboardDropdown(!dashboardDropdown)} >
                        <div className='flex space-x-4 items-center' >
                            <FaBorderAll />
                            {
                                showBar ? <p >Dashboard</p> : null
                            }

                            {
                                dashboardDropdown ? <FaAngleDown /> : <FaAngleRight />
                            }

                        </div>
                    </div>

                    {dashboardDropdown ?
                        <>
                            <Link to="/dashboard/all-courses" onClick={() => dispatch(pageChange(0))}>
                                <div className={pathname === "/dashboard/all-courses" ? ActiveSubmenuClass : submenuClass} >
                                    <FaCode />
                                    {
                                        showBar ? <p>All Courses</p> : null
                                    }

                                </div>
                            </Link>
                            <Link to="/dashboard/jobs" onClick={() => dispatch(pageChange(0))} >
                                <div className={pathname === "/dashboard/jobs" ? ActiveSubmenuClass : submenuClass} >
                                    <FaTasks />
                                    {
                                        showBar ? <p>All Jobs</p> : null
                                    }

                                </div>
                            </Link>
                            {
                                studentenrolledPrograms?.length ? <Link to="/dashboard/enrolled-courses" onClick={() => dispatch(pageChange(0))}>
                                    <div className={pathname === "/dashboard/enrolled-courses" ? ActiveSubmenuClass : submenuClass} >
                                        <FaCode />
                                        {
                                            showBar ? <p>Enrolled Courses</p> : null
                                        }

                                    </div>
                                </Link> : null
                            }
                        </> : null
                    }

                    <div className={page === 1 && pathname === "/creators-info" ? activeClass1 : nonActiveClass1} onClick={() => handlePage(1)}>
                        <div className="flex space-x-4 items-center" >
                            <FaUserCircle />
                            {
                                showBar ? <p>Basic Information</p> : null
                            }
                        </div>
                    </div>
                    {/* <div className={page === 2 ? activeClass1 : nonActiveClass1} onClick={() => handlePage(2)}>
                        <div className="flex space-x-4 items-center" >
                            <FaCode />
                            {
                                showBar ? <p >Skills</p> : null
                            }

                        </div>
                    </div> */}
                    <div className={page === 2 ? activeClass1 : nonActiveClass1} onClick={() => handlePage(2)}>
                        <div className="flex space-x-4 items-center" >
                            <FaShoppingBag />
                            {
                                showBar ? <p>Experience</p> : null
                            }

                        </div>
                    </div>
                    <div className={page === 3 ? activeClass1 : nonActiveClass1} onClick={() => handlePage(3)}>
                        <div className="flex space-x-4 items-center">
                            <FaInfoCircle />
                            {
                                showBar ? <p>Additional Info</p> : null
                            }

                        </div>
                    </div>

                    <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer duration-200  hover:border-l-4 hover:border-primary hover:text-primary' >
                        <div className='flex space-x-4 items-center' >
                            <FaSignOutAlt />
                            {
                                showBar ? <p onClick={handleLogout}>Logout</p> : null
                            }

                        </div>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                        pauseOnHover
                    />
                </div>
                {
                    showBar ? <div>
                        <p className='text-center text-gray-500'>Copyright © 2023 Ongshak</p>
                    </div> : null
                }

            </div>
        </div>
    )
}
