import React from 'react'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import BlogReads from './Components/BlogReads'

export default function BlogRead() {
  return (
    <div>
        <Navbar />
        <BlogReads />
        <Footer />
    </div>
  )
}
