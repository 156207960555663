// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBG7jWMM0qabVMsYVfjqghKgOx5qjYZxP0",
  authDomain: "mvp-creators-2023.firebaseapp.com",
  projectId: "mvp-creators-2023",
  storageBucket: "mvp-creators-2023.appspot.com",
  messagingSenderId: "415102821038",
  appId: "1:415102821038:web:5d69fa6d30b152f60f433f",
  measurementId: "G-8XW5JLCGCM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const timeStamp = Timestamp;
export const projectStorage = getStorage();
const analytics = getAnalytics(app);
