import React from 'react'
import { RotatingTriangles } from 'react-loader-spinner'

export default function Loader() {
    return (
        <div className='w-full min-h-[500px] flex justify-center items-center'>
            <RotatingTriangles
                visible={true}
                height="80"
                width="80"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperClass="rotating-triangels-wrapper"
                colors={['#5282D3', '#44c7ce', '#f46a1c']}
            />
        </div>
    )
}
