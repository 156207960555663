import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Toast from '../../../Shared/UI/Toast';
import SelectedAssessment from './SelectedAssessment';
import usePageVisibility from '../../../hooks/usePageVisibility';

export default function Assesment() {
    const { email } = useSelector(state => state.authDetail);

    const [selectedAssessment, setSelectedAssessment] = useState({});
    const [switchTab, setSwitchTab] = useState(0);


    const { document } = useCollection("assessments");

    const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutes

    const [answers, setAnswers] = useState(Array(10).fill(null));
    const { document: creatorDetails } = useCollection("creator_details");
    const { updateDocument, isLoading, response } = useFirestore("creator_details");

    const navigate = useNavigate("");

    const actualCreator = creatorDetails?.filter(creator => creator?.basicInfo?.email === email)[0];

    // testing
    const visibilityStatus = usePageVisibility();

    useEffect(() => {
        if (visibilityStatus) {
            setSwitchTab(switchTab + 1)
        }
    }, [visibilityStatus])

    console.log("setSwitchtab", switchTab)


    // Testing

    const handleSelectAssessment = (assessment) => {
        setSelectedAssessment(assessment);
        localStorage.removeItem("answers");
        setAnswers(Array(10).fill(null))
    }

    // handle answer selection
    function handleAnswerSelection(questionIndex, answerIndex) {
        const updatedAnswers = [...answers];
        if (answerIndex === "") {
            updatedAnswers[questionIndex] = null;
        } else {
            updatedAnswers[questionIndex] = answerIndex;
        }
        setAnswers(updatedAnswers);
        localStorage.setItem('answers', JSON.stringify(updatedAnswers)); // store the updated answers in local storage
    }

    const handleSubmit = () => {
        let isNull = answers?.every(answer => answer === null);

        if (isNull) {
            toast.error("In order to submit your assessment you should answer.");
            return
        }
        let updatedObj = {
            ...actualCreator,
            assessments: [
                ...actualCreator?.assessments,
                {
                    selectedAssessment,
                    answers: answers
                }
            ]
        }

        updateDocument(actualCreator?.id, updatedObj)
    }

    useEffect(() => {
        if (timeLeft === 0 || switchTab >= 2) {
            handleSubmit();
            navigate("/");
        }
    }, [timeLeft, switchTab])

    useEffect(() => {
        if (response.success) {
            localStorage.removeItem("answers");
            toast.success("Your Assessment submission done.")
            setTimeout(() => {
                navigate("/creators-info")
            }, 1000)
        }
    }, [response])

    // retrieve the answers from local storage if they exist
    useEffect(() => {
        const storedAnswers = JSON.parse(localStorage.getItem('answers'));
        if (storedAnswers) {
            setAnswers(storedAnswers);
        }
    }, []);


    return (
        <div className='max-w-3xl mx-auto px-4 py-20'>
            {
                !selectedAssessment?.category ? <>
                    <h2 className='text-2xl text-center pb-12 font-bold'>Select your tech stack for Assessment test.</h2>
                    <div className='grid gap-12 grid-cols-3 items-center'>
                        {
                            document?.map(assessment => (
                                <div className='p-10 text-black text-xl rounded-lg cursor-pointer bg-secondary flex flex-col justify-center items-center' onClick={() => handleSelectAssessment(assessment)} >
                                    <p className='font-bold text-center pb-4'>{assessment?.category}</p>
                                    <img src={assessment?.image} className='w-20 h-20 object-contain' />
                                </div>
                            ))
                        }
                    </div>
                </> : null
            }
            {
                selectedAssessment?.allQuestions?.length ? <SelectedAssessment selectedAssessment={selectedAssessment} answers={answers} handleAnswerSelection={handleAnswerSelection} handleSubmit={handleSubmit} timeLeft={timeLeft} setTimeLeft={setTimeLeft} /> : null
            }

            {
                selectedAssessment?.category ? <div className='w-[250px] p-4 rounded-md bg-red-100  border-2 border-red-900 fixed bottom-6 right-[50px]'>
                    Do not switch to another tab or browser. If you move more than twice, we will submit your evaluation, regardless of what you choose and answer.
                </div> : null
            }
            <Toast />

        </div>
    )
}
