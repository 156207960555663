import React, { useEffect } from 'react';
import Program from '../../../Shared/Components/Program';
import { FaSearch } from "react-icons/fa";
import { useCollection } from '../../../hooks/useCollection';
import { useState } from 'react';
import Heading from '../../../Shared/UI/Heading';
import Loader from '../../../Shared/UI/Loader';

export default function ExplorePrograms() {
    const { document: allProgram, isLoading } = useCollection("all-programs");

    let [allPrograms, setAllPrograms] = useState([]);
    let [difficulty, setDifficulty] = useState("");
    let [category, setCategory] = useState("");
    let [searchName, setSearchName] = useState("");

    let [allCategory, setAllCategory] = useState([]);

    useEffect(() => {
        let programCategory = allProgram?.map(program => program?.categories);
        let uniqueCategory = [...new Set(programCategory[0]?.concat(...programCategory))];
        setAllCategory(uniqueCategory);
    }, [allProgram])

    useEffect(() => {
        if (difficulty || category) {
            // const filteredPrograms = allProgram.filter(program => program?.difficultyLevel === difficulty).filter(program => program?.categories?.includes(category));
            const filteredPrograms = allProgram.filter(program => {
                if (difficulty && !category) {
                    return difficulty === program?.difficultyLevel
                }

                if (!difficulty && category) {
                    return program?.categories?.includes(category)
                }

                if (difficulty && category) {
                    return difficulty === program?.difficultyLevel && program?.categories?.includes(category)
                }
            }).filter(program => program?.title?.toLowerCase().includes(searchName));

            setAllPrograms([...filteredPrograms]);
        } else {
            let filteredPrograms = allProgram?.filter(program => program?.title?.toLowerCase().includes(searchName));
            setAllPrograms([...filteredPrograms]);
        }
    }, [difficulty, category, searchName])

    useEffect(() => {
        setAllPrograms([...allProgram]);
    }, [allProgram])


    let programs = allPrograms?.map(program => (
        <Program key={program?.id} program={program} hovering={program?.id} />
    ));
    // let allPrograms = programsData?.map(program => (<Program key={program?.id} program={program} hovering={program?.id} />));
    return (
        <div className='max-w-screen-xl mx-auto py-12'>
            <div className='text-center'>
                <Heading>Explore Programs</Heading>
                <p className='text-left px-4 py-2 text-xl' >Sort By</p>
                <div className='flex flex-col md:flex-row justify-between  items-center space-y-4 md:space-y-0 px-4'>
                    <div className=' w-full flex flex-col md:flex-row justify-center md:justify-start space-y-4 md:space-y-0 space-x-0 md:space-x-4'>

                        <div className="relative">
                            <select
                                // className="rounded-md border py-3 px-2 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] shadow-custom"
                                className="w-full rounded-md border py-3 px-2 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] shadow-custom"
                                onChange={e => setCategory(e.target.value)}
                            >

                                <option className='' value="">Categories</option>
                                {
                                    allCategory?.map(category => (
                                        <option value={category}>{category}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="relative">
                            <select
                                className="w-full rounded-md border py-3 px-2 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] shadow-custom"
                                onChange={e => setDifficulty(e.target.value)}
                            >
                                <option value="">Difficulty</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div>
                    </div>


                    <div className=' w-full '>

                        <div className='relative w-full md:text-right'>
                            <input type="text" className='w-full md:w-[380px] px-6 py-2 rounded-full outline-none border-2 border-[#1450BD] placeholder:italic' placeholder='Search programs' onChange={e => setSearchName(e.target.value)} />
                            <FaSearch className='absolute top-0 right-1/4 md:right-0 top-1/2 -translate-x-full md:-translate-x-3/4 -translate-y-1/2 fill-primary ' />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <Loader /> : <div className='grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 pt-12'>
                    {
                        programs
                    }
                </div>
            }

        </div>
    )
}
