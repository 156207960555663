import React from 'react'
import { useCollection } from '../../../../hooks/useCollection';

export default function AllSuperAdmins() {
    const { document: allAdmin } = useCollection('creator_admin');
    return (
        <div className='w-full pb-8'>
            <div className="max-w-screen-xl mx-auto py-6">
                <div className='flex justify-between items-center'>
                    <h2 className='py-8 text-3xl font-bold'>All Admin</h2>
                </div>
                {/* All Program */}
                <div className='rounded-md bg-[#E7FFF9] overflow-hidden'>

                    <table className="w-full table-auto rounded-md ">
                        <thead className='bg-[#1DD1A1] text-white '>
                            <tr className="text-center text-dark font-bold">
                                <th
                                    className="w-6/12 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Email
                                </th>
                                <th
                                    className="w-3/12 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Passsword
                                </th>
                                <th
                                    className="w-2/12 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Role
                                </th>
                                {/* <th
                                    className="w-1/12 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                >
                                    Action
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allAdmin?.map(admin => (
                                    <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <p className='py-4'>{admin.email}</p>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            {admin?.password}
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            {admin?.role}
                                        </td>

                                        {/* <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-4 justify-center'>
                                                <FaTrashAlt />
                                            </div>
                                        </td> */}

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
