import React, { useState } from 'react'
import SingleAssessment from './SingleAssessment';
import { useEffect } from 'react';
import { useFirestore } from '../../../../hooks/useFirestore';
import { toast } from 'react-toastify';
import Toast from '../../../../Shared/UI/Toast';
import { projectStorage } from '../../../../firebase.config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export default function AdminAddAssessment({ setAssessments, assessments }) {
  let [selectAssessment, setSelectAssessment] = useState("");

  let [image, setImage] = useState("");
  let [fileProgress, setFileProgress] = useState("");
  let [type, setType] = useState("");
  let [assessment, setAssessment] = useState({
    question: '',
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    questionType: "",
    correctAnswerIndex: null
  })

  const { addDocument, isLoading, response } = useFirestore("assessments");

  useEffect(() => {
    if (response.success) {
      toast.success("Your Assessment added successfully.")
      setAssessments({
        category: "",
        allQuestions: []
      })
      setImage("");
    }
  }, [response])

  useEffect(() => {
    setAssessments({ ...assessments, category: selectAssessment, image: image })
  }, [selectAssessment])

  const imageTypes = ['image/png', 'image/jpeg', "image/jpg"];

  const handlImage = (e) => {
    let selected = e.target.files[0];

    if (selected && !imageTypes.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      toast.error('Please select an image file (png , jpg Or JPeg) for program picture');
      return
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setFileProgress(progress);
      },
      (error) => {
        toast.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
        });
      }
    );
  }


  const handleAdd = () => {
    setAssessments({ ...assessments, allQuestions: [...assessments.allQuestions, assessment] })
    setAssessment({
      question: '',
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      questionType: "",
      correctAnswerIndex: null
    })
    setType("");
  }

  const handleSubmitAssessment = () => {
    addDocument(assessments)
  }

  return (
    <div className='w-full pb-8 '>
      <div className="max-w-screen-md mx-auto">
        <h2 className='py-8 text-3xl font-bold'>Add Assessment - {assessments?.allQuestions?.length}</h2>
        <div className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md'>
          <div className='flex flex-col pb-4'>
            <label className='pb-4'>Select Category</label>
            <div class="relative">
              <select
                class="border-[#1DD1A1] w-full appearance-none rounded-md border py-2 px-4 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                value={selectAssessment}
                onChange={(e) => setSelectAssessment(e.target.value)}
              >
                <option value="">Select Assessment for</option>
                <option value="Mern">Mern</option>
                <option value="React">React</option>
                <option value="IoT">IoT</option>
                <option value="Django">Django</option>
              </select>
              <span
                class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
              >
              </span>
            </div>
          </div>

          <div className='w-3/4 min-h-[160px] h-auto mx-auto bg-white flex justify-center items-center font-bold text-2xl '>
            {
              image ? <img src={image} alt="Assessment Image" /> : <h2>Preview Image {fileProgress}</h2>
            }
          </div>

          <div className='flex flex-col'>
            <label className='pb-4'>Blog Image</label>
            <input
              type="file"
              class="border-[#1DD1A1] text-black placeholder-[#1DD1A1] focus:border-[#1DD1A1] active:border-[#1DD1A1] file:border-[#1DD1A1] file:text-[#1DD1A1]  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#1DD1A1] file:text-white file:py-3 file:px-5 disabled:cursor-default "
              onChange={handlImage}
            />
          </div>


          <SingleAssessment type={type} setType={setType} assessment={assessment} setAssessment={setAssessment} />

          <div>
            <button className='btn bg-admin_primary text-white px-4 py-2 rounded-md' onClick={handleAdd}>Add Assessment</button>
          </div>

          <div className='py-8 text-right'>
            <button className='btn bg-admin_primary text-white px-4 py-2 rounded-md' onClick={handleSubmitAssessment}>Submit Assessment</button>
          </div>


          {/* {
          assessmentNumber?.map(number => (
            <SingleAssessment number={number} setAssessments ={setAssessments} assessments ={assessments} selectAssessment={selectAssessment} />
          ))
          } */}



        </div>
        <Toast />
      </div>
    </div>
  )
}


