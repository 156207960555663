import React, { useEffect, useState } from 'react'
import Toast from '../../../../Shared/UI/Toast'
import { toast } from 'react-toastify'
import { useFirestore } from '../../../../hooks/useFirestore'

export default function AddSuperAdmins() {
    const [adminObj, setAdminObj] = useState({
        email: "",
        password: "",
        role: ""
    })

    const {addDocument, response} = useFirestore("creator_admin")

    useEffect(() => {
        if(response.success) {
            toast.success("Successfully add admin")
        }
    }, [response])

    const handleAddAdmin = () => {
        if(adminObj.email === "" || adminObj.password === "" || adminObj.role === "") {
            toast.error("Please fill all required field");
            return
        }

        addDocument(adminObj);
    }
    return (
        <div className='w-full pb-8 '>
            <div className="max-w-screen-md mx-auto">
                <h2 className='py-8 text-3xl font-bold'>Add Admin</h2>
                <div className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md'>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Admin email</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' 
                        value={adminObj?.email} onChange={e => setAdminObj({ ...adminObj, email: e.target.value })}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Admin Passsword</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' 
                        value={adminObj?.password} onChange={e => setAdminObj({ ...adminObj, password: e.target.value })}
                         />
                    </div>
                    <div className='flex flex-col pb-4'>
                        <label className='pb-4'>Admin role</label>
                        <div class="relative">
                            <select
                                class="border-[#1DD1A1] w-full appearance-none rounded-md border py-2 px-4 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                value={adminObj?.role} onChange={e => setAdminObj({ ...adminObj, role: e.target.value })}
                            >
                                <option value="">Select level</option>
                                <option value="Manager">Manager</option>
                                <option value="Controller">Controller</option>
                                <option value="Reader">Reader</option>
                            </select>
                            <span
                                class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                            >
                            </span>
                        </div>
                    </div>

                    <Toast />

                    <div className='mt-4'>
                        <button className='bg-[#1DD1A1] p-4 text-white rounded-md' onClick={handleAddAdmin}>Add Admin</button>
                    </div>


                </div>
            </div>
        </div>
    )
}
