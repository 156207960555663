import React, { useState } from 'react'
import { FaRegComment, FaPhone, FaEnvelope, FaAngleRight, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { isShowAuthModal, toggleAuthModal } from '../features/globalState/globalStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFirestore } from '../hooks/useFirestore';
import { useEffect } from 'react';
import Toast from './UI/Toast';
import { toast } from 'react-toastify';

export default function Footer() {
    const showModal = useSelector(isShowAuthModal);
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    const { addDocument, response, isLoading } = useFirestore("newsletter_subscriber");

    useEffect(() => {
        if (response.success) {
            toast.success("Successfully subscribe our newsletter.")
        }
    }, [response])

    const handleNewsLetter = () => {
        addDocument({ email })
    }


    const handleToggle = () => {
        dispatch(toggleAuthModal(!showModal));
        window.scrollTo(0, 0);
    }
    return (
        <div className='bg-primary text-white py-[60px] '>
            <div className='max-w-screen-xl mx-auto  p-4'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 pb-8 border-b-[1px]'>
                    <div className='col-span-1 md:col-span-2'>
                        <h2 className='text-3xl font-bold mb-8 text-center md:text-left'>Ongshak</h2>
                        <div className='flex justify-start space-x-4 items-center mb-4'>
                            <FaRegComment />
                            <p>Level 3, Plot 19, Road 27, Block J Banani, Dhaka-1213</p>
                        </div>
                        <div className='flex justify-start space-x-4 items-center mb-4'>
                            <FaEnvelope />
                            <p>hello@ongshak.com</p>
                        </div>
                        <div className='flex justify-start space-x-4 items-center mb-4'>
                            <FaPhone />
                            <p>+8801984-440881</p>
                        </div>
                    </div>
                    <div className='text-left'>
                        <h2 className='text-xl font-bold mb-8'>Quick Links</h2>
                        <p className='mb-4 cursor-pointer'>Faq</p>
                        <Link to="/blogs">
                            <p className='mb-4 cursor-pointer'>Blogs</p>
                        </Link>
                        <p className='mb-4 cursor-pointer' onClick={handleToggle}>Sign up</p>
                        <Link to="/terms-condition">
                            <p className='mb-4 cursor-pointer'>Terms & Conditions</p>
                        </Link>
                    </div>
                    <div className='text-left'>
                        <h2 className='text-xl font-bold mb-8'>Programs</h2>
                        <Link to='/all-programs/programDetails/IVHM85pn0ezkKkLP0FdZ'>
                            <p className='mb-4'>IoT</p>
                        </Link>
                        <Link to='/all-programs/programDetails/HWwjcc3rMACHbL9rkkDj'>
                            <p className='mb-4'>Mern</p>
                        </Link>
                        <Link to='/all-programs/programDetails/eBtBjf4jCGF9Rn16qNMK'>
                            <p className='mb-4'>React</p>
                        </Link>
                        <Link to='/all-programs/programDetails/Hap6wX5czoVwJYDYx7jF'>
                            <p className='mb-4'>Django</p>
                        </Link>
                    </div>
                    <div >
                        <h2 className='text-left text-xl font-bold mb-8'>Newsletter</h2>
                        <div className='flex justify-start'>
                            <input type="email" placeholder="ex.jogndoe@gmail.com" className='text-black outline-none p-2 rounded-l-md' value={email} onChange={(e) => setEmail(e.target.value)(e)} />
                            <div className='border border-1 flex justify-center items-center p-2 rounded-r-md cursor-pointer' onClick={handleNewsLetter}>
                                <FaAngleRight />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center justify-between space-y-4 pt-4'>
                    <p className='md:font-bold'>Copyright © 2023 Ongshak. All Rights Reserved</p>
                    <div className='flex space-x-4'>
                        <a href="https://www.facebook.com/ongshak/" target='_blank'>
                            <FaFacebook className='w-8 h-8' />
                        </a>
                        <a href="https://www.linkedin.com/company/ongshak/mycompany/" target='_blank'>
                            <FaLinkedin className='w-8 h-8' />
                        </a>
                        <a href="https://www.youtube.com/channel/UCORwe1FE3dPpRHQ73n6Qtcw" target='_blank'>
                            <FaYoutube className='w-8 h-8' />
                        </a>
                    </div>
                </div>
            </div>
            <Toast />
        </div>
    )
}
