import React from 'react'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import AllBlogs from './Components/AllBlogs'

export default function Blogs() {
  return (
    <div>
        <Navbar />
        <AllBlogs />
        <Footer />
    </div>
  )
}
