import React from 'react'
import Modal from '../../../../Shared/Modal'
import profile from "../../../../assets/images/profile.png"
import { useCollection } from '../../../../hooks/useCollection';
import { useSelector } from 'react-redux';
import { FaCheck, FaCross, FaLock, FaTimes } from 'react-icons/fa';

export default function ShowCreator({ creatorDetail }) {
    const { document: enrolledPrograms } = useCollection("enrolled_course");

    const studentenrolledPrograms = enrolledPrograms?.filter((program) => program?.studentEmail === creatorDetail?.basicInfo?.email);
    const percentageMeasure = (program) => {
        const watchTutorial = program?.curriculum?.filter(tutorial => tutorial.watch);

        const percentage = (watchTutorial?.length / program?.curriculum?.length) * 100;

        return parseInt(percentage)
    }
    return (
        <>
            <div className='max-w-5xl w-full min-h-min mx-auto flex justify-center items-center z-20 absolute top-[100px] left-1/2  -translate-x-1/2 -translate-y-0'>


                <div className='relative bg-[#E7FFF9] p-16 drop-shadow-md rounded-md  min-w-[900px]'>
                    <img src={creatorDetail?.basicInfo?.image ? creatorDetail?.basicInfo?.image : profile} alt={creatorDetail?.basicInfo?.firstName + " " + creatorDetail?.basicInfo?.lastName} className='w-16 h-16 rounded-full absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2' />
                    <div className='text-center'>
                        <p><span className='font-bold'>Role:</span> {creatorDetail?.role}</p>
                        <p><span className='font-bold'>Role Defined:</span> {creatorDetail?.roleDefined}</p>
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex flex-col space-y-4'>
                            <p><span className='font-bold'>Name:</span> {creatorDetail?.basicInfo?.firstName + " " + creatorDetail?.basicInfo?.lastName}</p>
                            <p><span className='font-bold'>Email:</span> {creatorDetail?.basicInfo?.email}</p>
                            <p><span className='font-bold'>Phone:</span> {creatorDetail?.basicInfo?.phoneNumber}</p>
                            <p><span className='font-bold'>Gender:</span> {creatorDetail?.basicInfo?.gender}</p>
                            <p><span className='font-bold'>Date of Birth:</span> {creatorDetail?.basicInfo?.dateOfBirth}</p>
                        </div>
                        <div className='flex flex-col space-y-4'>
                            <p><span className='font-bold'>Github:</span> {creatorDetail?.additionalInfo?.github}</p>
                            <p><span className='font-bold'>Linkedin:</span> {creatorDetail?.additionalInfo?.linkedin}</p>
                            <p><span className='font-bold'>Portfolio:</span> {creatorDetail?.additionalInfo?.portfolio}</p>
                            <p><span className='font-bold'>Cv/Resume:</span> {creatorDetail?.additionalInfo?.portfolio}</p>
                        </div>
                    </div>
                    {
                        creatorDetail?.skills?.length ? <>
                            <h2 className='text-2xl py-4 font-bold'>Skills:</h2>
                            <div className='min-w-[900px] bg-white p-8 rounded-md flex  flex-wrap items-center gap-4'>
                                {
                                    creatorDetail?.skills?.map(skill => (
                                        <div className='bg-admin_primary inline-block py-2 px-4 rounded-md text-white'>
                                            {skill}
                                        </div>
                                    ))
                                }
                            </div>
                        </> : null
                    }


                    {
                        creatorDetail?.experiences?.length ? <>
                            <h2 className='text-2xl py-4 font-bold'>Experience:</h2>
                            <div className='grid grid-cols-2 gap-6 mb-4'>
                                {
                                    creatorDetail?.experiences?.map(experience => (
                                        <div className='bg-white p-6 rounded-md'>
                                            <div className='flex justify-between items-center'>
                                                <h2 className='font-bold text-2xl'>{experience?.companyName}</h2>
                                                <div className='inline-block bg-admin_primary text-white px-4 py-2 rounded-full'>{experience?.jobType}</div>
                                            </div>
                                            <p className='text-gray-500'>{experience?.designation}</p>
                                            <p className='text-gray-500'>{experience?.startDate} - {experience?.endDate ? experience?.endDate : "present"}</p>
                                        </div>
                                    ))
                                }
                            </div></> : null
                    }

                    {
                        creatorDetail?.assessments[0]?.selectedAssessment?.category ? <div className='py-6'>
                            <h1 className='font-bold text-xl'>Assessment Details:</h1>
                            <div className='bg-white p-4'>
                                <div className='font-bold text-xl flex items-center justify-between border-b-2 border-gray-500 py-2'>
                                    <p><span className=''>category:</span> {creatorDetail?.assessments[0]?.selectedAssessment?.category}</p>
                                </div>
                                <div>
                                    <h2 className='font-bold'>Answers:</h2>
                                    <div>
                                        {
                                            creatorDetail?.assessments[0]?.answers?.map((answer, index) => (

                                                answer && <p><span className='font-bold'>{index + 1}</span>: {answer}</p>


                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }

                    {
                        studentenrolledPrograms?.length ? <div className='py-6'>
                            <h2 className='font-bold text-xl'>Enrolled courses:</h2>
                            {
                                studentenrolledPrograms?.map(program => (
                                    <div className='p-4 my-4 bg-white '>
                                        <div className='font-bold text-xl flex items-center justify-between border-b-2 border-gray-500 py-2'>
                                            <p><span className=''>Course Name:</span> {program?.title}</p>
                                            <p>{percentageMeasure(program)} %</p>
                                        </div>
                                        <div>
                                            {
                                                program?.curriculum?.filter(tutorial => tutorial?.asssessment !== "").map(tutorial => (
                                                    <div className='my-4'>
                                                        <p className='flex gap-4 items-center justify-between'>
                                                            <span>

                                                                <span className='font-bold'>Question: </span> {tutorial?.asssessment}
                                                            </span>
                                                            <span>{tutorial?.watch ? <FaCheck /> : <FaLock />}</span></p>
                                                        <p><span className='font-bold'>Answer: </span>{tutorial?.answer}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div> : null
                    }



                </div>
            </div>
        </>
    )
}
