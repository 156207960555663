import React from 'react';
import Footer from '../../Shared/Footer';
import Navbar from '../../Shared/Navbar';
import ProgramHeading from './Components/ProgramHeading';
import ProgramOverview from './Components/ProgramOverview';
import { useParams } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import { useEffect } from 'react';
import { useState } from 'react';

export default function ProgramDetails() {

  const { document } = useCollection("all-programs");
  const { id } = useParams();
  let [program, setProgram] = useState({});

  useEffect(() => {
    let filteredProgram = document?.filter(individualProgram => individualProgram?.id === id)[0];
    setProgram(filteredProgram);
  }, [document, id])

  return (
    <div>
      <Navbar />
      <ProgramHeading program={program} programId={id} />
      <ProgramOverview program={program} allPrograms={document} />
      <Footer />
    </div>
  )
}
