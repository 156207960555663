import React, { useState } from 'react'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import CourseDetail from './Components/CourseDetail'
import { useCollection } from '../../hooks/useCollection';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function CourseDetails() {
    const { document } = useCollection("enrolled_course");
    const { id } = useParams();
    let [program, setProgram] = useState({});

    useEffect(() => {
        let filteredProgram = document?.filter(individualProgram => individualProgram?.id === id)[0];
        setProgram(filteredProgram);
    }, [document, id]);

    return (
        <div>
            <Navbar />
            <CourseDetail program={program} />
            <Footer />
        </div>
    )
}
