export const curriculum = [
    {
        "title": "Get started with Arduino",
        "videoUrl": "https://www.youtube.com/watch?v=nL34zDTPkcs | https://www.youtube.com/watch?v=zJ-LqeX_fLU",
        "asssessment": "Write a code to interface a button and an LED, use debouncing for the button and turn the LED on/off based on button presses.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn the basics of electronics - Voltage, current, resistance, ohm’s law",
        "videoUrl": "https://www.youtube.com/watch?v=uXr4lXYjXuU",
        "asssessment": "Simulate a circuit with 10ohm resistor and 1V supply.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn to design basic PCBs in Fritzing",
        "videoUrl": "https://www.youtube.com/watch?v=35YuILUlfGs",
        "asssessment": "Design a the circuit in module 1 as a PCB.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn how to use ESP microcontrollers with the Arduino IDE",
        "videoUrl": "https://www.youtube.com/watch?v=xPlN_Tk3VLQ",
        "asssessment": "Build a simple HTTP web server with ESP microcontroller, show the time since ESP was turned on",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Get basic knowledge of JSON files and how to work with them on Arduino",
        "videoUrl": "https://randomnerdtutorials.com/decoding-and-encoding-json-with-arduino-or-esp8266/ | https://www.youtube.com/watch?v=NYP_CxdYzLo",
        "asssessment": "Fetch a JSON file from the internet, simplify it and print it on the Serial Monitor.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn how to push data to Google firestore with ESPs:",
        "videoUrl": "https://randomnerdtutorials.com/esp32-firebase-realtime-database/ https://www.youtube.com/watch?v=jpYPIh8C_sM",
        "asssessment": "Push a few random WiFi SSID and password to your Google firestore database",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn how to fetch time data on an ESP",
        "videoUrl": "https://www.youtube.com/watch?v=r2UAmBLBBRM",
        "asssessment": "Show the current time on a HTTP webserver with an ESP, turn an LED on if the time is PM, keep it off when AM.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "title": "Learn how to use some basic sensors:",
        "description": "Temperature and humidity sensor:",
        "videoUrl": "https://www.youtube.com/watch?v=tDdL5urWvH4",
        "asssessment": "Display some sensor data on an HTTP webserver.",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },
    {
        "description": "Some air quality monitor sensors:",
        "videoUrl": "https://www.youtube.com/watch?v=GF27dp4RA0g",
        "description": "",
        "outcome": "",
        "watch": false,
        "answer": ""
    },


















    {
        "Sheet1": [
            {
                "title": "Learn how to fetch time data on an ESP",
                "videoUrl": "https://www.youtube.com/watch?v=r2UAmBLBBRM",
                "asssessment": "Show the current time on a HTTP webserver with an ESP, turn an LED on if the time is PM, keep it off when AM.",
                "description": "",
                "outcome": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "What are NoSQL databases",
                "description ": "NoSQL (Not Only SQL) databases are a type of database management system that differs from traditional relational databases in terms of data storage and processing. While relational databases store data in tables with defined relationships between them, NoSQL databases store data in a variety of formats such as key-value pairs, document-oriented, column-family or graph databases. NoSQL databases are often used for storing large volumes of unstructured or semi-structured data, such as social media posts, sensor data, or logs.",
                "videoUrl": "https://youtu.be/qEhNHOEa5sE",
                "outcome": "NoSQL databases are designed to be horizontally scalable, meaning that they can handle large volumes of data and traffic by adding more servers to a cluster.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "What is MongoDB",
                "description ": "MongoDB is a popular open-source document-oriented NoSQL database that is designed for high performance, scalability, and flexibility. It uses a JSON-like document model to store data, allowing for flexible and dynamic schemas. MongoDB is commonly used in web applications, mobile apps, and other modern applications that require fast and efficient processing of large volumes of unstructured or semi-structured data.",
                "videoUrl": "https://youtu.be/ExcRbA7fy_A",
                "outcome": "MongoDB is designed to be highly scalable, allowing users to add or remove servers to a cluster as needed to accommodate growing data volumes and traffic.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "MongoDB data model",
                "description ": "MongoDB uses a document-based data model to store data, where each document represents a single record or data object. Documents are stored in collections, which are similar to tables in relational databases but with more flexibility in terms of data structure.",
                "videoUrl": "https://youtu.be/3GHZd0zv170",
                "outcome": "MongoDB's document-based data model allows for flexible and dynamic data structures, making it easy to adapt to changing data requirements. MongoDB's data model is designed to be highly scalable, allowing users to easily add or remove data as needed.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "MongoDB features and benefits",
                "description ": "MongoDB is a NoSQL database that offers a wide range of features and benefits for modern applications. ",
                "videoUrl": "https://www.mongodb.com/advantages-of-mongodb",
                "outcome": "MongoDB's flexible document-based data model allows for easy and dynamic data modeling, making it simple to adapt to changing data requirements. This leads to improved development agility and faster time-to-market for new applications.MongoDB is designed to be highly scalable, allowing users to easily add or remove servers to accommodate growing data volumes and traffic. This leads to improved application performance and reliability as traffic and data volumes increase.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Creating and deleting databases and collections",
                "description ": " creating and deleting databases and collections in MongoDB can lead to improved data organization, flexibility, scalability, data management, performance, and data security. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications.",
                "videoUrl": "https://youtu.be/ojKJqNQYaOI",
                "outcome": "Creating databases and collections in MongoDB allows for efficient data organization and retrieval, making it easy to store and manage data based on specific requirements. This leads to improved data processing and analysis capabilities.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Inserting, updating, and deleting documents",
                "description ": " inserting, updating, and deleting documents in MongoDB can lead to improved data storage, real-time updates, data management, performance, and data security. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications",
                "videoUrl": "https://youtu.be/g3Z0Av9yRSs https://youtu.be/hq7gGo-1CgM https://youtu.be/s8YG0GvQInY",
                "outcome": "Inserting documents in MongoDB allows for efficient data storage, making it easy to add or update data based on specific requirements. This leads to improved data processing and analysis capabilities.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Querying documents with filters and projections",
                "description ": "querying documents with filters and projections in MongoDB can lead to improved data processing, real-time data access, data insights, performance, resource management, and data security. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications.",
                "videoUrl": "https://youtu.be/zFzR2mCHhTk",
                "outcome": "Querying documents with filters and projections allows for efficient data processing, making it easy to retrieve specific data based on specific criteria. This leads to improved data processing and analysis capabilities.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "Indexing and querying data for performance optimization",
                "description ": "indexing and querying data for performance optimization in MongoDB can lead to improved query performance, reduced disk I/O operations, efficient data processing, real-time data access, improved scalability, and improved resource utilization. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications.",
                "videoUrl": "https://youtu.be/zFzR2mCHhTk",
                "outcome": " Indexing data in MongoDB allows for faster query response times, making it easy to retrieve data based on specific criteria. This leads to improved data processing and analysis capabilities.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "complex queries and data transformations",
                "description ": "working with complex queries and data transformations in MongoDB can lead to advanced data processing, custom data aggregation, improved data visualization, improved system performance, improved scalability, and custom data transformations. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications.",
                "videoUrl": "https://youtu.be/NRKGZdJTf48",
                "outcome": "Complex queries and data transformations allow for advanced data processing, such as aggregations, data joins, and data transformations. This leads to improved data processing and analysis capabilities.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "Data modeling and schema design",
                "description ": "proper data modeling and schema design in MongoDB can lead to improved query performance, improved data integrity, improved data scalability, reduced data redundancy, improved data management, and improved application development. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications.",
                "videoUrl": "https://youtu.be/QAqK-R9HUhcc",
                "outcome": "Proper data modeling and schema design can lead to improved query performance, as data is organized and indexed in a way that facilitates efficient retrieval of data based on specific criteria.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "Performing CRUD operations with Node js",
                "description ": "performing CRUD operations with Node.js and MongoDB can lead to efficient data processing, improved application performance, simplified application development, improved data security, improved data management, and custom data transformations. These outcomes can ultimately lead to improved business outcomes and user experiences for modern web applications.",
                "videoUrl": "https://youtu.be/g3Z0Av9yRSs https://youtu.be/hq7gGo-1CgM https://youtu.be/s8YG0GvQInY",
                "outcome": "Performing CRUD operations with Node.js and MongoDB enables efficient data processing, as data can be easily retrieved, updated, and deleted based on specific criteria.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": " Back up and restoring MongoDB databases",
                "description ": "proper backup and restoration of MongoDB databases can lead to improved data preservation, improved system stability, improved disaster recovery capabilities, improved system scalability, improved data integrity, and improved compliance. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications that rely on MongoDB for data storage and management.",
                "videoUrl": "https://youtu.be/CHNB38MAvKY",
                "outcome": "Proper backup and restoration of MongoDB databases can help ensure that data is properly preserved and can be easily restored in the event of a system failure or data loss.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "authentication and access control",
                "description ": "proper authentication and access control in MongoDB can lead to improved system security, improved data protection, improved regulatory compliance, improved user management, improved auditing and monitoring capabilities, and improved system availability. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications that rely on MongoDB for data storage and management.",
                "videoUrl": "https://youtu.be/q68a5O2Yyy0",
                "outcome": "Proper authentication and access control in MongoDB can help protect the system against unauthorized access, data breaches, and other security threats.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "optimizing MongoDB performance",
                "description ": "optimizing MongoDB performance can lead to improved application responsiveness, improved scalability, reduced resource usage, improved query performance, improved database maintenance, and improved cost-effectiveness. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications that rely on MongoDB for data storage and management.",
                "videoUrl": "https://www.mongodb.com/docs/manual/administration/analyzing-mongodb-performance/",
                "outcome": "Optimizing MongoDB performance can help improve application responsiveness, as queries and operations can be executed more quickly and efficiently, leading to faster response times and better user experiences.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "scalable and distributed systems with MongoDB ",
                "description ": "scalable and distributed MongoDB systems can lead to improved performance, availability, scalability, fault tolerance, data distribution, and cost-effectiveness. These outcomes can ultimately lead to improved business outcomes and user experiences for modern applications that rely on MongoDB for data storage and management.",
                "videoUrl": "https://www.mongodb.com/scale/managing-distributed-data https://www.geeksforgeeks.org/what-is-scalable-system-in-distributed-system/",
                "outcome": "Scalable and distributed MongoDB systems can improve application performance by distributing data across multiple nodes or clusters, reducing the workload on any one node and enabling more efficient processing of queries and other operations.",
                "asssessment": "",
                "watch": false,
                "answer": ""

            },
            {
                "title": "Creating routes for different HTTP methods (GET, POST, PUT, DELETE)",
                "description ": "creating routes for different HTTP methods in Express.js can lead to improved organization, security, flexibility, error handling, testability, and usability. These outcomes can ultimately lead to improved business outcomes and user experiences for applications that rely on RESTful APIs built with Express.js.",
                "videoUrl": "https://youtu.be/0Hu27PoloYw",
                "outcome": "Creating routes for different HTTP methods helps organize your API's endpoints by mapping specific routes to specific actions, making it easier to navigate and manage.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Handling parameters and query strings in routes",
                "description ": "handling parameters and query strings in routes with Express.js can lead to improved flexibility, customization, data retrieval, error handling, testability, and user experience. These outcomes can ultimately lead to improved business outcomes and user experiences for applications that rely on APIs built with Express.js.",
                "videoUrl": "https://youtu.be/dqCULpd7OWY",
                "outcome": "Handling parameters and query strings in routes allows developers to build APIs that are more flexible and can handle a wider range of requests.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Creating route handlers to handle requests",
                "description ": "creating route handlers to handle requests with Express.js can lead to improved organization, functionality, readability, error handling, testability, and user experience. These outcomes can ultimately lead to improved business outcomes and user experiences for applications that rely on APIs built with Express.js.",
                "videoUrl": "https://www.digitalocean.com/community/tutorials/nodejs-express-routing",
                "outcome": "Creating route handlers allows developers to organize their code into smaller, more manageable pieces, making it easier to build, maintain, and scale APIs.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Using template engines",
                "description ": " using template engines with Express.js can lead to improved flexibility, customization, code reusability, performance, consistency, and SEO. These outcomes can ultimately lead to improved business outcomes and user experiences for web applications built with Express.js.",
                "videoUrl": "https://youtu.be/AhSIde-hTJg",
                "outcome": "Using a template engine allows developers to dynamically generate HTML pages based on data and logic, improving the flexibility of the application.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Parsing form data ",
                "description ": "parsing form data with Express.js can lead to improved user experience, data validation, data handling, error handling, debugging, and performance. These outcomes can ultimately lead to improved business outcomes and user experiences for web applications built with Express.js.",
                "videoUrl": "https://www.geeksforgeeks.org/parsing-form-data-in-express-app-manually-without-body-parser/ https://www.tutorialspoint.com/expressjs/expressjs_form_data.htm",
                "outcome": " By parsing form data, developers can create a more seamless and user-friendly experience for users, improving customer satisfaction and engagement.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Validating form data with express-validator",
                "description ": "using express-validator to validate form data with Express.js can lead to improved data accuracy, data security, user experience, error handling, code maintainability, and developer productivity. These outcomes can ultimately lead to improved business outcomes and user experiences for web applications built with Express.js.",
                "videoUrl": "https://youtu.be/X1FAYG4eoug",
                "outcome": "By using express-validator to validate form data, developers can ensure that the data being processed and stored in the application is accurate and reliable.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Connecting to a database (such as MongoDB) using the mongoose library",
                "description ": "connecting to a database using Mongoose can lead to improved data management, data modeling, error handling, code maintainability, developer productivity, and scalability. These outcomes can ultimately lead to improved business outcomes and user experiences for web applications built with Node.js and Express.js.",
                "videoUrl": "https://mongoosejs.com/docs/4.x/docs/connections.html",
                "outcome": "By connecting to a database using Mongoose, developers can easily manage and interact with data, reducing the need for custom database queries and improving productivity.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Performing CRUD operations on the database",
                "description ": "performing CRUD operations on the database is a critical task for building web applications. By performing these operations effectively, developers can improve data management, user experience, application performance, error handling, data security, and code maintainability, leading to improved business outcomes and user experiences.",
                "videoUrl": "https://youtu.be/W1Kttu53qTg",
                "outcome": "By performing CRUD operations on the database, developers can easily manage and interact with data, allowing for efficient storage and retrieval of data",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Authentication and Authorization",
                "description ": "Authentication and authorization ensure that only authorized users have access to sensitive data, reducing the risk of data breaches and unauthorized access.",
                "videoUrl": "https://youtu.be/bgk1mI2pak4",
                "outcome": "Authentication and authorization ensure that only authorized users have access to sensitive data, reducing the risk of data breaches and unauthorized access.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Handling errors using middleware",
                "description ": "handling errors using middleware is a critical technique for building stable, secure, and user-friendly web applications. By using error handling middleware effectively, developers can improve application stability, user experience, code maintainability, performance, security, and debugging, leading to improved business outcomes and user experiences.",
                "videoUrl": "https://youtu.be/lY6icfhap2o",
                "outcome": " By using error handling middleware, developers can ensure that the application remains stable even in the face of unexpected errors or exceptions.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Learn React JSX",
                "description ": "It is called JSX, and it is a syntax extension to JavaScript. ",
                "videoUrl": "https://www.youtube.com/watch?v=3GMnjtOKpOw",
                "outcome": "JSX is a powerful tool for creating dynamic user interfaces in web applications. With JSX, developers can easily create UI components that can update based on user interactions or changes in data."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Learn React Fragment ",
                "description ": "a fragment is a way to group a list of children elements without adding extra nodes to the DOM. It is a special component that does not render anything on its own, but instead can be used to wrap multiple elements and return them as a single child.",
                "videoUrl": "https://www.youtube.com/watch?v=bHdh1T0-US4",
                "outcome": "Fragments can help improve code organization by allowing developers to group multiple elements together without having to create an unnecessary wrapper element. This can make code more readable and easier to maintain."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "React Component",
                "description ": "a component is a reusable building block for creating user interfaces. Components are the building blocks of React applications, and they can be thought of as small pieces of code that encapsulate a particular functionality or piece of the user interface.",
                "videoUrl": "https://youtu.be/Y2hgEGPzTZY",
                "outcome": "Components provide a way to break down a complex user interface into smaller, more manageable parts. This can improve code organization and make it easier to understand and maintain."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Virtual Dom",
                "description ": "The Virtual DOM is a concept in React that represents a lightweight copy of the actual DOM (Document Object Model) that a web browser uses to display a web page. It is an abstract data structure that mirrors the structure of the actual DOM, but is much faster to update and manipulate.",
                "videoUrl": "https://youtu.be/Iw2BLUjQo1E",
                "outcome": "Understanding the Virtual DOM is key to understanding how React updates the UI efficiently and provides a seamless user experience."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Component Tree",
                "description ": "the component tree represents the hierarchical structure of components in a React application. At the top of the tree is the root component, which contains all the other components in the application as its children. Each component in the tree can have one or more child components, which can themselves have child components, creating a nested structure",
                "videoUrl": "https://youtu.be/ehGPlQzn8Rc\r",
                "outcome": "Understanding the component tree is critical to understanding the structure of a React application, as it shows how components are nested and organized."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Props ",
                "description ": "rops (short for \"properties\") are a way of passing data from a parent component to its child component. Props are a key feature of the React component architecture and are used to control the behavior and appearance of components.",
                "videoUrl": "https://youtu.be/XyIXMQ9SZmI",
                "outcome": "Props are a key feature of the React component architecture, and understanding how they work is essential to building modular and reusable components."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Props drilling",
                "description ": "Prop drilling is a term used to describe the process of passing props from a parent component down to its child component, and then to that child component's child component, and so on. Prop drilling can occur when there are several levels of nested components and data needs to be passed down to deeply nested components.",
                "videoUrl": "https://youtu.be/XyIXMQ9SZmI",
                "outcome": "Prop drilling can help to maintain a clear data flow between components, making it easier to understand how data is being used and modified throughout the application."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Props Destructure",
                "description ": "destructuring props is a technique that allows developers to extract specific values from an object or array passed as a prop to a component. This can make it easier to work with props, as it avoids the need to use long dot notation to access specific values.",
                "videoUrl": "https://youtu.be/5_PdMS9CLLI",
                "outcome": "Destructuring props can make code more readable by reducing the amount of dot notation needed to access specific values."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "State management",
                "description ": "State management in React refers to the process of managing the state of a component, which represents the current data and behavior of the component. In React, state is typically managed by the component itself, and can be updated using the setState method.",
                "videoUrl": "https://beta.reactjs.org/learn/managing-state",
                "outcome": "By managing component state effectively, developers can create more responsive and dynamic user interfaces, which can improve the overall user experience."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "State uplifting",
                "description ": "State lifting up is a technique used in React to manage state in a parent component, which can then be passed down to child components as props. State lifting up is typically used when multiple child components need to share the same state data, or when a parent component needs to manage the state of its child components.",
                "videoUrl": "https://youtu.be/ahKsy1FS45k",
                "outcome": "By managing state in a parent component and passing it down as props, developers can create a clear data flow within the application, which can improve the readability and maintainability of the code."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Keys",
                "description ": "a \"key\" is a special attribute that is used to identify each element in an array of child components. Keys are used by React to optimize rendering and improve performance, by allowing it to identify which elements have changed, and which need to be updated or deleted.",
                "videoUrl": "https://youtu.be/E_nfeIaRYg4",
                "outcome": "By using keys, React can optimize rendering and avoid unnecessary updates, resulting in better performance and faster rendering times."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Render component",
                "description ": "the render method is a required method in a class component, which returns a React element that represents the component's UI. The render method is called whenever the component needs to be re-rendered, such as when its state or props change.",
                "videoUrl": "https://youtu.be/VPtL6dU0YXc",
                "outcome": "The render method allows developers to create dynamic UIs that can respond to changes in state or props, by rendering different elements based on the current state or props of the component."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Conditional Rendering",
                "description ": "Conditional rendering in React allows developers to show or hide parts of the UI based on certain conditions. This can be achieved using a variety of techniques, such as conditional statements, ternary operators, or logical operators.",
                "videoUrl": "https://youtu.be/7o5FPaVA9m0",
                "outcome": "Conditional rendering allows developers to create dynamic UIs that can respond to changes in state or props, by showing or hiding components based on the current state or props of the component."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Reusable Components",
                "description ": "In React, reusable components are components that can be used multiple times throughout an application, or even across different applications. Reusable components are an important part of creating scalable and maintainable applications in React, as they help to reduce code duplication and improve code reusability.",
                "videoUrl": "https://youtu.be/-YpnB-zlkPU",
                "outcome": "By creating reusable components, developers can reduce code duplication and improve the reusability of their codebase. This can help to save development time and effort, and make it easier to maintain and update the application over time."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Style in React",
                "description ": "Styling in React can be done using a variety of techniques, including inline styles, CSS modules, CSS-in-JS libraries, and third-party styling libraries like Bootstrap or Material UI. ",
                "videoUrl": "https://youtu.be/NbTrGcz4DW8",
                "outcome": "the appropriate styling techniques for the specific needs of the application, developers can create more maintainable, performant, and user-friendly applications.",
                "__EMPTY": "Create a simple to-do list application that allows users to add, edit, and delete tasks. The app should display a list of tasks, and users should be able to mark tasks as completed."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Control vs Uncontrolled Component",
                "description ": "Controlled and uncontrolled components are two approaches to managing form input in React.\n\nControlled components are those where the value of the form input is controlled by React through the component's props. This means that every time the value of the input changes, the onChange event is triggered, and the state of the component is updated with the new value. This way, the component state always reflects the current value of the input field.\n\nUncontrolled components, on the other hand, do not use React state to manage the value of the input field. Instead, they rely on the value of the DOM element, which is accessed using a ref. This way, the component state does not need to be updated every time the value of the input field changes.",
                "videoUrl": "https://youtu.be/ecY3QSxZZYY",
                "outcome": "the choice between controlled and uncontrolled components depends on the specific needs of the application. Controlled components can provide more control and validation, but can be more complex to implement, while uncontrolled components can be faster and simpler, but can be less accessible and harder to validate."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Error handling",
                "description ": "Error handling is an important part of any application, and React provides several ways to handle errors that can occur within components or during the application's lifecycle.",
                "videoUrl": "https://youtu.be/DTBta08fXGU",
                "outcome": "By handling errors gracefully and providing users with clear feedback, developers can improve the overall user experience of their application, reducing frustration and improving user retention."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Optimization",
                "description ": "Optimizing a React application involves finding ways to improve its performance and reduce its memory usage. There are several techniques and tools that can be used to optimize a React application, including code splitting, lazy loading, memoization, and using tools like React Profiler and Chrome DevTools to identify performance bottlenecks.",
                "videoUrl": "https://reactjs.org/docs/optimizing-performance.html",
                "outcome": "By optimizing a React application, developers can improve the overall user experience by reducing load times and making the application more responsive and smooth."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Code refactoring",
                "description ": "Code refactoring involves restructuring existing code to make it more efficient, maintainable, and easier to understand. There are several benefits to refactoring code in a React application, including improved performance, reduced technical debt, and increased maintainability.",
                "videoUrl": "https://levelup.gitconnected.com/refactoring-a-complex-react-component-5-best-practices-to-write-efficient-and-readable-components-b0d06f4f22b4",
                "outcome": "By refactoring code to remove unnecessary code, optimize algorithms, and reduce the number of requests made to the server, developers can significantly improve the performance of their React application."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Form Handling",
                "description ": "Form handling in a React application involves managing and validating user input through forms, such as login forms or registration forms. Proper form handling is crucial for creating user-friendly applications, and there are several techniques and tools that can be used to handle forms in a React application.",
                "videoUrl": "https://youtu.be/7Vo_VCcWupQ",
                "outcome": "By properly handling forms, developers can provide a better user experience by giving users clear feedback on their input and reducing the number of errors that they encounter."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Form Validity",
                "description ": "Form validity is an important aspect of form handling in a React application. It involves validating user input to ensure that it meets specific criteria or matches certain patterns before submitting it to the server. Proper form validity can help to improve the user experience, prevent errors and vulnerabilities, and increase the overall reliability of the application.",
                "videoUrl": "https://youtu.be/EYpdEYK25Dc",
                "outcome": "By providing clear feedback to users on invalid inputs, developers can create a better user experience and reduce the number of errors and frustrations that users encounter."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Asynchronous behavior handling",
                "description ": "Asynchronous behavior handling is an important aspect of building a React application. In a React application, asynchronous behavior can occur when making API calls, fetching data from a database, or performing any other task that involves waiting for a response from an external resource. Proper handling of asynchronous behavior can help to improve the user experience, prevent errors, and increase the overall reliability of the application.",
                "videoUrl": "https://www.positronx.io/how-to-handle-http-request-with-async-await-in-react/",
                "outcome": "By properly handling asynchronous behavior, developers can create a better user experience by providing feedback to users during long-running operations and reducing the time that users spend waiting for a response."
                ,
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useState hooks",
                "description ": "useState is a hook that allows developers to add state to functional components. By using state, developers can make components dynamic and interactive, as the state can change over time in response to user actions or other events.",
                "videoUrl": "https://youtu.be/O6P86uwfdR0 https://youtu.be/4pO-HcG2igk",
                "outcome": "By using useState, developers can add dynamic behavior to their components. For example, a button that changes color when clicked can be created by using state to change the color of the button in response to a click event.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useEffect",
                "description ": "is a hook that allows developers to handle side effects in functional components. Side effects are any operations that don't affect the component's rendering output, such as making an API call, manipulating the DOM, or setting up event listeners. ",
                "videoUrl": "https://youtu.be/0ZJgIjIuY7U https://youtu.be/gv9ugDJ1ynU",
                "outcome": "By using useEffect, developers can handle side effects in their components, making them more dynamic and responsive to user actions or other events.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "use reduces",
                "description ": "the useReducer hook allows developers to handle more complex state management scenarios in their functional components.",
                "videoUrl": "https://youtu.be/kK_Wqx3RnHk https://youtu.be/cVYp4u1m6iA",
                "outcome": "useReducer provides a more explicit and predictable way to handle state changes in complex components. It allows developers to manage state with more granular control and can simplify complex state management scenarios.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useContext",
                "description ": "the useContext hook allows developers to consume data and functions from a parent component without having to pass props down through every intermediate component.",
                "videoUrl": "https://youtu.be/5LrDIWkK_Bc https://youtu.be/CI7EYWmRDJE",
                "outcome": "By using useContext, developers can simplify their code by reducing the need to pass props down through multiple levels of components.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useRef",
                "description ": "the useRef hook allows developers to create a reference to a component or element in the DOM.",
                "videoUrl": "https://youtu.be/t2ypzz6gJm0 https://youtu.be/yCS2m01bQ6w",
                "outcome": "By using useRef, developers can manipulate the DOM more effectively since they have direct access to a component or element.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useMemo",
                "description ": "the useMemo hook allows developers to memoize the results of a function so that it is only recomputed when the inputs change.",
                "videoUrl": "https://youtu.be/THL1OPn72vo https://youtu.be/oR8gUi1LfWY",
                "outcome": "By using useMemo, developers can improve the performance of their application by avoiding unnecessary re-computations of functions that are expensive or time-consuming.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "useCallback",
                "description ": "the useCallback hook allows developers to memoize a function so that it is only created when its dependencies change.",
                "videoUrl": "https://youtu.be/_AyFP5s69N4 https://youtu.be/IL82CzlaCys",
                "outcome": "By using useCallback, developers can improve the performance of their application by avoiding unnecessary re-creation of functions that are expensive or time-consuming.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Custom hooks- (useFetch, useAdded etc)",
                "description ": "Custom hooks are reusable functions in React that allow developers to extract logic from components and share it across different components.",
                "videoUrl": "https://youtu.be/6ThXsUwLWvc",
                "outcome": "By extracting reusable logic into custom hooks, developers can easily share that logic across different components, reducing code duplication and improving code reusability.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Context API",
                "description ": "Context API is a way to share data between components in a React application, without having to pass props down through multiple levels of the component tree",
                "videoUrl": "https://youtu.be/sP7ANcTpJr8",
                "outcome": "Context API makes it easy to share data between components in a React application, without having to pass props down through multiple levels of the component tree.",
                "__EMPTY": "Create a quiz app that asks users a series of multiple-choice questions. Users should be able to choose an answer and see if they got the question right or wrong. At the end of the quiz, the app should display the user's score.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Core redux",
                "description ": "Redux is a state management library that provides a centralized store for managing the state of a React application",
                "videoUrl": "https://youtube.com/playlist?list=PL0Zuz27SZ-6M1J5I1w2-uZx36Qp6qhjKo",
                "outcome": "Redux is a powerful tool for improving the reusability, scalability, and maintainability of a React application. By separating state management from components and encapsulating it in a centralized store, developers can write more efficient and modular code that is easier to test, maintain, and debug.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "React-redux",
                "description ": "React-redux is a powerful tool for improving the reusability, scalability, and maintainability of a React application that uses Redux for state management. By providing a bridge between React components and the Redux store, React-redux simplifies the process of managing state in a React application, making it easier to write more efficient and modular code that is easier to test, maintain, and debug.",
                "videoUrl": "https://youtu.be/u3KlatzB7GM https://youtu.be/hI-VgEaCMyQ https://youtu.be/93CR_yURoII",
                "outcome": "React-redux simplifies the process of managing state in a React application by providing a bridge between React components and the Redux store",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Redux-toolkit",
                "description ": "Redux Toolkit is a library that provides a set of opinionated utilities and abstractions to simplify the process of using Redux in a React application",
                "videoUrl": "https://youtu.be/u3KlatzB7GM https://youtu.be/hI-VgEaCMyQ https://youtu.be/93CR_yURoII",
                "outcome": "Redux Toolkit simplifies the process of setting up a Redux store by providing a set of pre-configured tools and abstractions, reducing the amount of boilerplate code required.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Redux rtk Query",
                "description ": "Redux Toolkit Query (RTK Query) is a powerful library that simplifies the process of fetching and caching data in a React application using Redux.",
                "videoUrl": "https://youtu.be/HyZzCHgG3AY",
                "outcome": "RTK Query simplifies the process of fetching data by providing a set of abstractions and conventions that help developers define and execute API requests.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Difference between Context API and redux",
                "description ": "Context API is a simpler, more lightweight solution for managing state within a single React component tree, while Redux is a more powerful, flexible solution for managing state across multiple components and the entire application. The choice between them depends on the specific needs of the application and the complexity of the state management requirements.",
                "videoUrl": "https://www.scalablepath.com/react/context-api-vs-redux",
                "outcome": "Understanding when to use redux and when to use contenxt API",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "React routed dom",
                "description ": "React Router is a popular routing library for React that allows developers to create dynamic, client-side applications with multiple pages and URLs. It provides a declarative way to map URLs to components and manage the history stack, allowing users to navigate through different pages of an application without refreshing the page.",
                "videoUrl": "https://youtube.com/playlist?list=PL4cUxeGkcC9iVKmtNuCeIswnQ97in2GGf",
                "outcome": "The outcome of using React Router DOM is a more robust and user-friendly application that allows users to navigate through different pages and URLs without refreshing the page. It also provides a better user experience by allowing users to use the browser's back and forward buttons to navigate through the application.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Firebase",
                "description ": "Firebase Authentication is a service provided by Google's Firebase platform that allows developers to authenticate users using a variety of methods such as email and password, phone number, Google, Facebook, Twitter, and more. Firebase Authentication provides a secure and reliable way to authenticate users in your application, eliminating the need to build your own authentication system.",
                "videoUrl": "https://youtu.be/9bXhf_TELP4",
                "outcome": "Firebase Authentication provides a secure and reliable way to authenticate users in your application, helping to ensure that only authorized users can access your application's content and functionality. It also provides a variety of authentication methods and customization options, making it easy to integrate with your application and provide a seamless user experience.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Get",
                "description ": "In React, you can make HTTP GET requests to retrieve data from an API using various methods. One common method is by using the built-in fetch() function or third-party libraries like Axios",
                "videoUrl": "https://youtu.be/59z1_3-vTOk",
                "outcome": "By learning how to make HTTP GET requests in React, you can retrieve data from an API and use that data in your React application. This is a fundamental skill for building modern web applications that integrate with third-party APIs.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Post",
                "description ": "HTTP POST request is a type of HTTP request method used to send data to a server to create/update a resource. When it comes to React, we can use libraries such as Axios, Fetch, and the built-in Fetch API to send HTTP POST requests.",
                "videoUrl": "https://youtu.be/59z1_3-vTOk",
                "outcome": "With HTTP POST requests, we can create new resources such as new users, new blog posts, or new products in a shopping cart.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Patch/put",
                "description ": "HTTP requests PATCH and PUT are used to modify an existing resource on the server.",
                "videoUrl": "https://youtu.be/59z1_3-vTOkk",
                "outcome": "Successfully updating the resource on the server",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Delete",
                "description ": "HTTP DELETE request is used to delete a resource on the server. The outcomes of using the DELETE request in React applications depend on the specific use case and the server-side implementation.",
                "videoUrl": "https://youtu.be/59z1_3-vTOk",
                "outcome": "Successfully deleting the resource on the server",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Next js basic",
                "description ": "Next.js is a popular open-source React framework that enables the creation of server-side rendered (SSR) React applications. It allows developers to build web applications with both client-side and server-side rendering in a single codebase. Next.js is built on top of React and Node.js and provides a set of tools and features that make it easier to build scalable and performant web applications.",
                "videoUrl": "https://youtube.com/playlist?list=PL4cUxeGkcC9g9gP2onazU5-2M-AzA8eBw",
                "outcome": "Next.js provides server-side rendering and other performance optimizations out of the box, which can improve the overall speed and user experience of your web application.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            {
                "title": "Deployment",
                "description ": "Deployment is an essential part of the development process, and the outcome of deployment can vary depending on various factors such as the deployment method, the infrastructure, and the configuration. ",
                "videoUrl": "https://youtu.be/Q9n2mLqXFpU https://youtu.be/AOqY6disSVI",
                "outcome": "the outcome of deployment depends on the quality of the deployment strategy, the infrastructure, and the development team's expertise. A well-executed deployment can improve the application's performance, availability, and scalability, leading to better user satisfaction and increased business value.",
                "asssessment": "",
                "watch": false,
                "answer": ""
            },
            
        ],

    }

]
