import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import thumbnail from "../../../../assets/images/django.png"
import { isShowBar } from '../../../../features/CreatorsNav/CreatorsNavSlice'
import { useCollection } from '../../../../hooks/useCollection'
import AllPrograms from '../../../AllPrograms/AllPrograms'

export default function EnrolledCourses() {
    const showBar = useSelector(isShowBar);
    const { email, displayName } = useSelector(state => state.authDetail);

    const { document: enrolledPrograms } = useCollection("enrolled_course");


    const studentenrolledPrograms = enrolledPrograms?.filter((program) => program?.studentEmail === email);

    const percentageMeasure = (program) => {
        const watchTutorial = program?.curriculum?.filter(tutorial => tutorial.watch);

        const percentage = (watchTutorial?.length / program?.curriculum?.length) * 100;

        return parseInt(percentage)
    }

    return (
        <div className={`w-full min-h-[50vh] flex flex-col bg-white p-4 py-12  ${showBar ? "hidden md:flex" : ""}`}>
            <div className='max-w-7xl w-full mx-auto'>
                <h2 className='text-2xl pb-6'>Welcome Back <span className='text-primary font-bold'>{displayName}</span>, Ready For Your Next Lesson? </h2>

                <div className='w-full rounded-md overflow-hidden my-6 py-16'>
                    {/* {
                        studentenrolledPrograms?.length > 0 ? <table className="w-full table-auto rounded-md ">
                            <thead className='bg-primary text-white '>
                                <tr className="text-center text-dark font-bold">
                                    <th
                                        className="w-4/6 md:w-3/6 min-w-[200px] border-l border-transparent py-4 px-1 md:px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                    >
                                        Course Name
                                    </th>
                                    <th
                                        className="hidden md:flex justify-center  md:text-center md:w-1/6 min-w-[80px] py-4 px-1 md:px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                    >
                                        Progress
                                    </th>
                                    <th
                                        className="md:w-1/6 min-w-[80px] py-4 px-1 md:px-3 text-lg font-semibold lg:py-7 lg:px-2"
                                    >
                                        Difficulty level
                                    </th>
                                    <th
                                        className="w-2/6 md:w-1/6 min-w-[80px] border-r border-transparent py-4 px-1 md:px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    studentenrolledPrograms?.map(program => (
                                        <tr className='border-t border-[#ddd] text-center text-dark py-4'>
                                            <td
                                                className="py-2 px-1 md:px-2  text-base"
                                            >
                                                <p className='py-4'>{program?.title}</p>
                                            </td>
                                            <td
                                                className="py-2 px-1 md:px-2 text-base text-left"
                                            >
                                                {percentageMeasure(program)} %</td>
                                            <td
                                                className=" py-2 px-1 md:px-2 text-base"
                                            >
                                                {program?.difficultyLevel}
                                            </td>

                                            <td
                                                className="y-2 px-1 md:px-2 text-base"
                                            >
                                                <Link to={`/dashboard/courses/${program?.id}`}>
                                                    <button className='bg-primary text-white px-4 py-2 rounded-md'>Continue</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table> : ""
                    } */}

                    {
                        studentenrolledPrograms?.length ? <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'>
                            {
                                studentenrolledPrograms?.map(program => (
                                    <div className='grid gap-2 grid-cols-1 md:grid-cols-2 bg-secondary rounded-md overflow-hidden shadow-custom'>
                                        <div>
                                            <img src={program?.image} alt="Tumbnail" className='h-full w-full object-contain p-2' />
                                        </div>
                                        <div className='px-4 py-8'>
                                            <h2 className='text-xl font-bold mb-4'>{program?.title}</h2>
                                            <div className='flex gap-4 items-center mb-4'>
                                                <div className='w-full bg-white rounded-full overflow-hidden'>
                                                    <div className='bg-primary p-2 w-[72%]' style={{ width: `${percentageMeasure(program)}%` }}>
                                                    </div>
                                                </div>
                                                <p className='font-bold'>{percentageMeasure(program)}%</p>
                                            </div>
                                            <Link to={`/dashboard/courses/${program?.id}`}>
                                                <button className="bg-primary text-white font-bold rounded-md px-4 py-2">{percentageMeasure(program) >= 100 ? "Course Completed" : "Continue Course"}</button>
                                            </Link>
                                        </div>
                                    </div>
                                ))

                            }
                        </div> : null
                    }


                </div>
            </div>
        </div>
    )
}
