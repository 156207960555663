import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAddBlog from './Components/AdminAddBlog'

export default function AdminAddBlogs() {
    return (
        <div className='flex bg-white'>
            <SuperAdminSidebar />
            <AdminAddBlog />
        </div>
    )
}
