import React from 'react'
import CreatorsProfileSidebar from '../../../Shared/CreatorsProfileSidebar'
import Footer from '../../../Shared/Footer'
import Navbar from '../../../Shared/Navbar'
import CreatorSkill from './Components/CreatorSkill'

export default function Skills() {
    return (
        <div>
            <Navbar />
            <div className='max-w-screen-xl mx-auto p-4'>
                <div className='flex gap-4 flex-col md:flex-row items-start'>
                    <CreatorsProfileSidebar />
                    <CreatorSkill />
                </div>
            </div>
            <Footer />
        </div>
    )
}
