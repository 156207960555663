import React from 'react'
import AllPrograms from '../../../AllPrograms/AllPrograms'
import { useSelector } from 'react-redux';
import { isShowBar } from '../../../../features/CreatorsNav/CreatorsNavSlice';

export default function AllDashboardCourse() {
    const showBar = useSelector(isShowBar);
    return (
        <div className={`w-full min-h-[50vh] flex flex-col bg-white p-4 py-12  ${showBar ? "hidden md:flex" : ""}`}>
            <AllPrograms />
        </div>
    )
}
