import React, { useState } from 'react'
import ContactForm from '../../Shared/ContactForm'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import Achieve from './Components/Achieve'
import Approach from './Components/Approach'
import Hero from './Components/Hero'
import Programs from './Components/Programs'
import Testimonail from './Components/Testimonail'

export default function Home() {
  return (
    <div>
      <Navbar  />
      <Hero />
      <Achieve />
      <Programs />
      <Approach />
      <Testimonail />
      <Footer />
    </div>
  )
}
