import React from 'react'
import { useState } from 'react';
import RichTextEditor from './RichTextEditor';
import Toast from '../../../../Shared/UI/Toast';
import { useFirestore } from '../../../../hooks/useFirestore';
import { toast } from 'react-toastify';
import { projectStorage } from '../../../../firebase.config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { curriculum } from '../data';
import { assessment } from '../data';

export default function AdminAddProgram() {
    const inititalProgram = {
        title: "",
        description: "",
        image: "",
        difficultyLevel: "",
        enrolledStudent: [],
        language: "",
        outcomes: [],
        categories: [],
        requirements: ""
    }
    const [program, setProgram] = useState({ ...inititalProgram });
    const [outCome, setOutCome] = useState("");
    const [category, setCategory] = useState("");
    const [programContent, setProgramContent] = useState("");

    const [fileProgress, setFileProgress] = useState("");

    const { addDocument, response } = useFirestore("all-programs");


    useEffect(() => {
        if (response?.success) {
            toast.success("Successfully Program added.");
            setProgram({ ...inititalProgram });
            setProgramContent("");
        }
        if (response?.error) {
            toast.success("Could not add Program")
        }

    }, [response])

    const handleOutCome = (e) => {
        setProgram({ ...program, outcomes: [...program.outcomes, outCome] });
        setOutCome("");
    }

    const handleCategory = (e) => {
        if (program?.categories?.includes(category)) {
            toast.error("already added this skill into your skill set");
            return
        }
        if (category === "") {
            return
        }

        setProgram({ ...program, categories: [...program.categories, category] });
        setCategory("");
    }

    const handleDelete = (categoryName) => {
        let remainingProgram = program?.categories?.filter(category => category !== categoryName);

        setProgram({ ...program, categories: [...remainingProgram] })
    }

    const imageTypes = ['image/png', 'image/jpeg', "image/jpg"];

    const handlImage = (e) => {
        let selected = e.target.files[0];

        if (selected && !imageTypes.includes(selected.type)) {
            // setDetails({ ...details, image: "" })
            toast.error('Please select an image file (png , jpg Or JPeg) for program picture');
            return
        }
        const storageRef = ref(projectStorage, `profile/${selected.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selected);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFileProgress(progress);
            },
            (error) => {
                toast.error(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setProgram({ ...program, image: downloadURL });
                });
            }
        );
    }

    const handleSubmit = e => {
        e.preventDefault();
        addDocument({
            ...program,
            requirements: programContent,
            curriculum
        });
    }

    return (
        <div className='w-full pb-8 '>
            <div className="max-w-screen-md mx-auto">
                <h2 className='py-8 text-3xl font-bold'>Add Programs</h2>
                <form className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md' onSubmit={handleSubmit}>
                    <div className='w-3/4 min-h-[160px] h-auto mx-auto bg-white flex justify-center items-center font-bold text-2xl '>
                        {
                            program?.image ? <img src={program?.image} alt={program?.title} /> : <h2>Preview Image {fileProgress}</h2>
                        }
                    </div>

                    <div className='flex flex-col'>
                        <label className='pb-4'>Program Image</label>
                        <input
                            type="file"
                            class="border-[#1DD1A1] text-black placeholder-[#1DD1A1] focus:border-[#1DD1A1] active:border-[#1DD1A1] file:border-[#1DD1A1] file:text-[#1DD1A1]  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#1DD1A1] file:text-white file:py-3 file:px-5 disabled:cursor-default "
                            onChange={handlImage}
                        />
                    </div>

                    <div className='flex flex-col'>
                        <label className='pb-4'>Course Title</label>
                        <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' value={program?.title} onChange={e => setProgram({ ...program, title: e.target.value })} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Course Details</label>
                        <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' value={program?.description} onChange={e => setProgram({ ...program, description: e.target.value })} />
                    </div>
                    {
                        program?.categories?.length ? <div className='my-4 w-full'>
                            <h2 className='text-2xl font-bold'>selected category</h2>
                            <div className='flex gap-2 flex-wrap py-6'>
                                {
                                    program?.categories?.map(program => (
                                        <div className='bg-admin_secondary border border-admin_primary rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                                            <p className='text-admin_primary'>{program}</p>
                                            <FaTimes className='fill-admin_primary cursor-pointer' onClick={() => handleDelete(program)} />
                                        </div>
                                    ))
                                }

                            </div>
                        </div> : null
                    }
                    <div className='flex flex-col'>
                        <label className='pb-4'>Course Category ({program.categories.length})</label>
                        <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' value={category} onChange={e => setCategory(e.target.value)} />
                        <div className='bg-[#1DD1A1] text-white py-2 rounded-md mt-4 text-center' onClick={handleCategory}>Add category</div>
                    </div>
                    <div className='flex flex-col pb-4'>
                        <label className='pb-4'>Difficulty Level</label>
                        <div class="relative">
                            <select
                                class="border-[#1DD1A1] w-full appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                value={program?.difficultyLevel} onChange={e => setProgram({ ...program, difficultyLevel: e.target.value })}
                            >
                                <option value="">Select difficulty level</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                            <span
                                class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                            >
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col pb-4'>
                        <label className='pb-4'>Language</label>
                        <div className="relative">
                            <select
                                className="border-[#1DD1A1] w-full appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                value={program?.language} onChange={e => setProgram({ ...program, language: e.target.value })}
                            >
                                <option value="">Selec Language</option>
                                <option value="Bangla">Bangla</option>
                                <option value="English">English</option>
                            </select>
                            <span
                                className="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                            >
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Course Outcomes ({program.outcomes.length})</label>
                        <input type="text" className='w-full border p-4 rounded-md outline-none border-[#1DD1A1]' value={outCome} onChange={e => setOutCome(e.target.value)} />
                        <div className='bg-[#1DD1A1] text-white py-2 rounded-md mt-4 text-center' onClick={handleOutCome}>Add</div>
                    </div>

                    <RichTextEditor
                        setContent={setProgramContent}
                    />

                    <input type="submit" value="Add Course" className='bg-[#1DD1A1] p-4 text-white rounded-md mt-4' />

                </form>
            </div>
            <Toast />
        </div>
    )
}
