import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAllBlog from '../AdminBlogs/Components/AdminAllBlog'
import AllSuperAdmins from './Components/AllSuperAdmins'

export default function AllSuperAdmin() {
    return (
        <div className='flex bg-white'>
            <SuperAdminSidebar />
            <AllSuperAdmins />
        </div>
    )
}
