import React, { useEffect } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { pageChange, pageNumber } from '../../../features/CreatorsNav/CreatorsNavSlice';
import CreatorsInfoInput from '../../../Shared/Components/CreatorsInfoInput'
import CreatorsInfoFooter from '../../../Shared/CreatorsInfoFooter';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useFirestore } from '../../../hooks/useFirestore';

export default function CreatorsInfoPageThree({ showBar, setCreatorDetails, creatorDetails,  creator }) {
    const [experience, setExperience] = useState({
        companyName: "",
        designation: "",
        jobType: "",
        responsibility: "",
        startDate: "",
        endDate: "",
    })
    const [isPresent, setIsPresent] = useState(false);
    const [error, setError] = useState("");
    
    const { addDocument, updateDocument, response } = useFirestore("creator_details");


    useEffect(() => {
        if (response?.success) {
            toast.success("Your experience added.");
        }
    }, [response?.success])

    const handleAddExperience = () => {
        setError("")
        if (!experience.companyName || !experience.designation || !experience.jobType || !experience.responsibility || !experience.startDate || (!experience.endDate && !isPresent)) {
            toast.error("Please fill all the required field");
            return
        }

        setCreatorDetails({ ...creatorDetails, experiences: [...creatorDetails?.experiences, experience] });
        setExperience({
            companyName: "",
            designation: "",
            jobType: "",
            responsibility: "",
            startDate: "",
            endDate: "",
        })
    }

    const page = useSelector(pageNumber);
    const { displayName } = useSelector(state => state.authDetail);
    const [firstName, lastName] = displayName.split(" ");
    const dispatch = useDispatch();

    const handlePageNext = () => {
        if (creatorDetails?.experiences?.length < 1) {
            toast.error("Your experience will keep you ahead from other candidate")
        } else {
            updateDocument(creator?.id, creatorDetails);
        }
        dispatch(pageChange(page + 1))
    }

    const handlePagePrev = () => {
        dispatch(pageChange(page - 1))
    }
    return (
        <div className={`w-full bg-white flex flex-col justify-between ${showBar ? "hidden md:flex" : ""}`}>
            <div className='w-full md:p-8'>
                <div className='max-w-6xl mx-auto p-8'>

                    <div>
                        <h2 className='text-2xl md:text-4xl font-bold mb-4'>Hi, {lastName}</h2>
                        <h2 className='text-2xl md:text-4xl font-bold w-full'>Add Experience</h2>
                        <div className='flex flex-col md:flex-row md:gap-12'>
                            <CreatorsInfoInput placeholder="Company Name*" type="text" value={experience.companyName} onChange={e => setExperience({ ...experience, companyName: e.target.value })} />
                            <CreatorsInfoInput placeholder="Designation*" type="text" value={experience.designation} onChange={e => setExperience({ ...experience, designation: e.target.value })} />
                        </div>
                        <div className='flex flex-col md:flex-row md:gap-12'>
                            <div className='w-full mt-4'>
                                <label className='font-bold'>Job Type:</label>
                                <select className='border-b-2 border-black w-full outline-none mt-4' value={experience.jobType} onChange={e => setExperience({ ...experience, jobType: e.target.value })}>
                                    <option value="">Select Job type</option>
                                    <option value="Intern">Intern</option>
                                    <option value="Contractual">Contractual</option>
                                    <option value="Full Time">Full Time</option>
                                </select>
                            </div>
                            <div className='w-full'>

                            </div>
                        </div>

                        <div className='flex flex-col  mt-6'>
                            <label className='font-bold'>Job Responsibility:</label>
                            <textarea className='w-full w-full resize-none w-full border-b-2 border-black p-2 outline-none' value={experience.responsibility} onChange={e => setExperience({ ...experience, responsibility: e.target.value })} />
                        </div>
                        <div className='flex flex-col md:flex-row md:gap-12'>
                            <CreatorsInfoInput placeholder="Start Date*" type="date" value={experience.startDate} onChange={e => setExperience({ ...experience, startDate: e.target.value })} />
                            {
                                !isPresent && <CreatorsInfoInput placeholder="end Date*" type="date" value={experience.endDate} onChange={e => setExperience({ ...experience, endDate: e.target.value })} />
                            }
                        </div>
                        <div className='flex flex-col md:flex-row md:space-x-6 my-6'>
                            <input type="checkbox" value={isPresent} onChange={(e) => setIsPresent(e.target.checked)} />
                            <p>This is my current job</p>
                        </div>
                        <div>
                            <button className='bg-black text-white px-4 py-2 rounded-md' onClick={handleAddExperience}>Add</button>
                        </div>
                        {
                            error && <p className='text-red-500 pt-4 font-bold'>{error}</p>
                        }
                    </div>


                    {
                        creatorDetails?.experiences?.length ? <div className='my-8'>
                            <h2 className='text-2xl md:text-4xl font-bold w-full'>Experience</h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-4 my-6 mb-16'>
                                {
                                    creatorDetails?.experiences?.map(experience => (
                                        <div className='bg-white p-4 md:p-6 rounded-md drop-shadow-xl border'>
                                            <div className='flex justify-between items-center'>
                                                <h2 className='font-bold text-xl md:text-2xl'>{experience?.companyName}</h2>
                                                <div className='inline-block bg-black text-sm text-white px-2 md:px-4 py-2 rounded-full'>{experience?.jobType}</div>
                                            </div>
                                            <p className='text-gray-500'>{experience?.designation}</p>
                                            <p className='text-gray-500'>{experience?.startDate} - {isPresent ? "Present" : experience?.endDate}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> : null
                    }


                </div>


            </div>

            <div className='w-full'>
                <div className='max-w-6xl mx-auto p-8 flex items-center justify-between'>
                    <FaArrowLeft className='w-4 h-4 md:w-6 md:h-6 cursor-pointer' onClick={handlePagePrev} />
                    <div className='w-1/2 bg-blue-200 rounded-full'>
                        <div className={`bg-blue-500 w-[50%] rounded-full`}>
                            <p className='text-xs font-bold text-white text-right pr-4'>50%</p>
                        </div>
                    </div>
                    <FaArrowRight className='w-4 h-4 md:w-6 md:h-6 cursor-pointer' onClick={handlePageNext} />
                </div>
            </div>
            {/* <CreatorsInfoFooter /> */}
        </div>
    )
}
