import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import RelatedPrograms from './RelatedPrograms';

export default function ProgramOverview({ program, allPrograms }) {
   
    return (
        <div className='max-w-screen-xl mx-auto py-12'>
            <div className='grid grid-cols-1 md:grid-cols-3 md:gap-4 m-4 '>
                <div className='col-span-1 md:col-span-2'>
                    <div className='bg-white px-8 py-12 drop-shadow-xl rounded-lg'>
                        <h2 className='text-3xl font-bold pb-6'>Course Overview</h2>
                        <p className='text-xl pb-6 text-black'>{program?.description}</p>
                        <h2 className='text-3xl font-bold pb-6'>Outcomes</h2>
                        <div className='px-4 md:px-4 py-4 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 rounded-lg border-2 border-gray-300 text-gray-700'>
                            {
                                program?.outcomes?.map(outcome => (
                                    <div className='flex space-x-4'>
                                        <FaRegCheckCircle className='fill-[#1DD1A1] basis-12 w-6 h-6'/>
                                        <p className='text-lg w-full'>{outcome}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{ lineHeight: "1.6" }}>

                            <div
                                dangerouslySetInnerHTML={{ __html: program?.requirements }}
                            />
                        </div>
                        {/* <h2 className='text-3xl font-bold py-6'>Requirements</h2>
                        <div className='px-6'>
                            <ul className='list-disc text-gray-800'>
                                <li className='text-xl'>No prior React or Node experience is required!</li>
                                <li className='text-xl'>Basic (web) development knowledge is required - you don't need to know a specific language though</li>
                                <li className='text-xl'>Basic (web) development knowledge is required - you don't need to know a specific language though</li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div className='hidden'>

                </div>
                <RelatedPrograms allPrograms={allPrograms} />
            </div>
        </div>
    )
}
