import React, { useEffect, useState } from 'react'
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useFirestore } from '../../../hooks/useFirestore';
import { toast } from 'react-toastify';
import Toast from '../../../Shared/UI/Toast';
import { useCollection } from '../../../hooks/useCollection';
import { Link } from 'react-router-dom';

export default function ProgramHeading({ program, programId }) {
    const [height, setHeight] = useState(0);
    const user = useSelector(state => state.authDetail);
    const { updateDocument, response } = useFirestore("all-programs");
    const { addDocument, response: addResponse, isloading: addIsLoading } = useFirestore("enrolled_course");
    const { document: enrolledCourses } = useCollection("enrolled_course");

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = 700;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);
    };

    const handleEnrolled = () => {
        const enrolledCourse = {
            ...program,
            studentEmail: user?.email
        }
        addDocument(enrolledCourse);


        const updatedProgram = {
            ...program,
            enrolledStudent: [...program.enrolledStudent, {
                email: user?.email,
                displayName: user?.displayName,
                assignments: []
            }]
        }
        updateDocument(program?.id, updatedProgram);
    }

    useEffect(() => {
        if (addResponse.success) {
            toast.success("You just enrolled this program");
        }
    }, [response])

    const isAlreadyApplied = enrolledCourses?.filter(indProgram => indProgram?.title === program?.title && indProgram.studentEmail === user?.email);

    console.log("isAlreadyApplied", isAlreadyApplied)

    return (
        <div className={height > 60 ? "md:fixed top-0 left-0 right-0 bg-gray-600 text-white py-4 z-40 px-4" : 'bg-gray-600 text-white py-12 px-4'}>
            <div className='max-w-screen-xl mx-auto'>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 relative'>
                    <div className='col-span-1 md:col-span-2'>
                        <h2 className={height > 60 ? 'text-xl font-bold pb-2' : 'text-3xl font-bold pb-8'}>{program?.title}</h2>
                        <p className={height > 60 ? "hidden" : 'pb-8 text-xl'}>{program?.description}</p>
                        <div className='flex space-x-6 items-center'>
                            <div className='bg-[#E1B12C] px-4 py-1 rounded-xl text-black font-bold'>{program?.difficultyLevel}</div>
                            <p className='font-bold'>{program?.enrolledStudent?.length ? program?.enrolledStudent?.length : 0} Students Enrolled</p>
                            <div className='flex items-center space-x-2'>
                                <FaAssistiveListeningSystems />
                                <p className='font-bold'>{program?.language}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="col-span-1 bg-white md:absolute top-1/6 right-0 w-fulll md:w-[350px] text-black rounded-lg p-12 drop-shadow-2xl">
                        <h2 className='text-4xl font-bold text-center pb-6'>Free</h2>
                        <div className='text-center'>
                            {
                                isAlreadyApplied?.length ? <Link to={`/dashboard/courses/${isAlreadyApplied[0]?.id}`}>
                                    <button className='bg-[#1DD1A1] px-8 py-2 text-xl text-white font-bold rounded-lg'>Continue Course</button>
                                </Link> : <button onClick={handleEnrolled} className='bg-[#1DD1A1] px-8 py-2 text-xl text-white font-bold rounded-lg'>Get Enrolled</button>
                            }

                        </div>

                        <p className='text-2xl font-bold pt-8 pb-4'>Includes:</p>
                        <div>
                            <div className='flex items-center space-x-4'>
                                <FaAssistiveListeningSystems />
                                <p className='text-xl'>30 Lessons</p>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <FaAssistiveListeningSystems />
                                <p className='text-xl'>Life time access</p>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <FaAssistiveListeningSystems />
                                <p className='text-xl'>Get Certified</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toast />
        </div>
    )
}
