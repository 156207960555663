import React, { useState } from "react";
import CreatorsInfoInput from "../../../Shared/Components/CreatorsInfoInput";
import { FaArrowRight } from "react-icons/fa";
import CreatorsInfoFooter from "../../../Shared/CreatorsInfoFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  pageChange,
  pageNumber,
} from "../../../features/CreatorsNav/CreatorsNavSlice";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";
import { projectStorage } from "../../../firebase.config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  addDocumentData,
  createDocument,
} from "../../../features/fireStore/addDocumentSlice";
import { useFirestore } from "../../../hooks/useFirestore";
import { useEffect } from "react";
import profile from "../../../assets/images/profile.png";

export default function CreatorsInfoPageOne({
  showBar,
  setCreatorDetails,
  creatorDetails,
  creator,
}) {
  let [error, setError] = useState("");
  let [fileProgress, setFileProgress] = useState("");
  let [fileError, setFileError] = useState("");
  let [file, setFile] = useState("");

  const page = useSelector(pageNumber);
  const dispatch = useDispatch();

  const { addDocument, updateDocument, response } =
    useFirestore("creator_details");

  const addDocumentState = useSelector(addDocumentData);
  const { email, displayName } = useSelector((state) => state.authDetail);
  const splitName = displayName.split(" ");

  let [lastName] = splitName.splice(splitName.length - 1);
  let firstName = splitName.join(" ");

  useEffect(() => {
    if (email) {
      setCreatorDetails({
        ...creatorDetails,
        basicInfo: {
          ...creatorDetails.basicInfo,
          firstName: firstName,
          lastName,
          email,
        },
      });
    }
  }, [firstName, email]);

  useEffect(() => {
    if (creator?.basicInfo?.email) {
      setCreatorDetails({
        ...creatorDetails,
        basicInfo: { ...creatorDetails.basicInfo },
        skills: [...creator?.skills],
        experiences: [...creator?.experiences],
        additionalInfo: { ...creator?.additionalInfo },
        role: creator?.role,
        roleDefined: creator?.roleDefined,
      });
    }
  }, [creator]);
  console.log("creator", creator);

  useEffect(() => {
    if (response?.success) {
      toast.success("Your basic information added.");
      handlePageNext();
    }
  }, [response?.success]);

  const types = ["application/pdf", "application/msword"];

  const handleResume = (e) => {
    let selected = e.target.files[0];
    console.log("selected", selected);

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setFileError("Please select a file (pdf , msword) for resume");
      toast.error("Please select a file (pdf , msword) for resume");
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setCreatorDetails({
            ...creatorDetails,
            basicInfo: { ...creatorDetails?.basicInfo, resume: downloadURL },
          });
        });
      }
    );
  };
  const imageTypes = ["image/png", "image/jpeg", "image/jpg"];

  const handlImage = (e) => {
    let selected = e.target.files[0];

    if (selected && !imageTypes.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setFileError(
        "Please select an image file (png , jpg Or jpeg) for profile picture"
      );
      toast.error(
        "Please select an image file (png , jpg Or jpeg) for profile picture"
      );
      return;
    }
    const storageRef = ref(projectStorage, `profile/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setCreatorDetails({
            ...creatorDetails,
            basicInfo: { ...creatorDetails?.basicInfo, image: downloadURL },
          });
        });
      }
    );
  };

  const handleFirstPage = () => {
    if (response?.isLoading) {
      return;
    }
    if (
      !creatorDetails.basicInfo.firstName ||
      !creatorDetails.basicInfo.lastName ||
      !creatorDetails.basicInfo.email ||
      !creatorDetails.basicInfo.phoneNumber ||
      !creatorDetails.basicInfo.dateOfBirth ||
      !creatorDetails.basicInfo.gender ||
      !creatorDetails.basicInfo.institute
    ) {
      toast.error("please fill all the required field");
      return;
    }
    if (!creator?.basicInfo?.email) {
      addDocument(creatorDetails);
    } else {
      updateDocument(creator?.id, creatorDetails);
    }
  };

  const handlePageNext = () => {
    dispatch(pageChange(page + 1));
  };
  return (
    <div
      className={`w-full bg-white flex flex-col justify-between ${
        showBar ? "hidden md:flex" : ""
      }`}
    >
      <div className="max-w-6xl mx-auto p-8 md:p-16 ">
        <div className="flex flex-col space-x-4 justify-center items-center w-full mb-4 md:mb-12">
          <img
            src={
              creator?.basicInfo?.image ? creator?.basicInfo?.image : profile
            }
            className="w-20 h-20 md:w-40 md:h-40 rounded-full text-center"
          />
          <div className="w-full md:w-1/3 mx-auto mt-4">
            <label className="mb-4 inline-block">
              Uplaod your image {fileProgress}:{" "}
              {creatorDetails?.basicInfo?.image ? "(already uploaded)" : null}
            </label>
            <input
              type="file"
              class="border-[#576574] text-black placeholder-[#576574] focus:border-[#576574] active:border-[#576574] file:border-[#576574] file:text-white  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#576574] file:text-white file:py-2 file:px-5 disabled:cursor-default "
              accept="image/*"
              onChange={handlImage}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-12">
          <div className="w-full mt-4">
            <label className="">First Name*</label>
            <input
              type="text"
              value={firstName}
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
          <div className="w-full mt-4">
            <label className="">Last Name*</label>
            <input
              type="text"
              value={lastName}
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
          {/* <CreatorsInfoInput placeholder="" type="text" />
                    <CreatorsInfoInput placeholder="Last Name*" type="text" /> */}
        </div>
        <div className="flex flex-col md:flex-row  md:gap-12">
          <div className="w-full mt-4">
            <label className="">Email*</label>
            <input
              type="email"
              value={email}
              onChange={(e) =>
                setCreatorDetails({
                  ...creatorDetails,
                  basicInfo: {
                    ...creatorDetails.basicInfo,
                    email: e.target.value,
                  },
                })
              }
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
          <div className="w-full mt-4">
            <label className="">Phone Number*</label>
            <input
              type="tel"
              required
              defaultValue={creatorDetails?.basicInfo?.phoneNumber}
              onChange={(e) =>
                setCreatorDetails({
                  ...creatorDetails,
                  basicInfo: {
                    ...creatorDetails.basicInfo,
                    phoneNumber: e.target.value,
                  },
                })
              }
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row  md:gap-12">
          <div className="w-full mt-4">
            <label className="">Date of birth*</label>
            <input
              type="date"
              defaultValue={creator?.basicInfo?.dateOfBirth}
              onChange={(e) =>
                setCreatorDetails({
                  ...creatorDetails,
                  basicInfo: {
                    ...creatorDetails.basicInfo,
                    dateOfBirth: e.target.value,
                  },
                })
              }
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
          <div className="w-full mt-8">
            <label>Sleect Gender:*</label>
            <div className="flex gap-4 mt-2">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="Male"
                  onChange={(e) =>
                    setCreatorDetails({
                      ...creatorDetails,
                      basicInfo: {
                        ...creatorDetails.basicInfo,
                        gender: e.target.value,
                      },
                    })
                  }
                  checked={
                    creatorDetails?.basicInfo?.gender === "Male" ? true : false
                  }
                />
                <label htmlFor="male">Male</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  onChange={(e) =>
                    setCreatorDetails({
                      ...creatorDetails,
                      basicInfo: {
                        ...creatorDetails.basicInfo,
                        gender: e.target.value,
                      },
                    })
                  }
                  checked={
                    creatorDetails?.basicInfo?.gender === "Female"
                      ? true
                      : false
                  }
                />
                <label htmlFor="female">Female</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="Other"
                  name="gender"
                  value="Other"
                  onChange={(e) =>
                    setCreatorDetails({
                      ...creatorDetails,
                      basicInfo: {
                        ...creatorDetails.basicInfo,
                        gender: e.target.value,
                      },
                    })
                  }
                  checked={
                    creatorDetails?.basicInfo?.gender === "Other" ? true : false
                  }
                />
                <label htmlFor="Other">Other</label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row  md:gap-12">
          <div className="w-full mt-8">
            <label>
              Upload cv:
              {creatorDetails?.basicInfo?.resume ? "(already uploaded)" : null}
            </label>
            <input
              type="file"
              class="border-[#576574] text-black placeholder-[#576574] mt-4 focus:border-[#576574] active:border-[#576574] file:border-[#576574] file:text-white  w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid  file:bg-[#576574] file:text-white file:py-2 file:px-5 disabled:cursor-default "
              accept=".doc,.pdf"
              onChange={handleResume}
            />
            <p className="mt-2">
              <span className="font-bold">NB:</span> It’s not necessary to put
              your cv right away. But we encourage you to put it here if you
              think your are eligible for work. so that we can work together
              from today.
            </p>
          </div>
          <div className="w-full mt-8">
            <label className="">Institute*</label>
            <input
              type="text"
              required
              defaultValue={creatorDetails?.basicInfo?.institute}
              onChange={(e) =>
                setCreatorDetails({
                  ...creatorDetails,
                  basicInfo: {
                    ...creatorDetails.basicInfo,
                    institute: e.target.value,
                  },
                })
              }
              className="w-full border-b-2 border-black p-2 outline-none"
            />
          </div>
        </div>

        {error && <p className="text-red-500 pt-4 font-bold">{error}</p>}
      </div>

      {/* Button component */}
      <div className="w-full">
        <div className="max-w-6xl mx-auto p-8 flex justify-between items-center">
          <div></div>
          <div className="w-1/3 bg-blue-200 rounded-full">
            <div className={`bg-blue-500 w-[0%] rounded-full`}>
              <p className="text-xs font-bold text-white text-right pl-4 py-1">
                0%
              </p>
            </div>
          </div>
          <FaArrowRight
            className="w-4 h-4 md:w-6 md:h-6 cursor-pointer"
            onClick={handleFirstPage}
          />
        </div>
        <Toast />
      </div>
      {/* <CreatorsInfoFooter /> */}
    </div>
  );
}
