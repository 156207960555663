import React, { useState, useEffect } from 'react';
import { useFirestore } from '../../../../hooks/useFirestore';
import { toast } from 'react-toastify';
import Toast from '../../../../Shared/UI/Toast';
import profile from "../../../../assets/images/profile.png"

export default function EditCreator({ creatorEditDetail }) {
    const { updateDocument, isLoading, response } = useFirestore("creator_details");

    const [role, setRole] = useState("");

    const handleUpdate = () => {
        let updatedObj = {
            ...creatorEditDetail,
            assessments: [],
            role,
            roleDefined: "admin"
        }
        if (!role) {
            toast.error("Please select creator role");
            return
        }

        updateDocument(creatorEditDetail?.id, updatedObj);
    }

    useEffect(() => {
        setRole(creatorEditDetail?.role);
    }, [creatorEditDetail?.role])

    useEffect(() => {
        if (response.success) {
            toast.success("Successfully updated");
        }
    }, [response])

    return (
        <div className='relative bg-[#E7FFF9] mt-12 p-16 drop-shadow-md rounded-md'>
            <img src={creatorEditDetail?.basicInfo?.image ? creatorEditDetail?.basicInfo?.image : profile} alt={creatorEditDetail?.basicInfo?.firstName + " " + creatorEditDetail?.basicInfo?.lastName} className='w-16 h-16 rounded-full absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2' />
            <div className='text-center'>
                <p><span className='font-bold'>Role:</span> {creatorEditDetail?.role}</p>
                <p><span className='font-bold'>Role Defined:</span> {creatorEditDetail?.roleDefined}</p>
            </div>
            <div className='flex justify-between'>
                <div className='flex flex-col space-y-4'>
                    <p><span className='font-bold'>Name:</span> {creatorEditDetail?.basicInfo?.firstName + " " + creatorEditDetail?.basicInfo?.lastName}</p>
                    <p><span className='font-bold'>Email:</span> {creatorEditDetail?.basicInfo?.email}</p>
                    <p><span className='font-bold'>Phone:</span> {creatorEditDetail?.basicInfo?.phoneNumber}</p>
                    <p><span className='font-bold'>Gender:</span> {creatorEditDetail?.basicInfo?.gender}</p>
                    <p><span className='font-bold'>Date of Birth:</span> {creatorEditDetail?.basicInfo?.dateOfBirth}</p>
                </div>
                <div className='flex flex-col space-y-4'>
                    <p><span className='font-bold'>Github:</span> {creatorEditDetail?.additionalInfo?.github}</p>
                    <p><span className='font-bold'>Linkedin:</span> {creatorEditDetail?.additionalInfo?.linkedin}</p>
                    <p><span className='font-bold'>Portfolio:</span> {creatorEditDetail?.additionalInfo?.portfolio}</p>
                    <p><span className='font-bold'>Cv/Resume:</span> {creatorEditDetail?.additionalInfo?.portfolio}</p>
                </div>
            </div>
            <h2 className='text-2xl py-4 font-bold'>Skills:</h2>
            <div className='min-w-[900px] bg-white p-8 rounded-md flex  flex-wrap items-center gap-4'>
                {
                    creatorEditDetail?.skills?.map(skill => (
                        <div className='bg-admin_primary inline-block py-2 px-4 rounded-md text-white'>
                            {skill}
                        </div>
                    ))
                }

            </div>

            <h2 className='text-2xl py-4 font-bold'>Experience:</h2>
            <div className='grid grid-cols-2 gap-6'>
                {
                    creatorEditDetail?.experiences?.map(experience => (
                        <div className='bg-white p-6 rounded-md'>
                            <div className='flex justify-between items-center'>
                                <h2 className='font-bold text-2xl'>{experience?.companyName}</h2>
                                <div className='inline-block bg-admin_primary text-white px-4 py-2 rounded-full'>{experience?.jobType}</div>
                            </div>
                            <p className='text-gray-500'>{experience?.designation}</p>
                            <p className='text-gray-500'>{experience?.startDate} - {experience?.endDate ? experience?.endDate : "present"}</p>
                        </div>
                    ))
                }
            </div>

            {
                creatorEditDetail?.assessments[0]?.selectedAssessment?.category ? <div>
                    <h1 className='font-bold text-xl'>Assessment Details:</h1>
                    <p><span className='font-bold'>category:</span> {creatorEditDetail?.assessments[0]?.selectedAssessment?.category}</p>
                    <div>
                        <h2 className='font-bold text-xl'>Answers:</h2>
                        <div>
                            {
                                creatorEditDetail?.assessments[0]?.answers?.map((answer, index) => (

                                    answer && <p>{index + 1}: {answer}</p>


                                ))
                            }
                        </div>
                    </div>
                </div> : null
            }


            <div className='flex flex-col pb-4'>
                <label className='py-4 text-2xl font-bold'>Select Role</label>
                <div class="relative">
                    <select
                        class="border-[#1DD1A1] w-full appearance-none rounded-md border py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                        value={role}
                        onChange={e => setRole(e.target.value)}
                    >
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                    </select>
                    <span
                        class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                    >
                    </span>
                </div>
            </div>
            <button className='bg-admin_primary px-4 py-2 rounded-md  font-bold text-white' onClick={handleUpdate}>Update</button>
            <Toast />
        </div>
    )
}

