import React from 'react'

export default function SingleAssessment({type, setType, assessment, setAssessment}) {
     return (
        <div className='bg-white p-4 rounded-md my-4'>
            {/* Question Type */}
            <div className='flex flex-col pb-4'>
                <label className='pb-4'>Question Type: {type}</label>
                <div class="relative">
                    <select
                        class="border-[#1DD1A1] w-full appearance-none rounded-md border py-2 px-4 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value)
                            setAssessment({ ...assessment, questionType: e.target.value })
                        }}
                    >
                        <option value="">Select Question Type</option>
                        <option value="option">Option</option>
                        <option value="text">Text</option>
                    </select>
                    <span
                        class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                    >
                    </span>
                </div>
            </div>

            {/* Question */}
            <div className='flex flex-col'>
                <label className='pb-4'>Question</label>
                <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.question} onChange={e => setAssessment({ ...assessment, question: e.target.value })} />
            </div>
            {
                type === "option" && <div className='grid grid-cols-2 gap-4'>

                    <div className='flex flex-col'>
                        <label className='pb-4'>Options 1</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.option1} onChange={e => setAssessment({ ...assessment, option1: e.target.value })} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Options 2</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.option2} onChange={e => setAssessment({ ...assessment, option2: e.target.value })} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Options 3</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.option3} onChange={e => setAssessment({ ...assessment, option3: e.target.value })} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Options 4</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.option4} onChange={e => setAssessment({ ...assessment, option4: e.target.value })} />
                    </div>
                </div>
            }

            <div className='flex flex-col'>
                <label className='pb-4'>Answer</label>
                <input type="number" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={assessment.correctAnswerIndex} onChange={e => setAssessment({ ...assessment, correctAnswerIndex: e.target.value })} />
            </div>
        </div>
    )
}
