import React from 'react'
import mahin from "../../../assets/images/mahin.png";
import Ashik from "../../../assets/images/Ashik.png";
import nahiyan from "../../../assets/images/nahiyan.png";
import anotation from "../../../assets/images/testimonial.png";

export default function Testimonail() {
    return (
        <div className='max-w-screen-xl mx-auto py-[120px] px-8'>
            <h2 className='text-2xl md:text-4xl font-bold text-center md:pb-10'>Words of Our Network</h2>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-[120px] md:gap-6 py-12 '>
                <div className='bg-secondary p-8 rounded-lg relative drop-shadow-lg'>
                    <div className='flex justify-center'>
                        <img src={anotation} alt="anotation" />
                    </div>
                    <p className='p-6 text-center italic'> Ongshak has been a constant support for all of our Tech requirements so far with affordable solutions. </p>
                    <div className='absolute -bottom-[35%] md:-bottom-[50%] left-1/2 -translate-x-1/2 w-full text-center'>
                        <div className="flex flex-col justify-center items-center">
                            <div className='text-center'>
                                <img src={Ashik} alt="Ashik Muntakim" className='w-16 h-16 rounded-full ' />
                            </div>
                            <h2 className='font-bold pt-4'>Ashik Muntakim</h2>
                            <p className='italic'>Chief of Staff, Healthport Bangladesh</p>
                        </div>
                    </div>
                </div>
                <div className='bg-secondary p-8 rounded-lg relative drop-shadow-lg'>
                    <div className='flex justify-center'>
                        <img src={anotation} alt="anotation" />
                    </div>
                    <p className='p-6 text-center italic'>I had a wonderful time with Ongshak. The website that they made for me was fantastic and served my purpose.</p>
                    <div className='absolute -bottom-[35%] md:-bottom-[50%] left-1/2 -translate-x-1/2  w-full text-center'>
                        <div className="flex flex-col justify-center items-center">
                            <div className='text-center'>
                                <img src={mahin} alt="mahin" className='w-16 h-16 rounded-full ' />
                            </div>
                            <h2 className='font-bold pt-4'>Mahin Abrar Rahman</h2>
                            <p className='italic'>Co-Founder, The Psycure Limited</p>
                        </div>
                    </div>
                </div>
                <div className='bg-secondary p-8 rounded-lg relative drop-shadow-lg'>
                    <div className='flex justify-center'>
                        <img src={anotation} alt="anotation" />
                    </div>
                    <p className='p-6 text-center italic'>Truly a smart tech solution for those who want to grow together with an expert and efficient team. Good luck.</p>
                    <div className='absolute  -bottom-[35%] md:-bottom-[50%] left-1/2 -translate-x-1/2  text-center'>
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='text-center'>
                                <img src={nahiyan} alt="Syed Al-Nahian" className='w-16 h-16 rounded-full ' />
                            </div>
                            <h2 className='font-bold pt-4'>Syed Al-Nahian</h2>
                            <p className='italic'>CEO, The Physics Guy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


{/* <div className='bg-secondary p-8 rounded-lg relative drop-shadow-lg'>
<p>Their courses are the only good things to happen in 2020. Ongshak keeping me busy immersed in studies</p>
<div className='absolute -bottom-1/2 left-1/2 -translate-x-1/2 text-center'>
    <img src={mahin} alt="mahin" className='w-12 h-12 rounded-full ml-12' />
    <h2 className='font-bold pt-4'>Mahin Abrar</h2>
    <p className='italic'>Founder, Psycure LTD</p>
</div>
</div>
<div className='bg-primary p-8 rounded-lg relative scale-125 mb-32 z-10  drop-shadow-lg'>
<p className='text-white'>Their courses are the only good things to happen in 2020. Ongshak keeping me busy immersed in studies</p>
<div className='absolute -bottom-1/2 left-1/2 -translate-x-1/2 text-center'>
    <img src={mahin} alt="mahin" className='w-12 h-12 rounded-full ml-12' />
    <h2 className='font-bold pt-4'>Mahin Abrar</h2>
    <p className='italic'>Founder, Psycure LTD</p>
</div>
</div>
<div className='bg-secondary p-8 rounded-lg relative drop-shadow-lg'>
<p>Their courses are the only good things to happen in 2020. Ongshak keeping me busy immersed in studies</p>
<div className='absolute -bottom-1/2 left-1/2 -translate-x-1/2 text-center'>
    <img src={mahin} alt="mahin" className='w-12 h-12 rounded-full ml-12' />
    <h2 className='font-bold pt-4'>Mahin Abrar</h2>
    <p className='italic'>Founder, Psycure LTD</p>
</div>
</div> */}