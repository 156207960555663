import React from 'react'
import { programsData } from '../../Home/Data/Data'
import Slider from 'infinite-react-carousel';
import Program from '../../../Shared/Components/Program';
import { useCollection } from '../../../hooks/useCollection';
import { FaAngleRight, FaAssistiveListeningSystems } from 'react-icons/fa';

export default function RelatedPrograms({ allPrograms }) {
    const calculateSlidesToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 992) {
            return 3;
        } else if (screenWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };

    const slidesToShow = calculateSlidesToShow();

    // const { document } = useCollection("all-programs");

    let allProgram = allPrograms.length ? allPrograms?.map(program => (<Program  program={program} key={program?.id} />)) : <p>loading</p>;

    return (
        <div className='col-span-3'>
            <h2 className='text-3xl font-bold py-6 text-center'>Related programs</h2>
            <div className='program_card'>
               <div className='py-8  program_card '>
                <Slider
                    slidesToShow={slidesToShow}
                    adaptiveHeight={true}
                    >
                    {
                        allProgram
                    }
                    {/* {
                        document?.map(doc => <h2>{doc}</h2>)
                    } */}
                </Slider>
            </div>
            </div>
        </div>
    )
}
