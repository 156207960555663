import React, { useState, useEffect } from "react";
import CreatorsInfoNav from "./Components/CreatorsInfoNav";
import Footer from "../../Shared/Footer";
import CreatorsInfoSidebar from "./Components/CreatorsInfoSidebar";
import CreatorsInfoPageOne from "./Components/CreatorsInfoPageOne";
import CreatorsInfoPageTwo from "./Components/CreatorsInfoPageTwo";
import CreatorsInfoPageThree from "./Components/CreatorsInfoPageThree";
import CreatorsInfoPageFour from "./Components/CreatorsInfoPageFour";
import { useSelector } from "react-redux";
import {
  isShowBar,
  pageNumber,
} from "../../features/CreatorsNav/CreatorsNavSlice";
import { useCollection } from "../../hooks/useCollection";
import CreatorsDashboardLayout from "../../Shared/Layout/CreatorsDashboardLayout";

export default function CreatorsInfo() {
  const showBar = useSelector(isShowBar);
  const page = useSelector(pageNumber);
  const { email } = useSelector((state) => state.authDetail);

  const { document: allCreatorDetails } = useCollection("creator_details");
  const creator = allCreatorDetails.filter(
    (creator) => creator?.basicInfo?.email === email
  );

  const [creatorDetails, setCreatorDetails] = useState({
    basicInfo: {
      firstName: "",
      lastName: "",
      email: "",
      image: "",
      phoneNumber: "",
      dateOfBirth: "",
      gender: "",
      institute: "",
      resume: "",
    },
    skills: [],
    experiences: [],
    additionalInfo: {
      github: "",
      linkedin: "",
      portfolio: "",
      others: "",
    },
    role: "",
    roleDefined: "",
  });

  console.log("creatorDetails", creatorDetails);

  return (
    <CreatorsDashboardLayout creatorDetails={creatorDetails}>
      {page === 1 ? (
        <CreatorsInfoPageOne
          showBar={showBar}
          setCreatorDetails={setCreatorDetails}
          creatorDetails={creatorDetails}
          creator={creator[0]}
        />
      ) : null}
      {/* {
                page === 2 ? <CreatorsInfoPageTwo showBar={showBar} setCreatorDetails={setCreatorDetails} creatorDetails={creatorDetails} creator={creator[0]} /> : null
            } */}
      {page === 2 ? (
        <CreatorsInfoPageThree
          showBar={showBar}
          setCreatorDetails={setCreatorDetails}
          creatorDetails={creatorDetails}
          creator={creator[0]}
        />
      ) : null}
      {page === 3 ? (
        <CreatorsInfoPageFour
          showBar={showBar}
          setCreatorDetails={setCreatorDetails}
          creatorDetails={creatorDetails}
          creator={creator[0]}
        />
      ) : null}
    </CreatorsDashboardLayout>
  );
}
