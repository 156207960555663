import React from 'react'
import CreatorsInfoSidebar from '../Components/CreatorsInfoSidebar'
import { useSelector } from 'react-redux';
import { isShowBar } from '../../../features/CreatorsNav/CreatorsNavSlice';
import { useCollection } from '../../../hooks/useCollection';
import JobDetails from '../../Jobs/JobDetails';

export default function AllJobDashboardDetails() {
    const showBar = useSelector(isShowBar);

    const { email } = useSelector(state => state.authDetail);

    const { document: allCreatorDetails } = useCollection("creator_details");
    const { document: allJobs } = useCollection("Jobs");
    const creator = allCreatorDetails.filter(creator => creator?.basicInfo?.email === email);

    return (
        <div className='min-h-screen flex'>
            <CreatorsInfoSidebar creatorDetails={creator[0]} />
            {/* <div className='max-w-screen-xl mx-auto min-h-[50vh]  flex flex-col md:flex-row gap-4 items-start p-4'> */}
            <div className={`w-[calc(100%-5rem)] min-h-[50vh] bg-white ${showBar ? "hidden md:block" : ""}`}>
                <JobDetails />
            </div>
        </div>
    )
}
