import React, { useState, useEffect } from 'react'

export default function SelectedAssessment({ selectedAssessment, answers, handleAnswerSelection, handleSubmit, timeLeft, setTimeLeft }) {
    // countdown timer
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className=''>
            <p className='text-center text-xl py-4'><span className='font-bold'>Time Left</span>: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}</p>
            <h2 className='text-xl font-bold pb-10 text-center'>{selectedAssessment?.category} Assessment</h2>
            {
                selectedAssessment?.allQuestions?.map((question, questinoIndex) => (
                    <div className='py-4'>
                        <h1 className='text-2xl pb-4'>{question?.question}</h1>
                        {
                            question?.questionType === "option" ? <div className='grid grid-cols-1 gap-6'>
                                <div className={` p-6 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 0 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 0)} >
                                    {question?.option1}
                                </div>
                                <div className={` p-6 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 1 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 1)} >
                                    {question?.option2}
                                </div>
                                <div className={` p-6 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 2 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 2)} >
                                    {question?.option3}
                                </div>
                                <div className={` p-6 rounded-md border border-primary cursor-pointer ${answers[questinoIndex] == 3 ? "bg-primary text-white" : ""}`} onClick={() => handleAnswerSelection(questinoIndex, 3)} >
                                    {question?.option4}
                                </div>
                            </div> : <textarea value={answers[questinoIndex]} className='w-full h-[200px] resize-none focus:outline-none p-2 border border-primary rounded-md' onChange={(e) => handleAnswerSelection(questinoIndex, e.target.value)} />
                        }
                    </div>
                ))
            }
            {
                selectedAssessment?.category && <div className='text-right'>
                    <button className='bg-primary text-white px-4 py-2 rounded-md' onClick={handleSubmit}>Submit</button>
                </div>
            }

        </div>
    )
}
