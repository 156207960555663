import React from 'react'

export default function CreatorExperience() {
    return (
        <div className='w-full bg-secondary rounded-md p-6'>
            <div className='flex justify-between items-center'>
                <h2 className='font-bold text-xl'>Job Experience</h2>
                <p className='text-primary cursor-pointer'>Add Experience</p>
            </div>

            <div className='my-6'>
                <div className='flex justify-between itmes-center'>
                    <h2 className='font-bold text-xl pb-4'>Frontend Developer</h2>
                    <div className='flex gap-4'>
                        <button className='text-primary'>Edit</button>
                        <button className='text-red-500'>Delete</button>
                    </div>
                </div>
                <div className='text-gray-500'>
                    <p>Ongshak</p>
                    <p>July 2021 - September 2021</p>
                    <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visua</p>
                </div>
            </div>
            <div className='my-6'>
                <div className='flex justify-between itmes-center'>
                    <h2 className='font-bold text-xl pb-4'>Frontend Developer</h2>
                    <div className='flex gap-4'>
                        <button className='text-primary'>Edit</button>
                        <button className='text-red-500'>Delete</button>
                    </div>
                </div>
                <div className='text-gray-500'>
                    <p>Ongshak</p>
                    <p>July 2021 - September 2021</p>
                    <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visua</p>
                </div>
            </div>
        </div>
    )
}
