import React, { useEffect, useState } from 'react'
import Navbar from '../../Shared/Navbar'
import Footer from '../../Shared/Footer'
import { useSelector } from 'react-redux'
import { useFirestore } from '../../hooks/useFirestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Shared/UI/Toast';

export default function Level() {
    const { email, displayName } = useSelector(state => state.authDetail);
    const [firstName, lastName] = displayName.split(" ");

    let [creatorObj, setCreatorObj] = useState({
        basicInfo: {
            firstName,
            lastName,
            email,
            image: "",
            phoneNumber: "",
            dateOfBirth: "",
            gender: "",
            institute: "",
            resume: ""
        },
        skills: [],
        experiences: [],
        additionalInfo: {
            github: "",
            linkedin: "",
            portfolio: "",
            others: "",
        },
        assessments: [],
        role: "",
        roleDefined: "creator"
    })

    const navigate = useNavigate("");

    const { addDocument, response } = useFirestore("creator_details")

    const handleSubmit = () => {
        if (!creatorObj?.role) {
            toast.error("plase select your level.");
            return
        }
        addDocument(creatorObj)
    };

    useEffect(() => {
        if (response.success) {
            toast.success("Your level setup done");
            if (creatorObj?.role === "Beginner") {
                navigate("/dashboard/all-courses")
            } else if (creatorObj?.role === "Intermediate" || creatorObj?.role === "Advanced") {
                navigate("/assessment")
            }
        }
    }, [response])

    return (
        <div>
            <Navbar />
            <div className='min-h-[500px] flex justify-center items-center flex-col'>
                <h2 className='text-2xl pb-12 font-bold'>Select your Level</h2>
                <div className='flex gap-12 justify-between items-center'>
                    <div className={` p-10 text-black text-xl rounded-lg cursor-pointer ${creatorObj?.role === "Beginner" ? "bg-primary text-white" : "bg-secondary"}`} onClick={e => setCreatorObj({ ...creatorObj, role: "Beginner" })}>
                        Beginner
                    </div>
                    <div className={` p-10 text-black text-xl rounded-lg cursor-pointer ${creatorObj?.role === "Intermediate" ? "bg-primary text-white" : "bg-secondary"} `} onClick={e => setCreatorObj({ ...creatorObj, role: "Intermediate" })}>
                        Intermediate
                    </div>
                    <div className={` p-10 text-black text-xl rounded-lg cursor-pointer ${creatorObj?.role === "Advanced" ? "bg-primary text-white" : "bg-secondary"}`} onClick={e => setCreatorObj({ ...creatorObj, role: "Advanced" })}>
                        Advanced
                    </div>
                </div>
                <div className='py-8'>
                    <button className='bg-primary px-4 py-2 text-white rounded-md' onClick={handleSubmit} >Submit</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}
