import React from 'react'
import Navbar from '../../Shared/Navbar'
import Footer from '../../Shared/Footer'
import Assesment from './Components/Assesment'

export default function Assessment() {
  return (
    <div>
      <Navbar />
      <Assesment />
      <Footer />
    </div>
  )
}
