import React from 'react'

export default function FormInput({ label, type, htmlFor, handleChange }) {
    return (
        <div className='flex flex-col w-full'>
            <label className='font-bold pb-2' htmlFor={htmlFor}>{label}</label>
            <input onChange={(e) => handleChange(e.target.value)} className='border border-gray-300 outline-none p-2 rounded-lg w-full' type={type} id={htmlFor} />
        </div>  
    )
}
