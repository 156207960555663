import React, { useEffect, useState } from 'react'
import { useFirestore } from '../../../hooks/useFirestore'

export default function CourseTutorial({ program, currentTutorial, setCurrentTutorial, showAssessment, setShowAssessment }) {
    let [assignmentLink, setAssignmentLink] = useState("");

    const { updateDocument, response } = useFirestore("enrolled_course");

    console.log("isLoading", response?.isLoading)

    const handleNextTutorial = e => {
        if (response?.isLoading) {
            return
        }
        if (program.curriculum[currentTutorial.id].watch) {
            setCurrentTutorial({ ...program?.curriculum[currentTutorial?.id + 1], id: currentTutorial?.id + 1 });
        } else {
            if (program.curriculum[currentTutorial.id].asssessment && !program.curriculum[currentTutorial.id].answer && !assignmentLink) {
                setShowAssessment(true);
            } else {
                program.curriculum[currentTutorial.id] = { ...currentTutorial, watch: true, answer: assignmentLink };

                updateDocument(program?.id, program)
            }
        }
    }

    useEffect(() => {
        if (response.success) {
            setCurrentTutorial({ ...program?.curriculum[currentTutorial?.id + 1], id: currentTutorial?.id + 1 });
            setShowAssessment(false);
            setAssignmentLink("");
        }
    }, [response])


    const watchTutorial = program?.curriculum?.filter(tutorial => tutorial.watch);
    const unWatchTutorial = program?.curriculum?.filter(tutorial => !tutorial.watch);

    const percentage = (watchTutorial?.length / program?.curriculum?.length) * 100;
    const isVideo = currentTutorial?.videoUrl?.includes("https://www.youtube.com/embed")
    return (
        <div className='w-full'>
            {
                percentage >= 100 ? <h2 className='text-xl'>Course Completed</h2> : null
            }

            <div className='w-full'>
                {
                    !showAssessment ? isVideo ? <iframe className='w-full h-[300px] md:h-[500px]' src={currentTutorial?.videoUrl} title={currentTutorial?.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : <div className='w-full bg-white p-8'>
                        <h2 className='text-xl font-bold pb-6'>Read the docs carefully.</h2>
                        <p><span className='font-bold'>Link: </span><a className='underline' href={currentTutorial?.videoUrl} target='_blank'>{currentTutorial?.videoUrl}</a></p>

                    </div> : <div className='w-full bg-white p-8'>
                        <h2 className='text-xl font-bold pb-6'>You have a assignment.</h2>
                        <p><span className='font-bold'>Task: </span>{currentTutorial?.asssessment}</p>

                        <input type="text" placeholder='upload github/docs/notion link' className='p-4 border w-full rounded-md border-primary' value={assignmentLink} onChange={(e) => setAssignmentLink(e.target.value)} />

                    </div>
                }


            </div>
            {
                program?.curriculum?.length < currentTutorial.id + 1 ? null : <div className='text-right pt-4'>
                    <button className='bg-primary text-white px-4 py-2 rounded-md' onClick={handleNextTutorial}>Next</button>
                </div>
            }

        </div>
    )
}
