import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import rafi from "../assets/images/rafi.jpg"

export default function CreatorsProfileSidebar() {
    return (
        <div className='md:sticky top-0 left-0 w-full md:min-w-[270px] md:max-w-[270px] bg-secondary md:min-h-[calc(100vh_-_10rem)] py-8 rounded-md'>
            <div>
                <div className='flex justify-center items-center py-4'>
                    <img src={rafi} className="w-16 h-16 rounded-full drop-shadow-2xl text-center" />
                </div>
                <div>
                    <Link to="/dashboard/profile">
                        <div className='flex gap-4 items-center px-8 py-4 cursor-pointer bg-primary text-white hover:bg-primary hover:text-white'>
                            <FaUserCircle />
                            <p>My Profile</p>
                        </div>
                    </Link>
                    <Link to="/dashboard/profile/experience">
                        <div className='flex gap-4 items-center px-8 py-4 cursor-pointer hover:bg-primary hover:text-white'>
                            <FaUserCircle />
                            <p>Experience</p>
                        </div>
                    </Link>
                    <Link to="/dashboard/profile/skills">
                        <div className='flex gap-4 items-center px-8 py-4 cursor-pointer hover:bg-primary hover:text-white'>
                            <FaUserCircle />
                            <p>Skill set</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
