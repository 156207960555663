import React from 'react'
import rafi from "../../../../assets/images/rafi.jpg";

export default function ProfileInfo() {
  return (
    <div className='w-full bg-secondary rounded-md p-6'>
      <div className='flex flex-col md:flex-row'>
        <div className='w-full md:w-1/3 text-center'>
          <div className='flex justify-center'>
          <img src={rafi} alt="creator image" className='w-20 h-20 rounded-full text-center' />
          </div>
          <button className='px-6 py-2 mt-4 bg-primary text-white'>Edit profile</button>
        </div>

        <div className='md:w-2/3'>
          <div className='py-2'>
            <p className='font-bold'>Full Name:</p>
            <p>Shahjalal Rafi</p>
          </div>
          <div className='py-2'>
            <p className='font-bold'>Email:</p>
            <p>shahjalalrafi5@gmail.com</p>
          </div>
          <div className='py-2'>
            <p className='font-bold'>Phone:</p>
            <p>01875371330</p>
          </div>
        </div>
      </div>
    </div>
  )
}
