import React, { useState } from 'react'
import { FaCode, FaPollH, FaUsers, FaPeopleCarry, FaAngleRight, FaCartPlus, FaAngleDown } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'
import rafi from "../assets/images/rafi.jpg"

export default function SuperAdminSidebar() {
  let [programsDropdown, setProgramsDropdown] = useState(false);
  let [blogsDropdown, setBlogsDropdown] = useState(false);
  let [jobsDropdown, setJobsDropdown] = useState(false);
  let [assessmentDropdown, setAssessmentDropdown] = useState(false);
  let [adminDropdown, setAdminDropdown] = useState(false);

  const location = useLocation();
  let activeClass = 'flex items-center space-x-4 p-4  text-admin_primary cursor-pointer pl-8 duration-200 hover:text-[#1DD1A1] hover:border-l-4 hover:border-admin_primary hover:text-admin_primary'
  let normalClass = 'flex items-center space-x-4 p-4  text-black cursor-pointer pl-8 duration-200 hover:text-[#1DD1A1] hover:border-l-4 hover:border-admin_primary hover:text-admin_primary'


  const handleLogout = () => {
    localStorage.removeItem("creator_admin_loggedIn");
    window.location.reload();
  }

  return (
    <div className='w-[300px] bg-[#F5F6FA] min-h-screen p-4'>
      <div className='flex items-center space-x-4 py-4'>
        <img src={rafi} alt="rafi" className='w-16 h-16 rounded-full drop-shadow-2xl' />
        <div>
          <p className='font-bold'>Shahjalal Rafi</p>
        </div>
      </div>
      <div className='py-2'>
        {/* Side Navigation */}
        <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer duration-200  hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={() => {
          setProgramsDropdown(!programsDropdown)
        }}>
          <div className='flex space-x-4 items-center' >
            <FaCode />
            <p>Programs</p>
          </div>
        </div>
        {/* Dropdown */}
        {programsDropdown ?
          <>
            <Link to="/admin/all-programs">
              <div className={location?.pathname === "/admin/all-programs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>All Programs</p>
              </div>
            </Link>
            <Link to="/admin/add-program" >
              <div className={location?.pathname === "/admin/add-program" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>Add Program</p>
              </div>
            </Link>
          </> : null
        }


        {/* Blog Navigation */}
        <div className='flex items-center justify-between space-x-4 p-4  duration-200 text-black cursor-pointer hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={() => {
          setBlogsDropdown(!blogsDropdown)
        }}>
          <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
            <FaPollH />
            <p>Blogs</p>
          </div>
        </div>
        {/* Dropdown */}
        {blogsDropdown ?
          <>
            <Link to="/admin/all-blogs">
              <div className={location?.pathname === "/admin/all-blogs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>All Blogs</p>
              </div>
            </Link>
            <Link to="/admin/add-blogs" >
              <div className={location?.pathname === "/admin/add-blogs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>Add Blog</p>
              </div>
            </Link>
          </> : null
        }



        <div className={location?.pathname === "/admin/all-creators" ? "text-admin_primary flex items-center justify-between space-x-4 p-4  duration-200 text-admin_primary cursor-pointer hover:border-l-4 hover:border-admin_primary hover:text-admin_primary" : "flex items-center justify-between space-x-4 p-4  duration-200 text-black cursor-pointer hover:border-l-4 hover:border-admin_primary hover:text-admin_primary"} >
          <Link to="/admin/all-creators">
            <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
              <FaUsers />
              <p>Creators</p>
            </div>
          </Link>
        </div>

        <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer  duration-200 hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={() => {
          setJobsDropdown(!jobsDropdown)
        }}>
          <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
            <FaPeopleCarry />
            <p>Jobs</p>
            {
              jobsDropdown ? <FaAngleDown /> : <FaAngleRight />
            }

          </div>
        </div>

        {/* Dropdown */}
        {jobsDropdown ?
          <>
            <Link to="/admin/all-jobs">
              <div className={location?.pathname === "/admin/all-jobs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>All Jobs</p>
              </div>
            </Link>
            <Link to="/admin/add-job" >
              <div className={location?.pathname === "/admin/add-job" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>Add Job</p>
              </div>
            </Link>
          </> : null
        }

        <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer  duration-200 hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={() => {
          setAssessmentDropdown(!assessmentDropdown)
        }}>
          <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
            <FaPeopleCarry />
            <p>Assessments</p>
            {
              jobsDropdown ? <FaAngleDown /> : <FaAngleRight />
            }

          </div>
        </div>

        {/* Dropdown */}
        {assessmentDropdown ?
          <>
            {/* <Link to="/admin/all-assessment">
              <div className={location?.pathname === "/admin/all-jobs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>All Assessment</p>
              </div>
            </Link> */}
            <Link to="/admin/add-assessment" >
              <div className={location?.pathname === "/admin/add-job" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>Add Assessment</p>
              </div>
            </Link>
          </> : null
        }


        <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer  duration-200 hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={() => {
          setAdminDropdown(!adminDropdown)
        }}>
          <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
            <FaPeopleCarry />
            <p>Admins</p>
            {
              adminDropdown ? <FaAngleDown /> : <FaAngleRight />
            }

          </div>
        </div>

        {/* Dropdown */}
        {adminDropdown ?
          <>
            <Link to="/admin/all-admin">
              <div className={location?.pathname === "/admin/all-jobs" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>All Admin</p>
              </div>
            </Link>
            <Link to="/admin/add-admin" >
              <div className={location?.pathname === "/admin/add-job" ? activeClass : normalClass} >
                <FaAngleRight />
                <p>Add Admin</p>
              </div>
            </Link>
          </> : null
        }


        <div className='flex items-center justify-between space-x-4 p-4  text-black cursor-pointer  duration-200 hover:border-l-4 hover:border-admin_primary hover:text-admin_primary' onClick={handleLogout}>
          <div className='flex space-x-4 items-center hover:text-[#1DD1A1]' >
            <FaPeopleCarry />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div >
  )
}
