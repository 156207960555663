import React from 'react'
import Navbar from '../../Shared/Navbar'
import Footer from '../../Shared/Footer'
import { termsConditionData } from './data'

export default function TermsCondition() {
  return (
    <div>
      <Navbar />
      <div className='max-w-5xl mx-auto py-[120px] px-8'>
        <h2 className='text-2xl md:text-4xl font-bold text-center md:pb-10'>Terms & Conditions</h2>
        <div>
          <h2 className='text-xl pb-6'><span className='font-bold'>Last Updated :</span> May 3, 2023</h2>
          {
            termsConditionData?.map(condition => (
              <div className='py-4'>
                <h2 className='text-xl font-bold pb-4'>{condition.id}. {condition.title}</h2>
                <p className='font-medium'>{condition.details}</p>
              </div>
            ))
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}
