import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { FaAlignJustify, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.config";
import { logout } from "../features/auth/authSlice";
import { useEffect } from "react";
import {
  isShowAuthModal,
  isShowContactModal,
  toggleAuthModal,
  toggleContactModal,
} from "../features/globalState/globalStateSlice";
import { useCollection } from "../hooks/useCollection";

export default function Navbar({ setShowContactModal }) {
  let [showNavigation, setShowNavigation] = useState(false);
  const { email } = useSelector((state) => state.authDetail);

  const { document } = useCollection("creator_details");

  const actualCreator = document?.filter(
    (creator) => creator?.basicInfo?.email === email
  )[0];

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const showModal = useSelector(isShowAuthModal);
  const showContactModal = useSelector(isShowContactModal);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleAuthModal(!showModal));
  };

  const handleContactToggle = () => {
    dispatch(toggleContactModal(!showContactModal));
  };

  const handleLogout = () => {
    signOut(auth).then((res) => {
      logout();
      window.location.reload();
    });
  };

  return (
    <div
      className={`sticky z-10 shadow-sm ${
        lastScrollY > 10 ? "bg-[#F5F6FA]" : ""
      }  ${show ? "top-0 " : ""}`}
    >
      <div
        className={`relative max-w-screen-xl flex flex-col md:flex-row justify-between items-start md:items-center py-4 px-4 mx-auto `}
      >
        <Link to="/">
          <img src={logo} alt="ongshak" className="w-[160px] object-contain" />
        </Link>
        <div className="hidden md:flex space-y-4 flex-col md:flex-row items-center  md:space-y-0 md:space-x-4">
          <Link to="/all-programs">
            <div className="cursor-pointer">Programs</div>
          </Link>
          <Link to="/all-jobs">
            <div>Jobs</div>
          </Link>
          <Link to="/blogs">
            <div>Blog</div>
          </Link>
          {email && (
            <Link to="/dashboard/all-courses">
              <div>Dashboard</div>
            </Link>
          )}
          {email &&
            actualCreator?.assessments?.length <= 0 &&
            (actualCreator?.role === "Intermediate" ||
              actualCreator?.role === "Advanced") && (
              <Link to="/assessment">
                <div>Assessment</div>
              </Link>
            )}
          {email && !actualCreator?.role && (
            <Link to="/level">
              <div>Level Setup</div>
            </Link>
          )}
          {/* <div className='cursor-pointer'>About</div> */}
          <div onClick={handleContactToggle} className="cursor-pointer">
            Contact
          </div>

          {email ? (
            <button
              className="bg-primary text-white py-2 px-4 rounded-full"
              onClick={handleLogout}
            >
              Sign out
            </button>
          ) : (
            <div
              className="bg-primary text-white py-2 px-4 rounded-full cursor-pointer"
              onClick={handleToggle}
            >
              Sign Up
            </div>
          )}
        </div>

        <div className="md:hidden absolute top-[20px] right-[40px]">
          {!showNavigation ? (
            <FaAlignJustify
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowNavigation(true)}
            />
          ) : (
            <FaTimes
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowNavigation(false)}
            />
          )}
        </div>
        {showNavigation && (
          <div className="md:hidden bg-white drop-shadow-xl mt-4 w-full py-4 flex space-y-4 flex-col md:flex-row items-center pt-4 md:space-y-0 md:space-x-4">
            <Link to="/all-programs">
              <div className="cursor-pointer">Programs</div>
            </Link>
            <Link to="/all-jobs">
              <div>Jobs</div>
            </Link>
            <Link to="/blogs">
              <div>Blog</div>
            </Link>
            {email && actualCreator?.assessments?.length > 0 && (
              <Link to="/creators-info">
                <div>Dashboard</div>
              </Link>
            )}
            {email && actualCreator?.assessments?.length <= 0 && (
              <Link to="/assessment">
                <div>Assessment</div>
              </Link>
            )}
            {/* <div className='cursor-pointer'>About</div> */}
            <div onClick={handleContactToggle} className="cursor-pointer">
              Contact
            </div>

            {email && actualCreator ? (
              <button
                className="bg-primary text-white py-2 px-4 rounded-full"
                onClick={handleLogout}
              >
                Sign out
              </button>
            ) : (
              <div
                className="bg-primary text-white py-2 px-4 rounded-full cursor-pointer"
                onClick={handleToggle}
              >
                Sign Up
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
