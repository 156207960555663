export const termsConditionData = [
    {
        id: "1",
        title: "Introduction",
        details: `These Terms and Conditions set forth the legally binding terms and conditions for your use of the Ongshak website and services (the "Service") offered by Ongshak. By using the Service in any way, you agree to comply with and be bound by this Agreement.`
    },
    {
        id: "2",
        title: "Eligibility",
        details: `The Service is only available to individuals who are at least 18 years old and can form legally binding contracts under applicable law. By using the Service, you represent and warrant that you are at least 18 years old and can form legally binding contracts under applicable law.`
    },
    {
        id: "3",
        title: "Use of the Service",
        details: `The Service is provided for personal and non-commercial use only. You may not use the Service for any commercial purposes without the express written consent of Ongshak.`
    },
    {
        id: "4",
        title: "Registration",
        details: `To use certain features of the Service, you must register and create an account. During the registration process, you will be required to provide certain information about yourself, including your name, email address, and experience level. You agree to provide accurate and complete information during the registration process and to update your information as necessary to keep it accurate and complete.`
    },
    {
        id: "5",
        title: "Assessment",
        details: `Intermediate and advanced level developers will be required to complete an assessment to verify their skill level. The assessment will be conducted online and will consist of multiple-choice questions. The results of the assessment will be reviewed by an Ongshak admin to verify the developer's skill level.`
    },
    {
        id: "6",
        title: "Job Postings",
        details: `Ongshak will post job opportunities on the website, and developers may apply for these jobs based on their skill level. However, beginner level developers will need to improve their skills to at least an intermediate level before being eligible to apply for jobs.`
    },
    {
        id: "7",
        title: "Developer Dashboard",
        details: `Registered users will have access to a dashboard where they can view their enrolled courses, saved jobs, and personal details. This information will also be available to Ongshak admin.`
    },
    {
        id: "8",
        title: "Job Postings",
        details: `All content included on the Service, including text, graphics, logos, images, and software, is the property of Ongshak or its content suppliers and is protected by copyright and other laws. You may not copy, modify, distribute, or reproduce any of the content without the express written consent of Ongshak.`
    },
    {
        id: "9",
        title: "Termination",
        details: `Ongshak may terminate your account and access to the Service at any time, for any violation of terms, without notice or liability.`
    },
    {
        id: "10",
        title: "Disclaimer of Warranties",
        details: `The Service is provided on an "as is" and "as available" basis. Ongshak makes no representations or warranties of any kind, express or implied, regarding the Service, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.`
    },
    {
        id: "11",
        title: "Limitation of Liability",
        details: `In no event shall Ongshak be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the Service, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.`
    },
    {
        id: "12",
        title: "Governing Law",
        details: `This Agreement shall be governed by and construed in accordance with the laws of Bangladesh, without giving effect to any principles of conflicts of law.`
    },
    {
        id: "13",
        title: "Changes to this Terms & Conditions",
        details: `Ongshak reserves the right to modify this Agreement at any time, and such modifications shall be effective immediately upon posting on the Service. Your continued use of the Service after any modification of this Agreement shall constitute your acceptance of the modified Terms & Conditions.`
    },
]