import { Navigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({children}) => {
    const {email} = useSelector(state => state.authDetail);

    return(
        email ? children : <Navigate to='/'></Navigate>
    );
}

export default ProtectedRoute;