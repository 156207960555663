import React from 'react'
import { useState } from 'react'
import { FaAngleDown, FaAngleUp, FaCheckCircle, FaPlay, FaSearch } from 'react-icons/fa'
import CourseLessonPart from './CourseLessonPart';
import { useEffect } from 'react';



export default function CourseLesson({ program, currentTutorial, setCurrentTutorial, setShowAssessment }) {
  let [searchTerm, setSearchTerm] = useState("");
  let [searchesResult, setSearchesResult] = useState([]);

  const watchTutorial = program?.curriculum?.filter(tutorial => tutorial.watch);

  const percentage = (watchTutorial?.length / program?.curriculum?.length) * 100;


  useEffect(() => {
    if (searchTerm === "") {
      setSearchesResult([]);
    } else {

      let filtered = program?.curriculum?.filter(tutorial => tutorial.watch && tutorial?.title?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      setSearchesResult(filtered)
    }
  }, [searchTerm])


  const handleSearch = (result, id) => {
    setCurrentTutorial({ ...result, id });
    setSearchesResult([])
  }

  return (
    <div className='w-full md:w-[600px]'>
      <p className='text-xl font-bold'>Course content</p>
      <div className='flex items-center gap-4 w-full'>
        <div className='w-full border border-primary rounded-full'>
          <div className={`p-2 bg-primary rounded-full`} style={{ width: `${parseInt(percentage)}%` }}></div>
        </div>
        <p className='w-1/3'>{parseInt(percentage)}%</p>
      </div>

      <div className='bg-secondary rounded-md p-4 mt-4'>
        <div className='relative'>
          <input type="text" placeholder='Search lesson' className='w-full px-4 py-2 rounded-md outline-none' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
          <FaSearch className='absolute top-1/2 right-[10px] -translate-y-1/2' />
        </div>
        {
          searchesResult?.length ? searchesResult?.map((result, id) => (
            <div className='flex items-start gap-4 p-2 cursor-pointer' onClick={() => handleSearch(result, id)} >
              <FaCheckCircle className='w-6 h-6 fill-primary' />
              <p>{result?.title}</p>
            </div>
          )) : null
        }
      </div>

      <div className='bg-white border shadow-xl p-6 my-4 rounded-md flex flex-col gap-4'>
        <CourseLessonPart program={program} currentTutorial={currentTutorial} setCurrentTutorial={setCurrentTutorial} setShowAssessment={setShowAssessment} />
      </div>
    </div>
  )
}
