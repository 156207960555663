import React from 'react'
import { useState } from 'react'
import RichTextEditor from './RichTextEditor';
import { useFirestore } from '../../../../hooks/useFirestore';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Toast from '../../../../Shared/UI/Toast';
import { FaTimes } from 'react-icons/fa';
import AddJobAssessment from './AddJobAssessment';

export default function AdminAddJob() {
    let [assessments, setAssessments] = useState([]);
    let [jobDetail, setJobDetail] = useState({
        title: "",
        jobType: "",
        jobDetail: "",
        level: "",
        categories: [],
        salary: "",
    });
    const [category, setCategory] = useState("");

    const [jobContent, setJobContent] = useState("");
    const { addDocument, response } = useFirestore("Jobs");

    const handleCategory = (e) => {
        if (jobDetail?.categories?.includes(category)) {
            toast.error("already added this skill into your skill set");
            return
        }
        if (category === "") {
            return
        }

        setJobDetail({ ...jobDetail, categories: [...jobDetail.categories, category] });
        setCategory("");
    }

    const handleDelete = (categoryName) => {
        let remainingJob = jobDetail?.categories?.filter(category => category !== categoryName);

        setJobDetail({ ...jobDetail, categories: [...remainingJob] });
    }


    const handleAddJob = e => {
        e.preventDefault();
        addDocument({
            ...jobDetail,
            jobDetail: jobContent,
            candidates: [],
            assessments
        });
    }

    useEffect(() => {
        if (response?.success) {
            toast.success("Successfully job added.")
        }
    }, [response])

    return (
        <div className='w-full pb-8 '>
            <div className="max-w-screen-md mx-auto">
                <h2 className='py-8 text-3xl font-bold'>Add Job</h2>
                <div className='w-full bg-[#E7FFF9] p-10 rounded-md drop-shadow-md'>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Job Title</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={jobDetail.title} onChange={(e) => setJobDetail({ ...jobDetail, title: e.target.value })} />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Job Basic</label>
                        <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' value={jobDetail?.basicDetail} onChange={e => setJobDetail({ ...jobDetail, basicDetail: e.target.value })} />
                    </div>
                    {/* <div className='flex flex-col'>
                        <label className='pb-4'>Job Details</label>
                        <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Job responsibility</label>
                        <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
                    </div>
                    <div className='flex flex-col'>
                        <label className='pb-4'>Job requirement</label>
                        <textarea type="text" className='w-full h-[160px] resize-none border p-4 rounded-md outline-none border-[#1DD1A1]' />
                    </div> */}
                    <div className='flex flex-col pb-4'>
                        <label className='pb-4'>Job Type</label>
                        <div class="relative">
                            <select
                                class="border-[#1DD1A1] w-full appearance-none rounded-md border py-2 px-4 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                value={jobDetail.jobType}
                                onChange={(e) => setJobDetail({ ...jobDetail, jobType: e.target.value })}
                            >
                                <option value="">Select job type</option>
                                <option value="Full time">Full time</option>
                                <option value="Contractual">Contractual</option>
                                <option value="Intern">Intern</option>
                            </select>
                            <span
                                class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                            >
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col pb-4'>
                        <label className='pb-4'>Job Level</label>
                        <div class="relative">
                            <select
                                class="border-[#1DD1A1] w-full appearance-none rounded-md border py-2 px-4 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                value={jobDetail.level}
                                onChange={(e) => setJobDetail({ ...jobDetail, level: e.target.value })}
                            >
                                <option value="">Select level</option>
                                <option value="Beginer">Beginer</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Expert">Expert</option>
                            </select>
                            <span
                                class="border-[#1DD1A1] absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"
                            >
                            </span>
                        </div>
                    </div>

                    {
                        jobDetail?.categories?.length ? <div className='my-4 w-full'>
                            <h2 className='text-2xl font-bold'>selected category</h2>
                            <div className='flex gap-2 flex-wrap py-6'>
                                {
                                    jobDetail?.categories?.map(category => (
                                        <div className='bg-admin_secondary border border-admin_primary rounded-md  px-4 py-2 text-white flex items-center space-x-4'>
                                            <p className='text-admin_primary'>{category}</p>
                                            <FaTimes className='fill-admin_primary cursor-pointer' onClick={() => handleDelete(category)} />
                                        </div>
                                    ))
                                }

                            </div>
                        </div> : null
                    }
                    <div className='flex flex-col'>
                        <label className='pb-4'>Job Category ({jobDetail.categories.length})</label>
                        <input type="text" className='w-full border px-4 py-2 rounded-md outline-none border-[#1DD1A1]' value={category} onChange={e => setCategory(e.target.value)} />
                        <div className='bg-[#1DD1A1] text-white py-2 rounded-md mt-4 text-center' onClick={handleCategory}>Add category</div>
                    </div>

                    <div className='flex flex-col mb-4'>
                        <label className='pb-4'>Salary</label>
                        <input type="text" className='w-full border py-2 px-4 rounded-md outline-none border-[#1DD1A1]' value={jobDetail.salary} onChange={(e) => setJobDetail({ ...jobDetail, salary: e.target.value })} />
                    </div>

                    <RichTextEditor

                        setContent={setJobContent}

                    />

                    <AddJobAssessment assessments={assessments} setAssessments={setAssessments} />




                    <Toast />

                    <div className='mt-4'>
                        <button className='bg-[#1DD1A1] p-4 text-white rounded-md' onClick={handleAddJob}>Add Job</button>
                    </div>


                </div>
            </div>
        </div>
    )
}