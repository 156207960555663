import React from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AddSuperAdmins from './Components/AddSuperAdmins'

export default function AddSuperAdmin() {
    return (
        <div className='flex bg-white'>
            <SuperAdminSidebar />
            <AddSuperAdmins />
        </div>
    )
}
