import React from 'react'
import { blogsData } from '../data'
import { FaSearch } from "react-icons/fa";
import Blog from './Blog';
import { useCollection } from '../../../hooks/useCollection';
import { useState, useEffect } from 'react';
import Heading from '../../../Shared/UI/Heading';
import Loader from '../../../Shared/UI/Loader';

export default function AllBlogs() {
    const [searchTerm, setSearchTerm] = useState("");
    const { document: allBlogs, isLoading } = useCollection("blogs");
    let [blogs, setBlogs] = useState([]);

    useEffect(() => {
        if (searchTerm) {
            let filteredBlogs = allBlogs?.filter(blog => blog?.title?.toLowerCase().includes(searchTerm?.toLowerCase()));
            setBlogs(filteredBlogs)
        } else {
            setBlogs(allBlogs)
        }
    }, [searchTerm, allBlogs]);


    return (
        <div className='max-w-screen-xl mx-auto py-12 px-4'>
            <div className='text-center'>
                <Heading>Blogs</Heading>
                <div className='relative'>
                    <input type="text" className='w-1/2 md:w-1/3 px-6 py-2 rounded-full outline-none border border-primary placeholder:italic' placeholder='Search for blogs' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                    <FaSearch className='absolute top-0 right-1/4 md:right-1/3 top-1/2 -translate-x-full md:-translate-x-3/4 -translate-y-1/2 fill-primary ' />
                </div>
            </div>
            {
                isLoading ? <Loader /> : <div className='grid grid-cols-1 gap-8 md:grid-cols-3 pt-12'>
                    {blogs?.map(blog => <Blog blogObject={blog} />)}
                </div>
            }
        </div>
    )
}
