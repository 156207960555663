import React, { useState } from 'react'
import SuperAdminSidebar from '../../../Shared/SuperAdminSidebar'
import AdminAddAssessment from './Components/AdminAddAssessment'

export default function AdminAddAssessments() {
    let [assessments, setAssessments] = useState({
        category: "",
        allQuestions: []
    });
    return (
        <div className='flex bg-white'>
            <SuperAdminSidebar />
            <AdminAddAssessment setAssessments={setAssessments} assessments={assessments} />
        </div>
    )
}
