import django from "../../../assets/images/django.png";
import free from "../../../assets/images/free.png";
import schedule from "../../../assets/images/schedule.png";
import taka from "../../../assets/images/taka.png";
import support from "../../../assets/images/support.png";
import community from "../../../assets/images/community.png";

export const programsData = [
  {
    id: 1,
    title: "Django Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Advanced",
  },
  {
    id: 2,
    title: "MERN Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Intermediate",
  },
  {
    id: 3,
    title: "React Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Basic",
  },
  {
    id: 4,
    title: "IOT Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Intermediate",
  },
  {
    id: 5,
    title: "Flutter Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Advanced",
  },
  {
    id: 6,
    title: "React Native Development",
    description:
      "Build fullstack React.js applications with Node.js, Express.js & MongoDB (MERN) with this project-focused course.",
    image: django,
    categories: ["Django", "Python", "Api"],
    difficultyLevel: "Basic",
  },
];

export const ProgramsBenefit = [
  {
    id: 1,
    title: "FREE Courses",
    image: free,
  },
  {
    id: 2,
    title: "Value for Money",
    image: taka,
  },
  {
    id: 3,
    title: "Lifetime Access",
    image: schedule,
  },
  {
    id: 4,
    title: "Lifetime Support",
    image: support,
  },
  {
    id: 5,
    title: "Community Support",
    image: community,
  },
];
