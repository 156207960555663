import { configureStore } from "@reduxjs/toolkit";
import creatorsnavReducer from "../features/CreatorsNav/CreatorsNavSlice";
import authReducer from "../features/auth/authSlice";
import addDocumentReducer from "../features/fireStore/addDocumentSlice";
import globalStateReducer from "../features/globalState/globalStateSlice";

export const store = configureStore({
    reducer: {
        creatorNav: creatorsnavReducer,
        authDetail: authReducer,
        addDocument: addDocumentReducer,
        globalState: globalStateReducer,
    }
})