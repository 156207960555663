import React from 'react'
import { FaUserAlt, FaRegCalendarAlt, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import blog1 from "../../../assets/images/blog1.png"
import { useCollection } from "../../../hooks/useCollection"
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFirestore } from '../../../hooks/useFirestore';
import Toast from '../../../Shared/UI/Toast';
import Facebook from '../../../Shared/Components/SocialMedia/Facebook';
import Linkedin from '../../../Shared/Components/SocialMedia/Linkedin';
import Twitter from '../../../Shared/Components/SocialMedia/Twitter';

export default function BlogReads() {
    const { blogTitle } = useParams();
    let [blog, setBlog] = useState({});
    const [commentDetails, setCommentDetails] = useState({
        comment: "",
        name: "",
        email: "",
        website: ""
    });

    const { document: blogs } = useCollection("blogs");
    const { updateDocument, response } = useFirestore("blogs");

    useEffect(() => {
        let filteredBlog = blogs?.filter(blog => {
            let singleBlogTitle = blog?.title?.split(" ").join("-");
            return singleBlogTitle === blogTitle
        })
        setBlog(filteredBlog[0]);
    }, [blogs, blogTitle]);

    const handleComment = e => {
        e.preventDefault();
        if (!commentDetails.comment && !commentDetails.name && !commentDetails.email) {
            toast.error("Please fill all the required field");
            return
        }

        let updatedObj = {
            ...blog,
            comments: [...blog?.comments, commentDetails]
        }
        updateDocument(blog?.id, updatedObj);

    }

    useEffect(() => {
        if (response?.success) {
            toast.success("Comment added successfully");
            setCommentDetails({
                comment: "",
                name: "",
                email: "",
                website: ""
            })
        }
    }, [response])


    const milliseconds = blog?.createdAt?.seconds * 1000 + blog?.createdAt?.nanoseconds / 1000000;
    const date = new Date(milliseconds).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <div className='max-w-screen-lg mx-auto my-8 px-4'>
            <h2 className='text-3xl md:text-5xl font-bold py-4'>{blog?.title}</h2>
            <div className='flex items-center space-x-8 border-y border-gray-400 py-4'>
                <div className='flex space-x-4 items-center'>
                    <FaUserAlt />
                    <p className='font-bold'>{blog?.blogerName}</p>
                </div>
                <div className='flex space-x-4 items-center'>
                    <FaRegCalendarAlt />
                    <p className='font-bold'>{date}</p>
                </div>
            </div>
            <img src={blog?.blogImage} className="w-full mt-4" />
            <div style={{ lineHeight: "1.6", marginTop: "2rem" }}>

                <div
                    dangerouslySetInnerHTML={{ __html: blog?.blogDetails }}
                />
            </div>

            <h2 className='text-2xl font-bold py-6'>Contributor</h2>
            <div className='flex space-x-4 items-center'>
                <FaUserAlt />
                <p className='font-bold'>Shahjalal Rafi</p>
            </div>
            <div className='flex items-center space-x-4 py-8'>

                <Facebook url={`https://creators.ongshak.com/${blog?.title}`} qoute={blog?.title} />
                <Linkedin url={`https://creators.ongshak.com/${blog?.title}`} qoute={blog?.title} />
                <Twitter url={`https://creators.ongshak.com/${blog?.title}`} qoute={blog?.title} />
                {/* <div className='flex space-x-4 items-center bg-[#37A0EC]  text-white px-4 py-2 rounded-lg'>
                    <FaLinkedin className='w-6 h-6' />
                    <p>Linkedin</p>
                </div>

                <div className='flex space-x-4 items-center bg-[#3195DD]  text-white px-4 py-2 rounded-lg'>
                    <FaTwitter className='w-6 h-6' />
                    <p>Linkedin</p>
                </div> */}
            </div>

            <div className='bg-white drop-shadow-lg flex space-x-8 p-12 rounded-md'>
                <div>
                    <img src={blog1} className="w-12 h-12 rounded-full" />
                </div>
                <div>
                    <p className='font-bold'>Shahjalal Rafi</p>
                    <p>All Posts </p>
                </div>
            </div>
            <div className='p-6 my-8 border-y border-gray-400'>

            </div>


            {/* Leave comment section */}
            <div>
                <h2 className='text-xl font-bold'>Leave a comment</h2>
                <p className='text-2xl pb-8'>Your email address will not be published. Required fields are marked *</p>

                <form onSubmit={handleComment}>
                    <textarea className='w-full h-[200px] resize-none outline-none border border-gray-400 rounded-md p-2' value={commentDetails.comment} onChange={e => setCommentDetails({ ...commentDetails, comment: e.target.value })} />
                    <div className='flex space-x-6 py-4'>
                        <input type="text" placeholder='Name*' className='outline-none border  border-gray-400 rounded-md p-2 w-full' value={commentDetails.name} onChange={e => setCommentDetails({ ...commentDetails, name: e.target.value })} />
                        <input type="email" placeholder='Email*' className='outline-none border  border-gray-400 rounded-md p-2 w-full' value={commentDetails.email} onChange={e => setCommentDetails({ ...commentDetails, email: e.target.value })} />
                        <input type="text" placeholder='Website' className='outline-none border  border-gray-400 rounded-md p-2 w-full' value={commentDetails.website} onChange={e => setCommentDetails({ ...commentDetails, website: e.target.value })} />
                    </div>
                    <input type="submit" value="Post Comment" className='px-8 py-2 bg-primary text-white rounded-md cursor-pointer' />
                </form>
                <Toast />
            </div>
        </div >
    )
}




