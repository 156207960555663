import React from 'react'
import CreatorsInfoSidebar from '../Components/CreatorsInfoSidebar';
import AllDashboardCourse from './Components/AllDashboardCourse';
import { useCollection } from '../../../hooks/useCollection';
import { useSelector } from 'react-redux';
import CreatorsDashboardLayout from '../../../Shared/Layout/CreatorsDashboardLayout';

export default function AllDashbaordCourses() {
    const { email } = useSelector(state => state.authDetail);

    const { document: allCreatorDetails } = useCollection("creator_details");
    const creator = allCreatorDetails.filter(creator => creator?.basicInfo?.email === email);

    return (
        <CreatorsDashboardLayout creatorDetails={creator[0]}>
            <AllDashboardCourse />
        </CreatorsDashboardLayout>
    )
}
