import React from 'react'
import { FaAngleRight, FaAssistiveListeningSystems } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { isShowAuthModal, toggleAuthModal } from '../../features/globalState/globalStateSlice';

export default function Program({ program, hovering }) {
    const { email } = useSelector(state => state.authDetail)
    const dispatch = useDispatch();
    const showModal = useSelector(isShowAuthModal);

    const handleToggle = () => {
        if (email) {
            return
        }
        dispatch(toggleAuthModal(!showModal));
    }


    return (
        <div className='slide relative bg-white rounded-md drop-shadow-xl m-6 group hover:cursor-pointer min-h-[380px] duration-200 '>
            <img src={program?.image} alt={program?.title} className="w-full h-auto rounded-t-md group-hover:hidden" />
            <div className='p-4 group-hover:hidden flex flex-col justify-between'>
                <h2 className='text-xl font-bold'>{program?.title}</h2>
                <div className='flex flex-row flex-wrap gap-2  pt-2'>
                    {
                        program?.categories?.slice(0, 3)?.map(tag => (
                            <div className='bg-black text-white px-4 py-2 mt-2 rounded-full text-[10px]'>
                                {tag}
                            </div>
                        ))
                    }
                </div>
                <div className='flex justify-between items-center mt-4'>
                    <p className='text-2xl font-bold'>Free</p>
                    <div className='bg-[#E1B12C] inline-block text-black px-4 py-1 rounded-full text-sm font-bold'>{program?.difficultyLevel}</div>
                </div>
            </div>

            <div className='hidden group-hover:block p-6 absolute top-0 left-0 w-full  h-full bg-secondary rounded-md group-hover:flex group-hover:flex-col group-hover:justify-between  group-hover:duration-200'>
                <div className='text-left mb-4'>
                    <div className='bg-[#E1B12C] inline-block text-black px-4 py-1 rounded-full font-bold'>{program?.difficultyLevel}</div>
                </div>
                <div>
                    <h2 className='text-xl font-bold pb-4'>{program?.title}</h2>
                    <p className='pb-4'>{program?.description}</p>
                </div>
                <div className='flex gap-4 items-center justify-between'>
                    <Link to={`/all-programs/programDetails/${program?.id}`}>
                        <div className='bg-[#1DD1A1] text-white rounded-lg px-4 py-4  flex items-center space-x-2' onClick={handleToggle}>
                            <button className='text-xs'>Explore Program</button>
                            <FaAngleRight className='mt-1' />
                        </div>
                    </Link>
                    <div className='flex flex-col items-end'>
                        <p className='text-right'>{program?.enrolledStudent?.length ? program?.enrolledStudent?.length : 0} Students enrolled</p>
                        <div className='flex items-center space-x-2 text-right'>
                            <FaAssistiveListeningSystems />
                            <p className='font-bold'>{program?.language}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
